import React, { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useUser } from "../../common/contexts/userContext";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";
import { COLOR_BTN, COLOR_BTN_HOVER, COLOR_ERROR_MESSAGE, COLOR_LINK, COLOR_LINK_HOVER, COLOR_PLACEHOLDER, COLOR_TEXT, COLOR_WHITE } from "../../constants/cts_colors";
import { BsShieldLock } from "react-icons/bs";
import AuthPagesBase from "../../components/authPages/Base";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineKey } from "react-icons/hi";

const initialFormState = {
  new_password_token : "",
  email : "",
  password : "",
  repeatPassword : "",
}

const ResetPassword = () => {
  const { onResetPassword } = useUser();
  const [ formValues, _setFormValues ] = useState(initialFormState);
  const [ submitted, _setSubmitted ] = useState<boolean>(false);
  const [ errorMessage, _setErrorMessage ] = useState<string | null>(null);

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    _setFormValues({...formValues, [name] :  value})
  }

  const onSubmit = async (e : FormEvent) => {
    e.preventDefault();
    onResetPassword({
      new_password_token: formValues.new_password_token,
      email: formValues.email,
      password: formValues.password,
      repeatPassword: formValues.repeatPassword,
    })
    .then(() => {
      _setSubmitted(true)
    })
    .catch((error:any) =>  {
      if(error.message) {
        _setErrorMessage(error.message)
      }else if(error) {
        _setErrorMessage(error)
      }
    })
  }

  return (
    <AuthPagesBase
      title="Changement de mot de passe"
    >
      <MainContainer>
        {
          !submitted &&
          (
            <Form onSubmit={onSubmit} id="resetPassword">
              {
                errorMessage &&
                <ErrorMessageStyles>{errorMessage}</ErrorMessageStyles>
              }
              <div className="inputs-container">
                {/* token */}
                <div className="input-line">
                  <BsShieldLock  className="icon" />
                  <input 
                    type='token'  
                    id='token' 
                    name='new_password_token' 
                    placeholder='Votre token' 
                    onChange={handleChange} 
                    required />
                </div>
                {/* email */}
                <div className="input-line">
                  <AiOutlineUser className="icon" />
                  <input 
                    type='email'  
                    id='email' 
                    name='email' 
                    placeholder="Votre e-mail"
                    onChange={handleChange}
                    required />
                </div>
                {/* password */}
                <div className="input-line">
                  <HiOutlineKey className="icon" />
                  <input 
                    type='password'  
                    id='password' 
                    name='password' 
                    placeholder="Nouveau mot de passe"
                    minLength={6} 
                    onChange={handleChange} 
                    required />
                </div>
                {/* repeat password */}
                <div className="input-line">
                  <HiOutlineKey className="icon" />
                  <input 
                    type='password' 
                    id='repeatPassword'
                    name='repeatPassword' 
                    placeholder="confirmer mot de passe"
                    minLength={6} 
                    onChange={handleChange} 
                    required />
                </div>
              </div>
              {/* submit btn */}
              <button 
                className="submit-btn" 
                type='submit' 
                form='resetPassword'
              >
                Modifier
              </button>
            </Form>
          ) 
            ||
          (
            <EmailSent>
              Votre mot de passe à bien été modifié. Vous pouvez à présent vous <Link to={getPageUrl(PATH.login)} className="login-link">connecter</Link> à votre compte.
            </EmailSent>
          )
        }
      </MainContainer>
    </AuthPagesBase>
  )
}

export default ResetPassword;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ErrorMessageStyles = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${COLOR_ERROR_MESSAGE};

  // ========= MEDIA QUERIES - ErrorMessageStyles ============
  @media (max-width: 800px) {
    font-size: 2.5vw;
  }
`

const MainContainer = styled.div`
  width: 500px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  // ========= MEDIA QUERIES - MainContainer ============
  @media (max-width: 800px) {
    width: 100%;
  }
`

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .input-line {
      position: relative;
      display: flex;

      .icon {
        flex-basis: 0px;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        width: 15px;
      }

      input {
        flex-basis: 100%;
        color: ${COLOR_TEXT};
        font-size: 1.1rem;
        padding: 5px;
        padding-left: 22px;
        text-align: center;

        // ========= MEDIA QUERIES - Form / .inputs-container / .input-line / input ============
        @media (max-width: 800px) {
          font-size: 3vw;
        }
      
        &::placeholder {
          color: ${COLOR_PLACEHOLDER};
        }
      }
    }
  }

  .submit-btn {
    display: block;
    margin-left: auto;
    margin-top: 10px;
    background-color: ${COLOR_BTN};
    width: 50%;
    font-size: 1.1rem;
    padding: 10px 5px;
    border: none;
    border-radius: 5px;
    color: ${COLOR_WHITE};
    cursor: pointer;

    // ========= MEDIA QUERIES - Form / .submit-btn ============
    @media (max-width: 800px) {
      font-size: 3vw;
    }

    &:hover {
      background-color: ${COLOR_BTN_HOVER};
    }
  }
`

const EmailSent = styled.div`
  color: ${COLOR_TEXT};

  .login-link {
    text-decoration: none;
    color: ${COLOR_LINK};

    &:hover {
      color: ${COLOR_LINK_HOVER};
    }
  }
`