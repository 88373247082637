import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import { IDivisionInfos } from "../../../interfaces/division";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues } from "../../../interfaces/agent";
import { IResponsibleFormValues } from "../../../interfaces/responsible";
import { useResponsibles } from "../../../common/contexts/responsibleContext";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import { useDivisions } from "../../../common/contexts/divisionContext";
import ToastAlert from "../../../components/ToastAlert";
import { BiRename } from "react-icons/bi";

const AddEditAgentResponsibleForm = ({
  title,
  agentId,
  formData,
  actionString,
  onAgentResponsiblesUpdated,
}: {
  title: string;
  agentId: number;
  formData?: IResponsibleFormValues;
  actionString: string;
  onAgentResponsiblesUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    agentId: null,
    divisionId: null,
    responsibleType: null,
  };

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [formValues, _setFormValues] =
    useState<IResponsibleFormValues>(initialFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  const { onGetAgent } = useAgents();
  const {
    onCreateResponsible,
    onUpdateResponsible,
    onGetAllResponsiblesFromAgent,
    onDeleteResponsible,
  } = useResponsibles();

  const { onGetAllDivisions } = useDivisions();

  const [divisions, _setDivisions] = useState<Array<IDivisionInfos>>([]);
  const [agent, _setAgent] = useState<IAgentFormValues>();

  const [responsibles, _setResponsibles] = useState<
    Array<IResponsibleFormValues>
  >([]);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    // load agent id
    onGetAgent(agentId).then((response) => {
      _setAgent(response);
    });

    // load divisions
    onGetAllDivisions().then((response) => {
      _setDivisions(response);
    });

    onGetAllResponsiblesFromAgent(agentId).then((response: any) => {
      // console.log(response);
      _setResponsibles(response);
    });

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isEditMode) {
      onCreateResponsible({
        agentId: agentId,
        divisionId: formValues.divisionId,
        responsibleType: formValues.responsibleType,
      }).then(() => {
        _setToastMessage("Responsabilité ajoutée avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        onGetAllResponsiblesFromAgent(agentId).then((response) => {
          _setIsEditMode(false);
          _setResponsibles(response);
        });
      });
    } else {
      onUpdateResponsible({
        id: formValues.id,
        agentId: agentId,
        divisionId: formValues.divisionId,
        responsibleType: formValues.responsibleType,
      }).then(() => {
        _setToastMessage("Responsabilité mise à jour avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        onGetAllResponsiblesFromAgent(agentId).then((response) => {
          _setIsEditMode(false);
          _setResponsibles(response);
        });
      });
    }
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  // // delete responsible
  const deleteResponsible = (responsibleId: number) => {
    onDeleteResponsible(responsibleId)
      .then(() => {
        _setConfirmModal(null);
        onGetAllResponsiblesFromAgent(agentId).then((response) => {
          _setResponsibles(response);
          _setToastMessage("Responsabilité supprimée avec succès !");
          _setToastVisible(true);
        });
      })
      .catch((err) => console.error(err));
  };

  // on delete
  const handleDelete = (responsibleId: number) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer cette responsabilité ?",
      confirmedAction: () => deleteResponsible(responsibleId),
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueResponsibles>
                <h4>- Responsabilités -</h4>
                {responsibles && responsibles.length > 0 && (
                  <div className="actual-responsibles">
                    {responsibles.map((responsible: any, key) => {
                      return (
                        <ResponsibleCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              if (responsible.id) handleDelete(responsible.id);
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-edit-line"
                            onClick={() => {
                              _setIsEditMode(true);
                              _setFormValues(responsible);
                            }}
                          ></EditDeleteIcons>

                          <h4>
                            {responsible.agent.firstName}{" "}
                            {responsible.agent.lastName}
                          </h4>
                          <p>-</p>
                          <h4>{responsible.responsibleType}</h4>
                          <h4>
                            {responsible.division.name}{" "}
                            {responsible.division.acronym
                              ? " - " + responsible.division.acronym
                              : ""}
                          </h4>
                        </ResponsibleCard>
                      );
                    })}
                  </div>
                )}
                {!responsibles ||
                  (responsibles.length < 1 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: COLOR_TEXT,
                        paddingTop: 12,
                      }}
                    >
                      Aucune responsabilité pour le moment.
                    </p>
                  ))}
              </HistoriqueResponsibles>

              {/* responsible type */}
              <div>
                <FormLabel htmlFor="responsibleType" text="Nom" />
                <FormInputContainer
                  icon={<BiRename />}
                  input={
                    <input
                      type="text"
                      id="responsibleType"
                      name="responsibleType"
                      placeholder="Nom"
                      onChange={handleChange}
                      value={
                        formValues.responsibleType
                          ? formValues.responsibleType
                          : ""
                      }
                    />
                  }
                />
              </div>

              {/* divisionId */}
              <div>
                <FormLabel htmlFor="divisionId" text="Division" />
                <FormInputContainer
                  icon={<i className="ri-shield-user-line" />}
                  input={
                    <select
                      id="divisionId"
                      value={formValues.divisionId ? formValues.divisionId : ""}
                      name="divisionId"
                      onChange={handleChange}
                    >
                      <option value="">Division</option>
                      {divisions &&
                        divisions.map((division, key) => {
                          return (
                            <option key={key} value={division.id}>
                              {division.name}
                              {division.acronym ? " - " + division.acronym : ""}
                            </option>
                          );
                        })}
                    </select>
                  }
                />
              </div>

              {/* agent id */}
              <div>
                <FormLabel htmlFor="agentId" text="Agent" />
                <FormInputContainer
                  icon={<i className="ri-home-gear-line" />}
                  input={
                    <input
                      type="text"
                      id="agentId"
                      name="agentId"
                      placeholder="Agent"
                      onChange={handleChange}
                      disabled
                      value={
                        agent ? agent.firstName + " " + agent.lastName : ""
                      }
                    />
                  }
                />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={!isEditMode ? actionString : "Modifier"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditAgentResponsibleForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueResponsibles = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }

  .actual-responsibles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ResponsibleCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
