import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_RESPONSIBLE_AGENTID,
  EMPTY_RESPONSIBLE_DIVISIONID,
  EMPTY_RESPONSIBLE_TYPE,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateResponsibleApi,
  onDeleteResponsibleApi,
  onGetAllResponsiblesApi,
  onGetAllResponsiblesFromAgentApi,
  onGetResponsibleApi,
  onUpdateResponsibleApi,
  onGetAllResponsiblesFromDivisionApi,
} from "../../api/responsible.api";
import {
  IResponsibleFormValues,
  IResponsiblesContext,
} from "../../interfaces/responsible";

const ResponsiblesContext = createContext(null);

export function ResponsiblesProvider(props: any) {
  const [isLoadingResponsibles, _setIsLoadingResponsibles] = useState(false);

  // CREATE
  const onCreateResponsible = useCallback(
    async ({
      agentId,
      divisionId,
      responsibleType,
    }: IResponsibleFormValues) => {
      if (!checkEmptyInput(divisionId)) {
        return Promise.reject(EMPTY_RESPONSIBLE_DIVISIONID);
      }
      if (!checkEmptyInput(responsibleType)) {
        return Promise.reject(EMPTY_RESPONSIBLE_TYPE);
      }
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_RESPONSIBLE_AGENTID);
      }

      _setIsLoadingResponsibles(true);
      return onCreateResponsibleApi({
        agentId,
        divisionId,
        responsibleType,
      })
        .then((response) => {
          _setIsLoadingResponsibles(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingResponsibles(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllResponsibles = useCallback(() => {
    _setIsLoadingResponsibles(true);
    return onGetAllResponsiblesApi()
      .then((response) => {
        _setIsLoadingResponsibles(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingResponsibles(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM AGENT
  const onGetAllResponsiblesFromAgent = useCallback((id: number) => {
    _setIsLoadingResponsibles(true);
    return onGetAllResponsiblesFromAgentApi(id)
      .then((response) => {
        _setIsLoadingResponsibles(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingResponsibles(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM DIVISION
  const onGetAllResponsiblesFromDivision = useCallback((id: number) => {
    _setIsLoadingResponsibles(true);
    return onGetAllResponsiblesFromDivisionApi(id)
      .then((response) => {
        _setIsLoadingResponsibles(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingResponsibles(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetResponsible = useCallback((id: number) => {
    _setIsLoadingResponsibles(true);
    return onGetResponsibleApi(id)
      .then((response) => {
        _setIsLoadingResponsibles(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateResponsible = useCallback(
    async ({
      id,
      agentId,
      divisionId,
      responsibleType,
    }: IResponsibleFormValues) => {
      if (!checkEmptyInput(divisionId)) {
        return Promise.reject(EMPTY_RESPONSIBLE_DIVISIONID);
      }
      if (!checkEmptyInput(responsibleType)) {
        return Promise.reject(EMPTY_RESPONSIBLE_TYPE);
      }
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_RESPONSIBLE_AGENTID);
      }
      
      _setIsLoadingResponsibles(true);
      return onUpdateResponsibleApi({
        id,
        agentId,
        divisionId,
        responsibleType,
      })
        .then((response) => {
          _setIsLoadingResponsibles(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingResponsibles(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteResponsible = useCallback((id: number) => {
    _setIsLoadingResponsibles(true);
    return onDeleteResponsibleApi(id)
      .then((response) => {
        _setIsLoadingResponsibles(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingResponsibles(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <ResponsiblesContext.Provider
      {...props}
      value={{
        isLoadingResponsibles,
        // function
        onCreateResponsible,
        onGetAllResponsibles,
        onGetResponsible,
        onUpdateResponsible,
        onDeleteResponsible,
        onGetAllResponsiblesFromAgent,
        onGetAllResponsiblesFromDivision,
      }}
    />
  );
}

export const useResponsibles = (): IResponsiblesContext => {
  const context = React.useContext(ResponsiblesContext);
  if (!context)
    throw new Error("useResponsibles must be used in ResponsiblesProvider");

  return context;
};
