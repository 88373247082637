import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { AiOutlineFieldNumber, AiOutlineUser } from "react-icons/ai";
import FormBase from "../../../components/form/FormBase";
import { BsGenderAmbiguous } from "react-icons/bs";
import { GiLinkedRings } from "react-icons/gi";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues, IAgentInfos } from "../../../interfaces/agent";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormInfoMessage from "../../../components/form/FormInfoMessage";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdAlternateEmail } from "react-icons/md";

const AddEditAgentForm = ({
  title,
  formData,
  actionString,
  onAgentAdded,
  onAgentUpdated,
}: {
  title: string;
  formData?: IAgentInfos;
  actionString: string;
  onAgentAdded?: Function;
  onAgentUpdated?: Function;
}) => {
  const initialFormState = {
    firstName: null,
    lastName: null,
    sex: null,
    birthDate: null,
    registrationNumber: null,
    maritalStatus: null,
    professionalPhoneNumber: null,
    observationsDifficultJobs: null,
    observationsParticularSituations: null,
    email: null,
    bonusVacation: null,
    departureDate: null,
    departureReason: null,
  };
  const [formValues, _setFormValues] =
    useState<IAgentFormValues>(initialFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const { onCreateAgent, onUpdateAgent } = useAgents();
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isEditMode) {
      // if is on edit mode, update the agent
      onUpdateAgent(formValues)
        .then(() => {
          if (onAgentUpdated) {
            onAgentUpdated();
          }
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    } else {
      // if it's not on edit mode, create the agent
      onCreateAgent(formValues)
        .then(() => {
          if (onAgentAdded) {
            onAgentAdded();
          }
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    }
  };

  // format phone number with dot between 2 numbers
  const formatPhoneNumber = (phoneNumber: any) => {
    let numeros = phoneNumber.replace(/\D/g, "");
    let numeroFormate = numeros.match(/.{1,2}/g)?.join(".");

    return numeroFormate || "";
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name !== "professionalPhoneNumber") {
      _setFormValues({
        ...formValues,
        [name]: value ? value : null,
      });
    } else {
      const inputValue = e.target.value;
      const formattedValue = formatPhoneNumber(inputValue);
      // setPhoneNumber(formattedValue); // Met à jour l'état avec le numéro de téléphone formaté
      // setFormattedPhoneNumber(formattedValue); // Met à jour l'état avec le numéro de téléphone formaté pour affichage
      _setFormValues({
        ...formValues,
        ["professionalPhoneNumber"]: formattedValue,
      });
    }
  };

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* firstname */}
              <div>
                <FormLabel htmlFor="firstName" text="Prénom" />
                <FormInputContainer
                  icon={<AiOutlineUser />}
                  input={
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Prénom"
                      onChange={handleChange}
                      value={formValues.firstName ? formValues.firstName : ""}
                    />
                  }
                />
              </div>
              {/* lastname */}
              <div>
                <FormLabel htmlFor="lastName" text="Nom" />
                <FormInputContainer
                  icon={<AiOutlineUser />}
                  input={
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Nom"
                      onChange={handleChange}
                      value={formValues.lastName ? formValues.lastName : ""}
                    />
                  }
                />
              </div>
              {/* sex */}
              <div>
                <FormLabel htmlFor="sex" text="Genre" />
                <FormInputContainer
                  icon={<BsGenderAmbiguous />}
                  input={
                    <select
                      id="sex"
                      value={formValues.sex ? formValues.sex : ""}
                      name="sex"
                      onChange={handleChange}
                    >
                      <option value="">Genre</option>
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                    </select>
                  }
                />
              </div>
              {/* birth date */}
              <div>
                <FormLabel htmlFor="birthDate" text="Date de naissance" />
                <FormInputContainer
                  input={
                    <input
                      id="birthDate"
                      type="date"
                      onChange={handleChange}
                      name="birthDate"
                      value={
                        formValues.birthDate
                          ? formValues.birthDate.toString()
                          : ""
                      }
                    />
                  }
                />
                <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
              </div>
              {/* registration number */}
              <div>
                <FormLabel htmlFor="registrationNumber" text="Matricule" />
                <FormInputContainer
                  icon={<AiOutlineFieldNumber />}
                  input={
                    <input
                      type="text"
                      id="registrationNumber"
                      name="registrationNumber"
                      placeholder="Matricule"
                      required
                      onChange={handleChange}
                      value={
                        formValues.registrationNumber
                          ? formValues.registrationNumber
                          : ""
                      }
                    />
                  }
                />
              </div>
              {/* marital status */}
              {/* <div>
                <FormLabel htmlFor="maritalStatus" text="Statut matrimonial" />
                <FormInputContainer
                  icon={<GiLinkedRings />}
                  input={
                    <select
                      id="maritalStatus"
                      value={
                        formValues.maritalStatus ? formValues.maritalStatus : ""
                      }
                      name="maritalStatus"
                      onChange={handleChange}
                    >
                      <option value="">Statut matrimonial</option>
                      <option value="célibataire">Célibataire</option>
                      <option value="marié">Marié</option>
                      <option value="veuf">Veuf</option>
                      <option value="divorcé">Divorcé</option>
                    </select>
                  }
                />
              </div> */}
              {/* professional phone number */}
              <div>
                <FormLabel
                  htmlFor="professionalPhoneNumber"
                  text="Téléphone Professionnel"
                />
                <FormInputContainer
                  icon={<IoPhonePortraitOutline />}
                  input={
                    <input
                      type="tel"
                      id="professionalPhoneNumber"
                      name="professionalPhoneNumber"
                      placeholder="06.12.34.56.78"
                      onChange={handleChange}
                      maxLength={14}
                      value={
                        formValues.professionalPhoneNumber
                          ? formValues.professionalPhoneNumber
                          : ""
                      }
                    />
                  }
                />
              </div>
              {/* Email */}
              <div>
                <FormLabel htmlFor="email" text="E-mail" />
                <FormInputContainer
                  icon={<MdAlternateEmail />}
                  input={
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="prénom.nom@exemple.com"
                      onChange={handleChange}
                      value={formValues.email ? formValues.email : ""}
                    />
                  }
                />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button text={actionString} onClick={handleSubmit} />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  );
};

export default AddEditAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
