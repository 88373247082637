import React, { ReactNode } from "react";
import styled from "styled-components";
import backgroundImgSrc from "../assets/images/bg-1920-1080.jpg";
import { COLOR_TITLE } from "../constants/cts_colors";

const BodyContainer = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <Body>
      {/* top header */}
      <TopHeader>
        <h1 className="title">{title}</h1>
      </TopHeader>

      {/* main container */}
      <MainContainer>{children}</MainContainer>
    </Body>
  );
};

export default BodyContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Body = styled.div`
  width: 100%;
  height: 100vh;
  padding: 10px;

  // background
  background: url(${backgroundImgSrc}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const TopHeader = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .title {
    color: ${COLOR_TITLE};
    text-align: center;
    font-size: 1.5rem;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  height: calc(
    100% - 88px
  ); // 100% - (top page header height + bottom space (padding))
  margin-top: 10px;
  overflow: auto;
`;
