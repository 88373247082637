import React, { ReactNode } from "react";
import styled from "styled-components";
import { COLOR_MODAL_BACKGROUND } from "../constants/cts_colors";
import "animate.css";

const Modal = ({
  onClose,
  children,
  width,
  maxHeight,
}: {
  onClose: Function;
  children: ReactNode;
  width?: string;
  maxHeight?: string;
}) => {
  return (
    <Container>
      <div
        className={`overlay animate__animated animate__fadeIn`}
        onMouseDown={(e) => {
          // check if user clicked (left click) on the overlay
          if (e.nativeEvent.button === 0 && e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <ModalContainer
          style={{
            width, // custom width
            maxHeight, // custom height
          }}
        >
          {children}
        </ModalContainer>
      </div>
    </Container>
  );
};

export default Modal;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContainer = styled.div`
  width: 50%;
  max-width: 100%;
  max-height: 100%;
  background-color: ${COLOR_MODAL_BACKGROUND};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: auto;

  // ========= MEDIA QUERIES - Presentation / .presentation-video-overlay / .video ============
  @media (max-width: 1000px) {
    width: 90%;
  }
`;
