import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_EMPLOYMENT_AGENTID,
  EMPTY_EMPLOYMENT_CODE,
  EMPTY_EMPLOYMENT_FAMILY,
  EMPTY_EMPLOYMENT_NAME,
  EMPTY_EMPLOYMENT_SUBFAMILY,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateEmploymentApi,
  onDeleteEmploymentApi,
  onGetAllEmploymentsApi,
  onGetAllEmploymentsFromAgentApi,
  onGetEmploymentApi,
  onUpdateEmploymentApi,
} from "../../api/employment.api";
import {
  IEmploymentFormValues,
  IEmploymentsContext,
} from "../../interfaces/employment";

const EmploymentsContext = createContext(null);

export function EmploymentsProvider(props: any) {
  const [isLoadingEmployments, _setIsLoadingEmployments] = useState(false);

  // CREATE
  const onCreateEmployment = useCallback(
    async ({
      name,
      code,
      family,
      subFamily,
      agentId,
      startDate,
      endDate,
      referensJobId,
    }: IEmploymentFormValues) => {
      if (!checkEmptyInput(name)) {
        return Promise.reject(EMPTY_EMPLOYMENT_NAME);
      }
      if (!checkEmptyInput(code)) {
        return Promise.reject(EMPTY_EMPLOYMENT_CODE);
      }
      if (!checkEmptyInput(family)) {
        return Promise.reject(EMPTY_EMPLOYMENT_FAMILY);
      }
      if (!checkEmptyInput(subFamily)) {
        return Promise.reject(EMPTY_EMPLOYMENT_SUBFAMILY);
      }
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_EMPLOYMENT_AGENTID);
      }

      _setIsLoadingEmployments(true);
      return onCreateEmploymentApi({
        name,
        code,
        family,
        subFamily,
        agentId,
        startDate,
        endDate,
        referensJobId,
      })
        .then((response) => {
          _setIsLoadingEmployments(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingEmployments(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllEmployments = useCallback(() => {
    _setIsLoadingEmployments(true);
    return onGetAllEmploymentsApi()
      .then((response) => {
        _setIsLoadingEmployments(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingEmployments(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM AGENT
  const onGetAllEmploymentsFromAgent = useCallback((id: number) => {
    _setIsLoadingEmployments(true);
    return onGetAllEmploymentsFromAgentApi(id)
      .then((response) => {
        _setIsLoadingEmployments(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingEmployments(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetEmployment = useCallback((id: number) => {
    _setIsLoadingEmployments(true);
    return onGetEmploymentApi(id)
      .then((response) => {
        _setIsLoadingEmployments(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateEmployment = useCallback(
    async ({
      id,
      name,
      code,
      family,
      subFamily,
      agentId,
      startDate,
      endDate,
      referensJobId,
    }: IEmploymentFormValues) => {
      _setIsLoadingEmployments(true);
      return onUpdateEmploymentApi({
        id,
        name,
        code,
        family,
        subFamily,
        agentId,
        startDate,
        endDate,
        referensJobId,
      })
        .then((response) => {
          _setIsLoadingEmployments(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingEmployments(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteEmployment = useCallback((id: number) => {
    _setIsLoadingEmployments(true);
    return onDeleteEmploymentApi(id)
      .then((response) => {
        _setIsLoadingEmployments(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingEmployments(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <EmploymentsContext.Provider
      {...props}
      value={{
        isLoadingEmployments,
        // function
        onCreateEmployment,
        onGetAllEmployments,
        onGetEmployment,
        onUpdateEmployment,
        onDeleteEmployment,
        onGetAllEmploymentsFromAgent,
      }}
    />
  );
}

export const useEmployments = (): IEmploymentsContext => {
  const context = React.useContext(EmploymentsContext);
  if (!context)
    throw new Error("useEmployments must be used in EmploymentsProvider");

  return context;
};
