import { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  COLOR_ACTIVE_LINK,
  COLOR_BLUE_MORE,
  COLOR_BTN,
  COLOR_BUTTON,
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import BodyContainer from "../../components/BodyContainer";
import { CGV_EMAIL, CGV_NAME, CGV_NUMBER } from "../../constants/ctx_cgv";

const Contact = () => {
  const { t } = useTranslation();

  // set title of the page
  useEffect(() => {
    document.title = "Nous contacter | Sorbonne";
  }, []);

  return (
    <BodyContainer title="Nous contacter">
      <Wrapper>
        <p>
          Pour toute demande, nous contacter à l’adresse email : <b>{CGV_EMAIL}</b>
        </p>
        {/* <p>
          <br /> Vous pouvez contacter cette personne en utilisant ce numéro :{" "}
          <b>{CGV_NUMBER}</b>
        </p> */}
      </Wrapper>
    </BodyContainer>
  );
};

export default Contact;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-arround;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  transition: opacity 0.15s linear;
  padding: 0px 16px 12px 0px;
  color: ${COLOR_TEXT};
  min-width: 180px;
  text-align: left;

  .header {
    min-height: 48px;
    padding: 12px 8px;
    font-size: 20px;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${COLOR_TEXT};
  }

  .category-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-size: 14px;
    margin: 2px 0px;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      p {
        color: ${COLOR_RED_BRIGHT};
      }
    }

    .total {
      position: relative;
      border-radius: 160px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: bold;
      z-index: 1;
      min-width: 15px;
      max-width: 30px;
    }

    .number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .all {
      z-index: 1;
      color: ${COLOR_WHITE};
      background: ${COLOR_BLUE_MORE};
    }

    .blue {
      color: ${COLOR_WHITE};
      background: ${COLOR_ACTIVE_LINK};
    }

    .red {
      color: ${COLOR_WHITE};
      background: ${COLOR_RED_BRIGHT};
    }
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  color: ${COLOR_TEXT};
  z-index: 1;
`;

const Accounts = styled.div`
  background-color: ${COLOR_WHITE};
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid rgba(19, 24, 44, 0.125);
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
  color: ${COLOR_TEXT};
`;

const Header = styled.div`
  display: flex;
  justify-content: end;

  .btn {
    background-color: transparent;
    color: ${COLOR_BTN};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    transition: color 0.3s ease;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .disabled {
    color: ${COLOR_BUTTON};
    opacity: 0.33;
    cursor: not-allowed;
  }

  .add {
    margin-right: 10px;
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
