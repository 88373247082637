import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLOR_BLUE_MEDIUM_DARK, COLOR_FORM_INPUT, COLOR_SELECT_INPUT_BORDER } from '../../../constants/cts_colors';
import { changeDateFormatYYYYMMDD } from '../../../utils/date';
import { useDivisions } from '../../../common/contexts/divisionContext';
import { DIVISION } from '../../../constants/cts_divisions';
import { IDivisionInfos } from '../../../interfaces/division';
import { IOrgChartFilterOptions } from '../../../interfaces/orgChart';

const FiltersForm = ({
  defaultDate,
  defaultFilterOptions,
  onHandleSubmit,
  onDownloadCanvasImage
}: {
  defaultDate: Date,
  defaultFilterOptions: IOrgChartFilterOptions,
  onHandleSubmit: Function,
  onDownloadCanvasImage: Function
}) => {
  const initialFormState = {
    date: changeDateFormatYYYYMMDD(defaultDate),
    shortBranchRootId: null,
    ...defaultFilterOptions
  }
  const [formValues, _setFormValues] = useState(initialFormState)
  const {onGetAllDivisionsByTypes} = useDivisions()
  const [rootDivisions, _setRootDivisions] = useState<IDivisionInfos[] | null>(null)

  // GET ALL DIVISIONS VIEW DATA
  useEffect(() => {
    onGetAllDivisionsByTypes([DIVISION.direction.type, DIVISION.service.type])
      .then((response) => _setRootDivisions(response))
      .catch((error) => console.error(error))
  }, [])

  // ON CHANGE
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    let finalValue: string | boolean | null = value ? value : null;

    if (e.target.type === "checkbox") {
      finalValue = (e.target as HTMLInputElement).checked
    }

    _setFormValues({
      ...formValues,
      [name]: finalValue,
    });
  };

  return (
    <Form>
      <Filters>
        <FilterRow>
          <div className="date">
            <input
              id="date"
              type="date"
              onChange={handleChange}
              name="date"
              value={
                formValues.date
                  ? formValues.date.toString()
                  : ""
              }
            />
          </div>
          <div className="branch-view-option">
            <select
              id="shortBranchRootId"
              value={formValues.shortBranchRootId ? formValues.shortBranchRootId : ""}
              name="shortBranchRootId"
              onChange={handleChange}
            >
              <option value="">Afficher tout</option>
              {
                rootDivisions && rootDivisions.length > 0 &&
                rootDivisions.map((division) =>  
                <option
                  key={'short-branch-root-' + division.id}
                  value={division.id}>
                    {division.name}
                </option>)
              }
            </select>
          </div>
        </FilterRow>
        <FilterRow>
          <div className='checkbox-container'>
            <input
              id="showResponsibleType"
              type="checkbox"
              name="showResponsibleType"
              checked={formValues.showResponsibleType}
              onChange={handleChange}
            />
            <label htmlFor="showResponsibleType">Fonction du responsable</label>
          </div>
          <div className='checkbox-container'>
            <input
              id="showResponsibleStatus"
              type="checkbox"
              name="showResponsibleStatus"
              checked={formValues.showResponsibleStatus}
              onChange={handleChange}
            />
            <label htmlFor="showResponsibleStatus">Statut du responsable</label>
          </div>
          <div className='checkbox-container'>
            <input
              id="showAgentType"
              type="checkbox"
              name="showAgentType"
              checked={formValues.showAgentType}
              onChange={handleChange}
            />
            <label htmlFor="showAgentType">Fonction de l'agent</label>
          </div>
          <div className='checkbox-container'>
            <input
              id="showAgentStatus"
              type="checkbox"
              name="showAgentStatus"
              checked={formValues.showAgentStatus}
              onChange={handleChange}
            />
            <label htmlFor="showAgentStatus">Statut de l'agent</label>
          </div>
        </FilterRow>
      </Filters>
      <Buttons>
        <Button onClick={(e) => {
            e.preventDefault()
            onHandleSubmit(formValues)
          }}>
          Modifier
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            onDownloadCanvasImage()}
          }>
          Télécharger
        </Button>
      </Buttons>
    </Form>
  )
}

export default FiltersForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Form = styled.form`
  display: flex;
  align-items: start;
  justify-content: space-between;
`

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  input {
    padding: 3px 5px;
    color: ${COLOR_FORM_INPUT};

    &:focus {
      outline: none;
    }
  }

  input[type="date"] {
    border: none;
    width: 120px;
  }

  select {
    border: 1px solid ${COLOR_SELECT_INPUT_BORDER};
    border-radius: 5px;
    color: ${COLOR_FORM_INPUT};
    padding: 3px 5px;

    &:focus {
      outline: none;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Button = styled.button`
  display: block;
  width: 100%;
  background-color: transparent;
  border: 2px solid ${COLOR_BLUE_MEDIUM_DARK};
  border-radius: 5px;
  padding: 5px 10px;
  color: ${COLOR_BLUE_MEDIUM_DARK};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  transition: 0.3s opacity ease;

  &:hover {
    opacity: 1;
  }
`