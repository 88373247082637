import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_CATEGORY_TYPE,
  EMPTY_CATEGORY_BODY,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  ICategoriesContext,
  ICategoryFormValues,
} from "../../interfaces/category";
import {
  onCreateCategoryApi,
  onDeleteCategoryApi,
  onGetAllCategoriesApi,
  onGetCategoryApi,
  onUpdateCategoryApi,
  onGetCategoryFromAgentApi,
} from "../../api/category.api";

const CategoriesContext = createContext(null);

export function CategoriesProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateCategory = useCallback(
    async ({
      type,
      sigleBody,
      body,
      sigleGrade,
      grade,
      agentId,
      bodygradeId,
    }: ICategoryFormValues) => {
      if (!checkEmptyInput(type)) {
        return Promise.reject(EMPTY_CATEGORY_TYPE);
      }
      if (!checkEmptyInput(body)) {
        return Promise.reject(EMPTY_CATEGORY_BODY);
      }

      // console.log(
      //   "VALUES : ",
      //   type,
      //   sigleBody,
      //   body,
      //   sigleGrade,
      //   grade,
      //   agentId
      // );

      _setIsLoadingAgents(true);
      return onCreateCategoryApi({
        type,
        sigleBody,
        body,
        sigleGrade,
        grade,
        agentId,
        bodygradeId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllCategories = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllCategoriesApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetCategory = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetCategoryApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE FROM AGENT
  const onGetCategoryFromAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetCategoryFromAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateCategory = useCallback(
    async ({
      id,
      type,
      sigleBody,
      body,
      sigleGrade,
      grade,
      agentId,
      bodygradeId,
    }: ICategoryFormValues) => {
      _setIsLoadingAgents(true);
      return onUpdateCategoryApi({
        id,
        type,
        sigleBody,
        body,
        sigleGrade,
        grade,
        agentId,
        bodygradeId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteCategory = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onDeleteCategoryApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <CategoriesContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateCategory,
        onGetAllCategories,
        onGetCategory,
        onUpdateCategory,
        onDeleteCategory,
        onGetCategoryFromAgent,
      }}
    />
  );
}

export const useCategories = (): ICategoriesContext => {
  const context = React.useContext(CategoriesContext);
  if (!context)
    throw new Error("useCategories must be used in CategoriesProvider");

  return context;
};
