// Le tableau d'objet ci dessous permet de lister les element à mettre dans le sidebar
// de l'application. Lors de la création d'un nouvel élement, il faut indiquer le nom,
// aisin que le chemin associé
// ⚠️⚠️ Lors de la création d'un nouvel item, la route correspondante doit être ajouté
// au fichier "front/src/constants/cts_routes.tsx"

import { IMenuElements } from "../interfaces/menu";
import PATH from "./cts_routes";

export const sidebarElements: IMenuElements[] = [
  {
    name: "Accueil",
    to: PATH.home,
    logo: "ri-home-2-line",
    filledLogo: "ri-home-2-fill",
  },
  {
    name: "Agents",
    to: PATH.agents,
    logo: "ri-shield-user-line",
    filledLogo: "ri-shield-user-fill",
  },
  {
    name: "Agents Archivés",
    to: PATH.archivedAgents,
    logo: "ri-archive-line",
    filledLogo: "ri-archive-fill",
  },
  {
    name: "Services DLM",
    to: PATH.services,
    logo: "ri-home-gear-line",
    filledLogo: "ri-home-gear-fill",
  },
  {
    name: "Organigramme",
    to: PATH.organizationalChart,
    logo: "ri-organization-chart",
    filledLogo: "ri-organization-chart",
  },

  {
    name: "Nous contacter",
    to: PATH.contact,
    logo: "ri-mail-line",
    filledLogo: "ri-mail-fill",
  },
];

export const sidebarAdminElements: IMenuElements[] = [
  {
    name: "Agents Anonymisés",
    to: PATH.anonymisedAgents,
    logo: "ri-honour-line",
    filledLogo: "ri-honour-fill",
  },
  {
    name: "Utilisateurs",
    to: PATH.users,
    logo: "ri-folder-user-line",
    filledLogo: "ri-folder-user-fill",
  },
  {
    name: "Annuaire d'emplois",
    to: PATH.referensJobs,
    logo: "ri-git-repository-line",
    filledLogo: "ri-git-repository-fill",
  },
];
