import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import { IoPhonePortraitOutline } from "react-icons/io5";
import {
  MdAlternateEmail,
  MdOutlineAdminPanelSettings,
  MdOutlinePassword,
} from "react-icons/md";
import { IUserInfos } from "../../../interfaces/user";

const AddEditAccountForm = ({
  title,
  values,
  actionString,
  createAccount,
  handleChangeAccountData,
  handleSelectChange,
  handleSubmit,
}: {
  title: string;
  values: IUserInfos;
  actionString: string;
  createAccount: boolean;
  _setCreateAccount: Dispatch<SetStateAction<boolean>>;
  handleChangeAccountData: React.ChangeEventHandler<HTMLInputElement>;
  handleSelectChange: React.ChangeEventHandler<HTMLSelectElement>;
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (values) {
      _setIsEditMode(true);
    }
  }, []);

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form
            onSubmit={() => {
              handleSubmit;
            }}
          >
            <div className="inputs-container">
              {/* firstname */}
              <div>
                <FormLabel htmlFor="firstName" text="Prénom" />
                <FormInputContainer
                  icon={<AiOutlineUser />}
                  input={
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Prénom"
                      onChange={handleChangeAccountData}
                      value={values.firstName}
                      required
                    />
                  }
                />
              </div>
              {/* lastname */}
              <div>
                <FormLabel htmlFor="lastName" text="Nom" />
                <FormInputContainer
                  icon={<AiOutlineUser />}
                  input={
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Nom"
                      onChange={handleChangeAccountData}
                      value={values.lastName}
                      required
                    />
                  }
                />
              </div>
              {/* Email */}
              <div>
                <FormLabel htmlFor="email" text="E-mail" />
                <FormInputContainer
                  icon={<MdAlternateEmail />}
                  input={
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="prénom.nom@exemple.com"
                      onChange={handleChangeAccountData}
                      value={values.email}
                      required
                    />
                  }
                />
              </div>
              {/* PHONE NUMBER */}
              {/* professional phone number */}
              <div>
                <FormLabel
                  htmlFor="professionalPhoneNumber"
                  text="Téléphone Professionnel"
                />
                <FormInputContainer
                  icon={<IoPhonePortraitOutline />}
                  input={
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="06 12 34 56 78"
                      onChange={handleChangeAccountData}
                      value={values.phoneNumber}
                    />
                  }
                />
              </div>
              {/* ROLE NAME */}
              <div>
                <FormLabel htmlFor="roleName" text="Rôle" />
                <FormInputContainer
                  icon={<MdOutlineAdminPanelSettings />}
                  input={
                    <select
                      name="roleName"
                      id="roleName"
                      value={values.roleName}
                      onChange={handleSelectChange}
                      multiple={false}
                      placeholder={"Client"}
                      required
                    >
                      <option>Administrateur</option>
                      <option>Client</option>
                    </select>
                  }
                />
              </div>

              {/* PASSWORD */}
              {/* {!createAccount && ( */}
              <div>
                <FormLabel htmlFor="password" text="Mot de passe" />
                <FormInputContainer
                  icon={<MdOutlinePassword />}
                  input={
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      minLength={6}
                      placeholder={"Mot de passe"}
                      onChange={handleChangeAccountData}
                    />
                  }
                />
              </div>
              {/* )} */}

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button text={actionString} onClick={handleSubmit} />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  );
};

export default AddEditAccountForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
