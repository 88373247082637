import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import { IDivisionInfos } from "../../../interfaces/division";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues } from "../../../interfaces/agent";
import { IResponsibleFormValues } from "../../../interfaces/responsible";
import { useResponsibles } from "../../../common/contexts/responsibleContext";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import { BiRename } from "react-icons/bi";
import ToastAlert from "../../../components/ToastAlert";
import {
  RESPONSIBLE_ASSISTANT_TYPE,
  RESPONSIBLE_TYPE,
  RESPONSIBLE_DIRECTOR_TYPE,
  RESPONSIBLE_DIRECTOR_ASSISTANT_TYPE,
} from "../../../constants/cts_responsibles";

const AddEditResponsibleForm = ({
  title,
  division,
  formData,
  actionString,
  onResponsibleUpdated,
}: {
  title: string;
  division: IDivisionInfos;
  formData?: IResponsibleFormValues;
  actionString: string;
  onResponsibleUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    agentId: null,
    divisionId: null,
    responsibleType: null,
  };

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [formValues, _setFormValues] =
    useState<IResponsibleFormValues>(initialFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  const { onGetAllAgents, onGetAgent } = useAgents();
  const {
    onCreateResponsible,
    onUpdateResponsible,
    onGetAllResponsiblesFromDivision,
    onDeleteResponsible,
  } = useResponsibles();

  const [agents, _setAgents] = useState<Array<IAgentFormValues>>([]);

  const [responsiblesAgents, _setResponsiblesAgents] = useState<
    Array<IAgentFormValues>
  >([]);

  const [responsibles, _setResponsibles] = useState<
    Array<IResponsibleFormValues>
  >([]);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    // load agents
    onGetAllAgents().then((response) => {
      _setAgents(response);
    });

    onGetAllResponsiblesFromDivision(division.id).then((response: any) => {
      _setResponsibles(response);
    });

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isEditMode) {
      onCreateResponsible({
        agentId: formValues.agentId,
        divisionId: division.id,
        responsibleType: formValues.responsibleType,
      })
        .then(() => {
          _setToastMessage("Responsable ajouté avec succès !");
          _setToastVisible(true);
          onGetAllResponsiblesFromDivision(division.id).then((response) => {
            _setIsEditMode(false);
            _setResponsibles(response);
            _setFormValues(initialFormState);
          });
          if (onResponsibleUpdated) {
            onResponsibleUpdated();
          }
        })
        .catch((error) => _setErrorMessage(error));
    } else {
      onUpdateResponsible({
        id: formValues.id,
        agentId: formValues.agentId,
        divisionId: division.id,
        responsibleType: formValues.responsibleType,
      })
        .then(() => {
          _setToastMessage("Responsable mis à jour avec succès !");
          _setToastVisible(true);
          _setFormValues(initialFormState);
          onGetAllResponsiblesFromDivision(division.id).then((response) => {
            _setIsEditMode(false);
            _setResponsibles(response);
          });
          if (onResponsibleUpdated) {
            onResponsibleUpdated();
          }
        })
        .catch((error) => _setErrorMessage(error));
    }
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  // delete responsible
  const deleteResponsible = (agentStatusId: number) => {
    onDeleteResponsible(agentStatusId)
      .then(() => {
        _setToastMessage("Responsable supprimé avec succès !");
        _setToastVisible(true);
        _setConfirmModal(null);
        onGetAllResponsiblesFromDivision(division.id).then((response) => {
          _setResponsibles(response);
        });
        if (onResponsibleUpdated) {
          onResponsibleUpdated();
        }
      })
      .catch((err) => console.error(err));
  };

  // on delete
  const handleDelete = (responsibleId: number) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer ce responsable ?",
      confirmedAction: () => deleteResponsible(responsibleId),
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueResponsibles>
                <h4>- Responsables -</h4>
                {responsibles && responsibles.length > 0 && (
                  <div className="actual-responsibles">
                    {responsibles.map((responsible: any, key) => {
                      return (
                        <ResponsibleCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              if (responsible.id) handleDelete(responsible.id);
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-edit-line"
                            onClick={() => {
                              _setIsEditMode(true);
                              _setFormValues(responsible);
                            }}
                          ></EditDeleteIcons>

                          <h4>
                            {responsible.agent.firstName}{" "}
                            {responsible.agent.lastName}
                          </h4>
                          <p>-</p>
                          <h4>{responsible.responsibleType}</h4>
                        </ResponsibleCard>
                      );
                    })}
                  </div>
                )}
                {!responsibles ||
                  (responsibles.length < 1 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: COLOR_TEXT,
                        paddingTop: 12,
                      }}
                    >
                      Aucun responsable pour le moment.
                    </p>
                  ))}
              </HistoriqueResponsibles>

              {/* division */}
              <div>
                <FormLabel
                  htmlFor="divisionId"
                  text="Nom et Acronyme Service"
                />
                <FormInputContainer
                  icon={<i className="ri-home-gear-line" />}
                  input={
                    <input
                      type="text"
                      id="divisionId"
                      name="divisionId"
                      placeholder="Nom et Acronyme Service"
                      onChange={handleChange}
                      disabled
                      value={
                        division.name
                          ? division.name + " - " + division.acronym
                          : ""
                      }
                    />
                  }
                />
              </div>

              {/* responsible type */}
              <div>
                <FormLabel htmlFor="responsibleType" text="Rôle" />
                <ResponsibleTypeSelectContainer>
                  <FormInputContainer
                    icon={<BiRename />}
                    input={
                      <select
                        id="responsibleType"
                        name="responsibleType"
                        placeholder="Sélectionner le rôle"
                        onChange={handleChange}
                        value={
                          formValues.responsibleType
                            ? formValues.responsibleType
                            : ""
                        }
                      >
                        <option value="">Sélectionner le rôle</option>
                        <option value={RESPONSIBLE_DIRECTOR_TYPE}>
                          {RESPONSIBLE_DIRECTOR_TYPE}
                        </option>
                        <option value={RESPONSIBLE_DIRECTOR_ASSISTANT_TYPE}>
                          {RESPONSIBLE_DIRECTOR_ASSISTANT_TYPE}
                        </option>
                        <option value={RESPONSIBLE_TYPE}>
                          {RESPONSIBLE_TYPE}
                        </option>
                        <option value={RESPONSIBLE_ASSISTANT_TYPE}>
                          {RESPONSIBLE_ASSISTANT_TYPE}
                        </option>
                      </select>
                    }
                  />
                </ResponsibleTypeSelectContainer>
              </div>

              {/* agent */}
              <div>
                <FormLabel htmlFor="agentId" text="Qui a ce rôle ?" />
                <FormInputContainer
                  icon={<i className="ri-shield-user-line" />}
                  input={
                    <select
                      id="agentId"
                      value={formValues.agentId ? formValues.agentId : ""}
                      name="agentId"
                      onChange={handleChange}
                    >
                      <option value="">Sélection d'une personne</option>
                      {agents &&
                        agents.map((agent, key) => {
                          return (
                            <option key={key} value={agent.id}>
                              {agent.firstName} {agent.lastName}
                            </option>
                          );
                        })}
                    </select>
                  }
                />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={!isEditMode ? actionString : "Modifier"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditResponsibleForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueResponsibles = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }

  .actual-responsibles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ResponsibleCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const ResponsibleTypeSelectContainer = styled.div`
  margin-top: 10px;
`;
