import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_BONIFIEDVACATION_SIGLE,
  EMPTY_BONIFIEDVACATION_YEAR,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateBonifiedVacationApi,
  onDeleteBonifiedVacationApi,
  onGetAllBonifiedVacationsApi,
  onGetAllBonifiedVacationsFromAgentApi,
  onGetBonifiedVacationApi,
  onUpdateBonifiedVacationApi,
} from "../../api/bonifiedVacation.api";
import {
  IBonifiedVacationFormValues,
  IBonifiedVacationsContext,
} from "../../interfaces/bonifiedVacation";

const BonifiedVacationsContext = createContext(null);

export function BonifiedVacationsProvider(props: any) {
  const [isLoadingBonifiedVacations, _setIsLoadingBonifiedVacations] =
    useState(false);

  // CREATE
  const onCreateBonifiedVacation = useCallback(
    async ({ agentId, sigle, year }: IBonifiedVacationFormValues) => {
      // if (!checkEmptyInput(agentId)) {
      //   return Promise.reject(EMPTY_BonifiedVacation_NAME);
      // }
      if (!checkEmptyInput(sigle)) {
        return Promise.reject(EMPTY_BONIFIEDVACATION_SIGLE);
      }
      if (!checkEmptyInput(year)) {
        return Promise.reject(EMPTY_BONIFIEDVACATION_YEAR);
      }

      _setIsLoadingBonifiedVacations(true);
      return onCreateBonifiedVacationApi({
        agentId,
        sigle,
        year,
      })
        .then((response) => {
          _setIsLoadingBonifiedVacations(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingBonifiedVacations(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllBonifiedVacations = useCallback(() => {
    _setIsLoadingBonifiedVacations(true);
    return onGetAllBonifiedVacationsApi()
      .then((response) => {
        _setIsLoadingBonifiedVacations(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingBonifiedVacations(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetBonifiedVacation = useCallback((id: number) => {
    _setIsLoadingBonifiedVacations(true);
    return onGetBonifiedVacationApi(id)
      .then((response) => {
        _setIsLoadingBonifiedVacations(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE FROM AGENT ID
  const onGetAllBonifiedVacationsFromAgent = useCallback((id: number) => {
    _setIsLoadingBonifiedVacations(true);
    return onGetAllBonifiedVacationsFromAgentApi(id)
      .then((response) => {
        _setIsLoadingBonifiedVacations(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateBonifiedVacation = useCallback(
    async ({ id, agentId, sigle, year }: IBonifiedVacationFormValues) => {
      _setIsLoadingBonifiedVacations(true);
      return onUpdateBonifiedVacationApi({
        id,
        agentId,
        sigle,
        year,
      })
        .then((response) => {
          _setIsLoadingBonifiedVacations(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingBonifiedVacations(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteBonifiedVacation = useCallback((id: number) => {
    _setIsLoadingBonifiedVacations(true);
    return onDeleteBonifiedVacationApi(id)
      .then((response) => {
        _setIsLoadingBonifiedVacations(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingBonifiedVacations(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <BonifiedVacationsContext.Provider
      {...props}
      value={{
        isLoadingBonifiedVacations,
        // function
        onCreateBonifiedVacation,
        onGetAllBonifiedVacations,
        onGetBonifiedVacation,
        onUpdateBonifiedVacation,
        onDeleteBonifiedVacation,
        onGetAllBonifiedVacationsFromAgent,
      }}
    />
  );
}

export const useBonifiedVacations = (): IBonifiedVacationsContext => {
  const context = React.useContext(BonifiedVacationsContext);
  if (!context)
    throw new Error(
      "useBonifiedVacations must be used in BonifiedVacationsProvider"
    );

  return context;
};
