export const convertDateToString = (theDate: Date) => {
  if (theDate) {
    const months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(theDate);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[month]} ${year}`;
  } else {
    return null;
  }
};

export const isDate1EarlierThanDate2 = (
  firstDate: string | Date,
  secondDate: string | Date
) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  if (date1 < date2) {
    return true;
  }

  return false;
};

export const changeDateFormat = (theDate: Date | string) => {
  const date = new Date(theDate);
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
  return [date.getFullYear(), formatMonth, formatDate].join("-");
};

export const changeDateFormatDDMMYYYY = (theDate: Date | string) => {
  const date = new Date(theDate);
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let formatMonth = "";

  if (date.getMonth() + 1 < 10)
    formatMonth = "0" + (date.getMonth() + 1).toString();
  else formatMonth = (date.getMonth() + 1).toString();

  return [formatDate, formatMonth, date.getFullYear()].join("/");
};

export const changeDateFormatYYYYMMDD = (theDate: Date | string) => {
  if (theDate) {
    const date = new Date(theDate);
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const convertDateAndTimeToString = (theDate: Date) => {
  const months = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aout",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(theDate);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  let minutes = date.getMinutes() + "";

  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  return `${day} ${months[month]} ${year} / ${hour}:${minutes}`;
};

export function today(now = new Date()) {
  return (
    String(now.getFullYear()) +
    "-" +
    String(now.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(now.getDate()).padStart(2, "0")
  );
}

export function getToday(now = new Date()) {
  return new Date(now);
}
