import { IBodyGradeFormValues } from "../interfaces/bodyGrade";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateBodyGradeApi = async ({
  categoryType,
  sigleBody,
  body,
  sigleGrade,
  grade,
}: IBodyGradeFormValues) => {
  return instanceAxios
    .post("/bodies-grades/create-body-grade", {
      categoryType,
      sigleBody,
      body,
      sigleGrade,
      grade,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllBodiesGradesApi = async () => {
  return instanceAxios
    .get(`/bodies-grades/get-all-bodies-grades`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetBodyGradeApi = async (id: number) => {
  return instanceAxios
    .get(`/bodies-grades/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE FROM AGENT
export const onGetBodyGradeFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/bodies-grades/unique-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteBodyGradeApi = async (id: number) => {
  return instanceAxios
    .delete(`/bodies-grades/delete-body-grade/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// // UPDATE ONE
// export const onUpdateCategoryApi = async ({
//   id,
//   type,
//   body,
//   grade,
//   agentId,
// }: ICategoryFormValues) => {
//   return instanceAxios
//     .put("/bodies-grades/update-category/" + id, {
//       type,
//       body,
//       grade,
//       agentId,
//     })
//     .then((response) => (response.data ? response.data.data : null))
//     .catch((error) => {
//       throw error.response ? error.response.data : error.message;
//     });
// };
