import { IBonifiedVacationFormValues } from "../interfaces/bonifiedVacation";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateBonifiedVacationApi = async ({
  agentId,
  sigle,
  year,
}: IBonifiedVacationFormValues) => {
  return instanceAxios
    .post("/bonified-vacations/create-bonified-vacation", {
      agentId,
      sigle,
      year,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllBonifiedVacationsApi = async () => {
  return instanceAxios
    .get(`/bonified-vacations/get-all-bonified-vacations`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT ID
export const onGetAllBonifiedVacationsFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/bonified-vacations/get-all-bonified-vacations-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetBonifiedVacationApi = async (id: number) => {
  return instanceAxios
    .get(`/bonified-vacations/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteBonifiedVacationApi = async (id: number) => {
  return instanceAxios
    .delete(`/bonified-vacations/delete-bonified-vacation/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateBonifiedVacationApi = async ({
  id,
  agentId,
  sigle,
  year,
}: IBonifiedVacationFormValues) => {
  return instanceAxios
    .put("/bonified-vacations/update-bonified-vacation/" + id, {
      agentId,
      sigle,
      year,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
