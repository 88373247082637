import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  COLOR_INPUT_ICON_BACKGROUND,
  COLOR_PLACEHOLDER,
  COLOR_TEXT,
} from "../../constants/cts_colors";

const FormInputContainer = ({
  icon,
  input,
}: {
  icon?: ReactNode;
  input?: ReactNode;
}) => {
  return (
    <InputContainer>
      {icon && <div className="icon-container">{icon}</div>}
      {input && input}
    </InputContainer>
  );
};

export default FormInputContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 16px;

  .icon-container {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    height: calc(100% - 10px); // 100% - ( padding top + padding bottom )
    aspect-ratio: 1/1;
    background-color: ${COLOR_INPUT_ICON_BACKGROUND};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input,
  select {
    padding: 5px;
    width: 100%;
    color: ${COLOR_TEXT};
    font-size: 1.1rem;
    text-align: center;

    &::placeholder {
      color: ${COLOR_PLACEHOLDER};
    }

    @media (max-width: 800px) {
      font-size: 3vw;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    aspect-ratio: 1/1;
    background-color: ${COLOR_INPUT_ICON_BACKGROUND};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea {
    padding: 5px;
    width: 100%;
    height: 110px;
    color: ${COLOR_TEXT};
    font-size: 0.8rem;
    resize: none;

    &::placeholder {
      color: ${COLOR_PLACEHOLDER};
    }

    @media (max-width: 800px) {
      font-size: 3vw;
    }
  }
`;
