import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
  COLOR_WARNING,
} from "../../../constants/cts_colors";
import {
  IBonifiedVacationFormValues,
  IBonifiedVacationInfos,
} from "../../../interfaces/bonifiedVacation";
import { useBonifiedVacations } from "../../../common/contexts/bonifiedVacationContext";
import ToastAlert from "../../../components/ToastAlert";
import Modal from "../../../components/Modal";

const AddEditBonusVacationsAgentForm = ({
  title,
  agentId,
  formData,
  actionString,
  onAgentBonusVacationsUpdated,
}: {
  title: string;
  agentId: number;
  formData?: IBonifiedVacationFormValues;
  actionString: string;
  onAgentBonusVacationsUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    agentId: agentId,
    sigle: null,
    year: null,
  };

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [formValues, _setFormValues] =
    useState<IBonifiedVacationFormValues>(initialFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const {
    onCreateBonifiedVacation,
    onGetAllBonifiedVacationsFromAgent,
    onDeleteBonifiedVacation,
    onUpdateBonifiedVacation,
  } = useBonifiedVacations();

  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  const [bonifiedVacations, _setBonifiedVacations] = useState<
    Array<IBonifiedVacationInfos>
  >([]);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    // console.log("COMPONENT AGENT : ", agentId);

    if (agentId) {
      onGetAllBonifiedVacationsFromAgent(agentId).then((response: any) => {
        _setBonifiedVacations(response);
      });
    }

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isEditMode) {
      // if is on edit mode, update the agent
      onCreateBonifiedVacation(formValues)
        .then(() => {
          _setToastMessage("Congé bonifié ajouté avec succès !");
          _setToastVisible(true);
          _setFormValues(initialFormState);
          onGetAllBonifiedVacationsFromAgent(agentId).then((response: any) => {
            _setBonifiedVacations(response);
          });
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    } else {
      onUpdateBonifiedVacation(formValues).then(() => {
        _setToastMessage("Congé bonifié mis à jour avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        onGetAllBonifiedVacationsFromAgent(agentId).then((response: any) => {
          _setBonifiedVacations(response);
        });
      });
    }
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  // delete agent category
  const deleteAgentBonifiedVacation = (bonifiedVacationId: number) => {
    onDeleteBonifiedVacation(bonifiedVacationId)
      .then(() => {
        _setToastMessage("Congé bonifié supprimé avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        _setConfirmModal(null);
        onGetAllBonifiedVacationsFromAgent(agentId).then((response: any) => {
          _setBonifiedVacations(response);
        });
      })
      .catch((err) => console.error(err));
  };

  // on delete
  const handleDelete = (bonifiedVacationId: number) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer ce congé bonifié ?",
      confirmedAction: () => deleteAgentBonifiedVacation(bonifiedVacationId),
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueBonified>
                <h4 style={{ marginBottom: 8, color: COLOR_TITLE }}>
                  {" "}
                  - Les 3 derniers -{" "}
                </h4>
                {bonifiedVacations && bonifiedVacations.length > 0 && (
                  <div className="latest-vacations">
                    {bonifiedVacations.map((bonifiedVacation, key) => {
                      return (
                        <BonifiedVacationCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              handleDelete(bonifiedVacation.id);
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-edit-line"
                            onClick={() => {
                              _setIsEditMode(true);
                              _setFormValues(bonifiedVacation);
                            }}
                          ></EditDeleteIcons>
                          <p>{bonifiedVacation.year}</p>
                          <p>-</p>
                          <p>{bonifiedVacation.sigle}</p>
                        </BonifiedVacationCard>
                      );
                    })}
                  </div>
                )}
                {!bonifiedVacations ||
                  (bonifiedVacations.length < 1 && (
                    <p style={{ textAlign: "center", color: COLOR_TITLE }}>
                      Aucun congés bonifiés pour le moment.
                    </p>
                  ))}
              </HistoriqueBonified>
              {/* add année congé 1 */}
              <div>
                <FormLabel htmlFor="year" text="Année" />
                <FormInputContainer
                  input={
                    <input
                      type="number"
                      id="year"
                      name="year"
                      placeholder="Année"
                      onChange={handleChange}
                      value={formValues.year ? formValues.year : ""}
                    />
                  }
                />
              </div>

              {/* add congé 1 */}
              <div>
                <FormLabel htmlFor="sigle" text="Sigle" />
                <FormInputContainer
                  input={
                    <input
                      type="text"
                      id="sigle"
                      name="sigle"
                      placeholder="Sigle"
                      onChange={handleChange}
                      value={formValues.sigle ? formValues.sigle : ""}
                    />
                  }
                />
              </div>
              <WarningDiv>
                <i className="ri-error-warning-fill"></i>

                <p style={{ fontStyle: "italic", color: COLOR_TITLE }}>
                  Attention, seuls les 3 récents derniers congés bonifiés sont
                  stockés, le plus vieux congé bonifié sera donc supprimé.
                </p>
              </WarningDiv>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={isEditMode ? "Modifier" : actionString}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditBonusVacationsAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueBonified = styled.div`
  h4 {
    text-align: center;
  }
`;

const BonifiedVacationCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const WarningDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  i {
    color: ${COLOR_WARNING};
    font-size: 24px;
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
