import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import {
  IAgentFileFormValues,
  IAgentFileInfos,
} from "../../../interfaces/agentFile";
import { useAgentsFiles } from "../../../common/contexts/agentFileContext";
import InputFile from "../../../components/InputFile";
import { useTranslation } from "react-i18next";
import ToastAlert from "../../../components/ToastAlert";
import { useFiles } from "../../../common/contexts/fileContext";
import { IFileInfos } from "../../../interfaces/file";

const AddEditAgentFilesForm = ({
  title,
  agentId,
  formData,
  actionString,
  onAgentFilesUpdated,
}: {
  title: string;
  agentId: number;
  formData?: IAgentFileFormValues;
  actionString: string;
  onAgentFilesUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    agentId: agentId,
    filePath: null,
  };

  const { t } = useTranslation();

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [formValues, _setFormValues] =
    useState<IAgentFileFormValues>(initialFormState);

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const [resetFormValues, _setResetFormValue] = useState<boolean>(false);

  const { onCreateAgentFile, onGetAgentFiles, onDeleteAgentFile } =
    useAgentsFiles();
  const { onReadFile } = useFiles();

  const [files, _setFiles] = useState<Array<IAgentFileInfos>>([]);
  const [agentFiles, _setAgentFiles] = useState<Array<IFileInfos>>([]);
  const [agentFilesName, _setAgentFilesName] = useState<Array<string>>([]);

  const [agentFile, _setAgentFile] = useState<File>();

  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (agentId) {
      onGetAgentFiles(agentId).then(async (response: any) => {
        _setFiles(response);
        for (let i = 0; i < response.length; i++) {
          agentFilesName.push(
            response[i]["filePath"].split("-").slice(10).join(" ")
          );
        }
      });
    }

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // delete agent inactivity
  const deleteAgentFile = (agentFileName: string) => {
    onDeleteAgentFile(agentFileName).then((response) => {
      // console.log(response);
      _setToastMessage("Fichier supprimé avec succès !");
      _setToastVisible(true);
      _setAgentFilesName([]);
      _setConfirmModal(null);

      onGetAgentFiles(agentId).then(async (response: any) => {
        _setFiles(response);

        for (let i = 0; i < response.length; i++) {
          _setAgentFilesName([
            ...agentFilesName,
            response[i]["filePath"].split("-").slice(10).join(" "),
          ]);
        }
      });
    });
  };

  // on delete
  const handleDelete = (agentFileName: string) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer ce fichier ?",
      confirmedAction: () => deleteAgentFile(agentFileName),
    });
  };

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onCreateAgentFile({
      agentId,
      filePath: agentFile,
    }).then((response) => {
      _setAgentFile(undefined);
      _setToastMessage("Fichier ajouté avec succès !");
      _setToastVisible(true);
      _setResetFormValue(true);
      _setAgentFilesName([]);

      onGetAgentFiles(agentId).then(async (response: any) => {
        _setFiles(response);

        for (let i = 0; i < response.length; i++) {
          _setAgentFilesName([
            ...agentFilesName,
            response[i]["filePath"].split("-").slice(10).join(" "),
          ]);
        }
      });

      _setResetFormValue(false);
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueFiles>
                <h4>- Fichiers -</h4>
                {files && files.length > 0 && (
                  <div className="latest-files">
                    {files.map((file, key) => {
                      return (
                        <FileCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              handleDelete(file.filePath.toString());
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-eye-line"
                            onClick={() => {
                              window.open(
                                `http://localhost:8080/api/files/get/${file.filePath}`,
                                "_blank",
                                "fullscreen=yes"
                              );
                              return false;
                            }}
                          ></EditDeleteIcons>
                          <h4>{agentFilesName[key]}</h4>
                        </FileCard>
                      );
                    })}
                  </div>
                )}
                {!files ||
                  (files.length < 1 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: COLOR_TEXT,
                        paddingTop: 12,
                      }}
                    >
                      Aucun fichier pour le moment.
                    </p>
                  ))}
              </HistoriqueFiles>

              {/* file */}
              <div>
                {/* INPUT FILE */}
                <InputFile
                  _setFile={_setAgentFile}
                  id="filePath"
                  name="filePath"
                  onReset={resetFormValues}
                />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={!isEditMode ? actionString : "Modifier"}
                  onClick={handleSubmit}
                  disabled={!agentFile}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* FILE PREVIEW */}
      {/* <PreviewFile></PreviewFile> */}

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditAgentFilesForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;

    .latest-files {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 4px;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueFiles = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const FileCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};
  width: 180px;

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const PreviewFile = styled.embed`
  width: 100%;
  height: 100%;
  background-color: red;
`;
