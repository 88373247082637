import React, { useState, createContext, useCallback } from "react";
import { onGetOrgChartDataByDivisionApi } from "../../api/orgChart.api";
import { IOrgChartContext } from "../../interfaces/orgChart";

const OrgChartContext = createContext(null);

export function OrgChartProvider(props : any) {
  const [ isLoadingOrgChart, _setIsLoadingOrgChart ] = useState(false);

  // GET ORG CHART DATA BY DIVISION
  const onGetOrgChartDataByDivision = useCallback(async (divisionId: number) => {
    _setIsLoadingOrgChart(true);
    return onGetOrgChartDataByDivisionApi(divisionId)
      .then((response) => {
        _setIsLoadingOrgChart(false)
        return (response)
      })
      .catch((error) => {
        _setIsLoadingOrgChart(false)
        throw error
      })
  }, []);

  return (
    <OrgChartContext.Provider
      {...props}
      value={{
        isLoadingOrgChart,
        // functions
        onGetOrgChartDataByDivision,
      }}
    />
  );
}

export const useOrgChart = () : IOrgChartContext => {
  const context = React.useContext(OrgChartContext);
  if (!context)
    throw new Error(
      "useOrgChart must be used in OrgChartProvider"
    );

  return context;
};