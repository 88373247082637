import { IReferensJobFormValues } from "../interfaces/referensJob";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateReferensJobApi = async ({
  branchCode,
  branch,
  professionalFamily,
  reme,
  idStatutoryCorrespondence,
  codeStatutoryCorrespondence,
  statutoryCorrespondence,
  desiredTraining,
  jobs,
  mission,
  fapReme,
  mainActivities,
  specialExerciseConditions,
  knowledge,
  operationalSkills,
  behavioralSkills,
  statutoryDiplomaRequired,
  mediumtermDevelopmentFactors,
  impactOnJob,
  codeJob,
  jobTitle,
  previousCodeJob,
  previousJobTitle,
  tricarto,
  businessFamilyCode,
  desiredExperience,
  trifap,
  categoryReferens,
  referensSituationType,
  referensSituation,
  isExternalCompetition,
  externalCompetitionProgram,
  externalCompetitionProgramNormal,
  externalCompetitionProgramSuperior,
  pdfFile,
  referensKnowledgeDefinition,
  referensOperationalSkillsDefinition,
  referensBehavioralSkillsDefinition,
  idSkills,
  bap,
  fap,
  description,
}: IReferensJobFormValues) => {
  return instanceAxios
    .post("/referens-jobs/create-referens-job", {
      branchCode,
      branch,
      professionalFamily,
      reme,
      idStatutoryCorrespondence,
      codeStatutoryCorrespondence,
      statutoryCorrespondence,
      desiredTraining,
      jobs,
      mission,
      fapReme,
      mainActivities,
      specialExerciseConditions,
      knowledge,
      operationalSkills,
      behavioralSkills,
      statutoryDiplomaRequired,
      mediumtermDevelopmentFactors,
      impactOnJob,
      codeJob,
      jobTitle,
      previousCodeJob,
      previousJobTitle,
      tricarto,
      businessFamilyCode,
      desiredExperience,
      trifap,
      categoryReferens,
      referensSituationType,
      referensSituation,
      isExternalCompetition,
      externalCompetitionProgram,
      externalCompetitionProgramNormal,
      externalCompetitionProgramSuperior,
      pdfFile,
      referensKnowledgeDefinition,
      referensOperationalSkillsDefinition,
      referensBehavioralSkillsDefinition,
      idSkills,
      bap,
      fap,
      description,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllReferensJobsApi = async () => {
  return instanceAxios
    .get(`/referens-jobs/get-all-referens-jobs`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetReferensJobApi = async (id: number) => {
  return instanceAxios
    .get(`/referens-jobs/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE FROM CODE JOB
export const onGetReferensJobFromCodeApi = async (codeJob: string) => {
  return instanceAxios
    .get(`/referens-jobs/get-from-code/${codeJob}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteReferensJobApi = async (id: number) => {
  return instanceAxios
    .delete(`/referens-jobs/delete-referens-job/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateReferensJobApi = async ({
  id,
  branchCode,
  branch,
  professionalFamily,
  reme,
  idStatutoryCorrespondence,
  codeStatutoryCorrespondence,
  statutoryCorrespondence,
  desiredTraining,
  jobs,
  mission,
  fapReme,
  mainActivities,
  specialExerciseConditions,
  knowledge,
  operationalSkills,
  behavioralSkills,
  statutoryDiplomaRequired,
  mediumtermDevelopmentFactors,
  impactOnJob,
  codeJob,
  jobTitle,
  previousCodeJob,
  previousJobTitle,
  tricarto,
  businessFamilyCode,
  desiredExperience,
  trifap,
  categoryReferens,
  referensSituationType,
  referensSituation,
  isExternalCompetition,
  externalCompetitionProgram,
  externalCompetitionProgramNormal,
  externalCompetitionProgramSuperior,
  pdfFile,
  referensKnowledgeDefinition,
  referensOperationalSkillsDefinition,
  referensBehavioralSkillsDefinition,
  idSkills,
  bap,
  fap,
  description,
}: IReferensJobFormValues) => {
  return instanceAxios
    .put("/referens-jobs/update-referens-job/" + id, {
      branchCode,
      branch,
      professionalFamily,
      reme,
      idStatutoryCorrespondence,
      codeStatutoryCorrespondence,
      statutoryCorrespondence,
      desiredTraining,
      jobs,
      mission,
      fapReme,
      mainActivities,
      specialExerciseConditions,
      knowledge,
      operationalSkills,
      behavioralSkills,
      statutoryDiplomaRequired,
      mediumtermDevelopmentFactors,
      impactOnJob,
      codeJob,
      jobTitle,
      previousCodeJob,
      previousJobTitle,
      tricarto,
      businessFamilyCode,
      desiredExperience,
      trifap,
      categoryReferens,
      referensSituationType,
      referensSituation,
      isExternalCompetition,
      externalCompetitionProgram,
      externalCompetitionProgramNormal,
      externalCompetitionProgramSuperior,
      pdfFile,
      referensKnowledgeDefinition,
      referensOperationalSkillsDefinition,
      referensBehavioralSkillsDefinition,
      idSkills,
      bap,
      fap,
      description,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
