import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import { IDivisionInfos } from "../../../interfaces/division";
import {
  IAgentAddEditDivisionFormValues,
  IAgentInfos,
} from "../../../interfaces/agent";
import { COLOR_RED_BRIGHT, COLOR_TEXT } from "../../../constants/cts_colors";
import ToastAlert from "../../../components/ToastAlert";
import { useAgents } from "../../../common/contexts/agentContext";
import { useDivisions } from "../../../common/contexts/divisionContext";
import { AiOutlineUser } from "react-icons/ai";

const AddEditAgentDivisionForm = ({ agent }: { agent: IAgentInfos }) => {
  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");
  const [formValues, _setFormValues] =
    useState<IAgentAddEditDivisionFormValues>({
      agentId: agent.id,
      divisionId: agent.division ? agent.division.id : null,
    });
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const [divisions, _setDivisions] = useState<Array<IDivisionInfos>>([]);
  const { onGetAllDivisions } = useDivisions();
  const { onAddEditAgentDivision } = useAgents();
  const [isFormSubmitted, _setIsFormSubmitted] = useState<boolean>(false);

  // get the divisions
  useEffect(() => {
    onGetAllDivisions()
      .then((response) => _setDivisions(response))
      .catch((error) => console.error(error));
  }, []);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    onAddEditAgentDivision({
      agentId: agent.id,
      divisionId: formValues.divisionId,
    })
      .then(() => {
        _setToastMessage("L'agent a été ajouté avec succès au service DLM !");
        _setIsFormSubmitted(true);
        _setToastVisible(true);
      })
      .catch((error) => console.error(error));
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  return (
    <FormBase title="Service DLM">
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* agent */}
              <div>
                <FormLabel htmlFor="agentId" text="Agent" />
                <FormInputContainer
                  icon={<AiOutlineUser />}
                  input={
                    <input
                      type="text"
                      id="agentId"
                      name="agentId"
                      placeholder="Service DLM"
                      onChange={handleChange}
                      value={agent.firstName + " " + agent.lastName}
                      disabled
                    />
                  }
                />
              </div>

              {/* division */}
              <div>
                <FormLabel htmlFor="divisionId" text="Service DLM" />
                <FormInputContainer
                  icon={<i className="ri-home-gear-line" />}
                  input={
                    <select
                      id="divisionId"
                      value={formValues.divisionId ? formValues.divisionId : ""}
                      name="divisionId"
                      onChange={handleChange}
                    >
                      <option value="">Service DLM</option>
                      {divisions &&
                        divisions.map((division) => {
                          return (
                            <option
                              key={"division-" + division.id}
                              value={division.id}
                            >
                              {division.name}{" "}
                              {division.acronym ? `- ${division.acronym}` : ""}
                            </option>
                          );
                        })}
                    </select>
                  }
                />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text="Ajouter"
                  onClick={handleSubmit}
                  disabled={isFormSubmitted}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}
    </FormBase>
  );
};

export default AddEditAgentDivisionForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
