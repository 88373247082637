import { ITeleworkingFormValues } from "../interfaces/teleworking";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateTeleworkingApi = async ({
  agentId,
  days,
}: ITeleworkingFormValues) => {
  return instanceAxios
    .post("/teleworking/create-teleworking", {
      agentId,
      days,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllTeleworkingApi = async () => {
  return instanceAxios
    .get(`/teleworking/get-all-teleworking`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE FROM AGENT
export const onGetTeleworkingFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/teleworking/get-one-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteTeleworkingApi = async (id: number) => {
  return instanceAxios
    .delete(`/teleworking/delete-teleworking/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateTeleworkingApi = async ({
  agentId,
  days,
}: ITeleworkingFormValues) => {
  return instanceAxios
    .put("/teleworking/update-teleworking", {
      agentId,
      days,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
