import React from "react";
import styled from "styled-components";
import { COLOR_ERROR_MESSAGE } from "../../constants/cts_colors";

const FormErrorMessageContainer = ({errorMessage}: {errorMessage: string}) => {
  return <Container>
    {errorMessage}
  </Container>
}

export default FormErrorMessageContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  display: block;
  font-size: 0.9rem;
  color: ${COLOR_ERROR_MESSAGE};

  @media (max-width: 800px) {
    font-size: 2.5vw;
  }
`