import { IAgentFileFormValues } from "../interfaces/agentFile";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateAgentFileApi = async ({
  agentId,
  filePath,
}: IAgentFileFormValues) => {
  // console.log("FILE FRONT API ", filePath);

  const bodyFormData = new FormData();

  if (agentId) bodyFormData.append("agentId", agentId?.toString());
  if (filePath) bodyFormData.append("filePath", filePath);

  return instanceAxios
    .post("/agents-files/create-agent-file", bodyFormData)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllAgentsFilesApi = async () => {
  return instanceAxios
    .get(`/agents-files/get-all-agents-files`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetAgentFilesApi = async (id: number) => {
  return instanceAxios
    .get(`/agents-files/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteAgentFileApi = async (name: string) => {
  return instanceAxios
    .delete(`/agents-files/delete-agent-file/${name}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateAgentFileApi = async ({
  id,
  agentId,
  filePath,
}: IAgentFileFormValues) => {
  return instanceAxios
    .put("/agents-files/update-agent-file/" + id, {
      agentId,
      filePath,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
