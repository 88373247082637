import { MouseEventHandler } from "react";
import styled from "styled-components";
import {
  COLOR_TEXT_MUTED,
  COLOR_TITLE,
  COLOR_WHITE,
} from "../constants/cts_colors";

const DayCard = ({
  text,
  onClick,
  checked,
}: {
  text: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  checked: boolean;
}) => {
  return (
    <DayCardStyles onClick={onClick}>
      <div className={checked ? "selected" : ""}>
        <p className={checked ? "selected" : ""}>{text}</p>
      </div>
    </DayCardStyles>
  );
};

export default DayCard;

const DayCardStyles = styled.div`
  div {
    background-color: ${COLOR_WHITE};
    padding: 8px;
    border-radius: 4px;
    border: 1px solid ${COLOR_TEXT_MUTED};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100px;
    cursor: pointer;
    margin-bottom: 8px;
  }

  div.selected {
    background-color: ${COLOR_TITLE};
    color: ${COLOR_WHITE};
    border: 1px solid ${COLOR_TITLE};
  }

  p {
    color: ${COLOR_TEXT_MUTED};
    font-weight: bold;
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none;
  }

  p.selected {
    color: ${COLOR_WHITE};
  }

  label {
    font-style: italic;
    color: ${COLOR_TEXT_MUTED};
    cursor: pointer;
  }
`;
