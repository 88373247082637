import { useEffect } from "react";
import styled from "styled-components";
import BodyContainer from "../../components/BodyContainer";
import { getPageUrl } from "../../locales/i18n";
import PATH from "../../constants/cts_routes";
import { Link } from "react-router-dom";
import { COLOR_LINK, COLOR_LINK_HOVER } from "../../constants/cts_colors";

const CGV = () => {

  // set title of the page
  useEffect(() => {
    document.title = "Conditions Générales de Vente | Sorbonne";
  }, []);

  return (
    <BodyContainer title="Conditions Générales de Vente">
      <Wrapper>
        <p>
          En cas de question vous pouvez contacter le Responsable du Service
          Administratif et Financier.
        </p>
        <p>
          <br />Vous pouvez contacter cette personne en utilisant ce mail :{" "}
          <b>sciences-DLM-RH@admp6.jussieu.fr</b>
        </p>
        <br />
        <Link className="link" to={getPageUrl(PATH.login)}>
          Retour à la page de connexion
        </Link>
      </Wrapper>
    </BodyContainer>
  );
};

export default CGV;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .link {
    display: block;
    width: max-content;
    color: ${COLOR_LINK};
    text-decoration: none;

    @media (max-width: 800px) {
      font-size: 2.5vw;
    }

    &:hover {
      color: ${COLOR_LINK_HOVER};
    }
  }
`;
