// DIVISION
export const DIVISION = {
  direction: {
    type: 'direction',
    level: 0,
  },
  service: {
    type: 'service',
    level: -1,
  },
  department: {
    type: 'pôle',
  },
  cell: {
    type: 'cellule',
  },
  team: {
    type: 'équipe',
  },
}

// DIVISION REF
export const CUSTOM_POSITION_DIVISION_REFS = {
  // service
  service_administratif_et_financier: "0.0",

  // service
  pole_relation_et_service_a_l_usager: "0.1",

  // service
  service_gestion_des_espaces: "0.6",

  // service
  coordination_technique_ssi: "0.7.0",
}