import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { RiNotificationFill } from "react-icons/ri";
import { COLOR_NOTIFICATIONS_ICON, COLOR_NOTIFICATIONS_LINK, COLOR_RED_BRIGHT, COLOR_WHITE } from "../constants/cts_colors";
import { NavLink } from "react-router-dom";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import { INotificationInfos } from "../interfaces/notification";

const NotificationsBtn = ({notifications}: {notifications: INotificationInfos[]}) => {
  const [ isOpend, _setIsOpened ] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [activeNotifications, _setActiveNotifications] = useState<INotificationInfos[]>([]);

  // DETECT WHEN THE USER CLICKS OUTSIDE THE ACTIONS POPUP COMPONENT
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      const actionsContainer = btnRef.current;
      if(actionsContainer && !actionsContainer.contains(event.target as Node)) {
        _setIsOpened(false);
      }
    }
    
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [btnRef]);

  // SET ACTIVE NOTIFICATIONS
  useEffect(() => {
    const result = notifications.filter((notification) => notification.isActive)
    _setActiveNotifications(result)
  }, [notifications])


  return (
    <Button onClick={() => _setIsOpened((lastVal) => !lastVal)} ref={btnRef}>
      <RiNotificationFill className="icon" />
      {
        activeNotifications.length > 0 &&
        <ActiveNotificationsInfoNumber>
          {activeNotifications.length > 9 ? '9+' : activeNotifications.length}
        </ActiveNotificationsInfoNumber>
      }
      {
        isOpend &&
        <Menu ref={menuRef}>
          <SeeMoreContainer>
            <StyledNavLink
              to={getPageUrl(PATH.notifications)}
            >
              Voir plus...
            </StyledNavLink>
          </SeeMoreContainer>
          <NotificationsContainer>
            {
              activeNotifications.length > 0 ? (
                activeNotifications.map((notification) => 
                  <StyledNavLink
                    to={getPageUrl(`${PATH.notification}/${notification.id}`)}
                    className={`notification ${notification.isActive ? 'active' : ''}`}
                    key={'notification-' + notification.id}
                    >
                      {notification.text.length > 23 ? notification.text.substring(0, 20) + '...' : notification.text}
                  </StyledNavLink>)
              ) : (
                <div className="not-found-data">Aucune notification trouvée</div>
              )
            }
          </NotificationsContainer>
        </Menu>
      }
    </Button>
  )
}

export default NotificationsBtn;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;

  .icon {
    width: 20px;
    height: 20px;
    color: ${COLOR_NOTIFICATIONS_ICON};
    opacity: 0.8;
    transition: 0.3s opacity ease;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`

const ActiveNotificationsInfoNumber = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${COLOR_RED_BRIGHT};
  color: ${COLOR_WHITE};
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
`

const Menu = styled.div`
  position: absolute;
  background-color: rgba(230, 51, 42, 0.8);
  width: calc(200px);
  max-height: 210px;
  bottom: 35px;
  left: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .notification {
    color: ${COLOR_WHITE};
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding: 12px 24px;
    cursor: pointer;

    &:hover {
      background-color: rgba(230, 51, 42);
    }

    &.active {
      font-weight: bold;
    }
  }

  .not-found-data {
    color: ${COLOR_WHITE};
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding: 12px 24px;
  }
`

const SeeMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding: 7px 24px;
`

const NotificationsContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledNavLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: ${COLOR_NOTIFICATIONS_LINK};
  width: max-content;
  font-weight: bold;
  font-size: 12px;
  transition: 0.3s color ease;

  &:hover {
    color: ${COLOR_WHITE};
  }
`;