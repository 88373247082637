import { IResponsibleFormValues } from "../interfaces/responsible";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateResponsibleApi = async ({
  agentId,
  divisionId,
  responsibleType,
}: IResponsibleFormValues) => {
  return instanceAxios
    .post("/responsibles/create-responsible", {
      agentId,
      divisionId,
      responsibleType,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllResponsiblesApi = async () => {
  return instanceAxios
    .get(`/responsibles/get-all-responsible`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT
export const onGetAllResponsiblesFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/responsibles/get-all-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM DIVISION
export const onGetAllResponsiblesFromDivisionApi = async (id: number) => {
  return instanceAxios
    .get(`/responsibles/get-all-from-division/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetResponsibleApi = async (id: number) => {
  return instanceAxios
    .get(`/responsibles/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteResponsibleApi = async (id: number) => {
  return instanceAxios
    .delete(`/responsibles/delete-responsible/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateResponsibleApi = async ({
  id,
  agentId,
  divisionId,
  responsibleType,
}: IResponsibleFormValues) => {
  return instanceAxios
    .put("/responsibles/update-responsible/" + id, {
      agentId,
      divisionId,
      responsibleType,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
