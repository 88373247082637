export const COLOR_BACKGROUND = "#F5F5F6";
export const COLOR_TEXT = "#333333";
export const COLOR_FORM_HEADER = "#192139";
export const COLOR_TEXT_MUTED = "#99A0AC";
export const COLOR_FORM_INPUT = "#495057";
export const COLOR_FORM_BTN = "#448BFF";
export const COLOR_ACTIVE_LINK = "#448BFF";
export const COLOR_WHITE = "#FFF";
export const COLOR_BLACK = "#000";
export const COLOR_BLUE_MORE = "#14182C";
export const COLOR_BUTTON = "#535C78";
export const COLOR_BTN = "#E6332A";
export const COLOR_BTN_HOVER = "#C62017";
export const COLOR_LINK = "#E6332A";
export const COLOR_LINK_HOVER = "#C62017";
export const COLOR_TITLE = "#1D2769";
export const COLOR_PLACEHOLDER = "#767676";
export const COLOR_ERROR_MESSAGE = "#E6332A";
export const COLOR_SIDEBAR = "#EDEDED";
export const COLOR_BLUE_MEDIUM_DARK = "#1D2769";
export const COLOR_RED_BRIGHT = "#E6332A";
export const COLOR_TABLE_TEXT = "#535C78";
export const COLOR_TABLE_ROW_BORDER = "#F5F5F6";
export const COLOR_TABLE_HEADER = "#FFF";
export const COLOR_TABLE_HEADER_TOP = "#FAFAFA";
export const COLOR_TABLE_BODY = "rgba(255, 255, 255, 0.95)";
export const COLOR_MODAL_BACKGROUND = "#FFF";
export const COLOR_FORM_LABEL = "#1D2769";
export const COLOR_INPUT_ICON_BACKGROUND = "#FFF";
export const COLOR_WARNING = "#e5c62b";
export const COLOR_ORG_CHART_DIVISION_BG = "#1D2769";
export const COLOR_ORG_CHART_AGENTS_BG = "#EDEBE9";
export const COLOR_ORG_CHART_CONNECTION_LINE = "#1D2769";
export const COLOR_ORG_CHART_AGENTS_TEXT = "#1D2769";
export const COLOR_LOADING_ICON = "#1D2769";
export const COLOR_SELECT_INPUT_BORDER = "#EDEBE9";
export const COLOR_NOTIFICATIONS_ICON = "#1D2769";
export const COLOR_NOTIFICATIONS_LINK = "#EDEBE9";

// first org chart (to do - delete ?)
export const COLOR_ORG_CHART_DIRECTION_DIVISION_BG = "#1D2769";
export const COLOR_ORG_CHART_SERVICE_DIVISION_BG = "#227c9d";
export const COLOR_ORG_CHART_DEPARTMENT_DIVISION_BG = "#EDEDED";
export const COLOR_ORG_CHART_DIRECTION_DIVISION_TEXT = "#fef9ef";
export const COLOR_ORG_CHART_SERVICE_DIVISION_TEXT = "#fef9ef";
export const COLOR_ORG_CHART_DEPARTMENT_DIVISION_TEXT = "#535C78";
export const COLOR_ORG_CHART_DIVISION_PATH = "#1D2769";
