import { IFormationFormValues } from "../interfaces/formation";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateFormationApi = async ({
  name,
  year,
  agentId,
  comments,
  endValidationDate,
}: IFormationFormValues) => {
  return instanceAxios
    .post("/formations/create-formation", {
      name,
      year,
      agentId,
      comments,
      endValidationDate,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllFormationsApi = async () => {
  return instanceAxios
    .get(`/formations/get-all-formations`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT
export const onGetAllFormationsFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/formations/get-all-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetFormationApi = async (id: number) => {
  return instanceAxios
    .get(`/formations/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteFormationApi = async (id: number) => {
  return instanceAxios
    .delete(`/formations/delete-formation/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateFormationApi = async ({
  id,
  name,
  year,
  agentId,
  comments,
  endValidationDate,
}: IFormationFormValues) => {
  return instanceAxios
    .put("/formations/update-formation/" + id, {
      name,
      year,
      agentId,
      comments,
      endValidationDate,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
