import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_AGENTID,
  EMPTY_INACTIVITY_TITLE,
  EMPTY_INACTIVITY_STARTDATE,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  IAgentInactivityFormValues,
  IAgentsInactivitiesContext,
} from "../../interfaces/agentInactivity";
import {
  onCreateAgentInactivityApi,
  onDeleteAgentInactivityApi,
  onGetAgentInactivityApi,
  onGetAllAgentsInactivitiesApi,
  onGetAllAgentsInactivitiesFromAgentApi,
  onUpdateAgentInactivityApi,
} from "../../api/agentInactivities";

const AgentsInactivitiesContext = createContext(null);

export function AgentsInactivitiesProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateAgentInactivity = useCallback(
    async ({
      title,
      agentId,
      startDate,
      endDate,
    }: IAgentInactivityFormValues) => {
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_AGENTID);
      }
      if (!checkEmptyInput(title)) {
        return Promise.reject(EMPTY_INACTIVITY_TITLE);
      }
      if (!checkEmptyInput(startDate)) {
        return Promise.reject(EMPTY_INACTIVITY_STARTDATE);
      }

      _setIsLoadingAgents(true);
      return onCreateAgentInactivityApi({
        title,
        agentId,
        startDate,
        endDate,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllAgentsInactivities = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllAgentsInactivitiesApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM AGENT
  const onGetAllAgentsInactivitiesFromAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAllAgentsInactivitiesFromAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetAgentInactivity = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAgentInactivityApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateAgentInactivity = useCallback(
    async ({
      id,
      title,
      agentId,
      startDate,
      endDate,
    }: IAgentInactivityFormValues) => {
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_AGENTID);
      }
      if (!checkEmptyInput(title)) {
        return Promise.reject(EMPTY_INACTIVITY_TITLE);
      }
      if (!checkEmptyInput(startDate)) {
        return Promise.reject(EMPTY_INACTIVITY_STARTDATE);
      }

      _setIsLoadingAgents(true);
      return onUpdateAgentInactivityApi({
        id,
        title,
        agentId,
        startDate,
        endDate,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteAgentInactivity = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onDeleteAgentInactivityApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <AgentsInactivitiesContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateAgentInactivity,
        onGetAllAgentsInactivities,
        onGetAgentInactivity,
        onUpdateAgentInactivity,
        onDeleteAgentInactivity,
        onGetAllAgentsInactivitiesFromAgent,
      }}
    />
  );
}

export const useAgentsInactivities = (): IAgentsInactivitiesContext => {
  const context = React.useContext(AgentsInactivitiesContext);
  if (!context)
    throw new Error(
      "useAgentsInactivities must be used in AgentsInactivitiesProvider"
    );

  return context;
};
