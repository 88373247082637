import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import {
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_TABLE_HEADER,
  COLOR_TABLE_ROW_BORDER,
  COLOR_TABLE_TEXT,
  COLOR_TEXT,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import { ITableData } from "../../interfaces/table";
import { MdCloudUpload } from "react-icons/md";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { FiArchive } from "react-icons/fi";
import TableRowActions from "./TableRowActions";
import * as XLSX from "xlsx";

const SELECT_COLUMN_CLASS_NAME = "select";
const ACTIONS_COLUMN_CLASS_NAME = "actions";

const TableComponent = ({
  data,
  onAddBtnClicked,
  onDeleteSelectedRows,
  onRestoreSelectedRows,
  onAnonymiseSelectedRows,
  noDataMessage,
  maxHeight,
  onImportBtnClicked,
  hideHeader,
  rowActions,
  onRowActionSelected,
  onUpdatedSelectedRowsIds,
  onExport,
}: {
  data: ITableData | null;
  onAddBtnClicked?: MouseEventHandler<HTMLButtonElement>;
  onDeleteSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  onRestoreSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  onAnonymiseSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  noDataMessage?: string;
  maxHeight?: string;
  onImportBtnClicked?: MouseEventHandler<HTMLButtonElement>;
  hideHeader?: boolean;
  rowActions?: object;
  onRowActionSelected?: Function;
  onUpdatedSelectedRowsIds?: Function;
  onExport?: Function;
}) => {
  const [columns, _setColumns] = useState<any>(null);
  const [rows, _setRows] = useState<any>(null);
  const [selectColumnIndex, _setSelectColumnIndex] = useState<number | null>(
    null
  );
  const [actionsColumnIndex, _setActionsColumnIndex] = useState<number | null>(
    null
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableScrollPosition, _setTableScrollPosition] = useState<number>(0);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const [tableHeadBottomPosition, _setTableHeadBottomPosition] = useState<
    number | null
  >(null);
  const [selectedRowsIds, _setSelectedRowsIds] = useState<Array<number>>([]);
  const SELECT_COLUMN_KEY = "select";
  const ACTIONS_COLUMN_KEY = "actions";

  // get column index by column key
  const getColumnIndexByColumnKey = ({
    columnKeys,
    columnKey,
  }: {
    columnKeys: Object;
    columnKey: string;
  }) => {
    const keysArray = [];
    let returnIndex = null;

    if (typeof columnKeys === "object") {
      for (const [key] of Object.entries(columnKeys)) {
        keysArray.push(key);
      }
    }

    keysArray.forEach((key, index) => {
      if (key === columnKey) {
        returnIndex = index;
      }
    });

    return returnIndex;
  };

  // set the data
  useEffect(() => {
    if (data) {
      _setColumns(data.columns);
      _setRows(data.rows);

      if (data.rows.length > 0) {
        _setSelectColumnIndex(
          getColumnIndexByColumnKey({
            columnKeys: data.rows[0].tableData,
            columnKey: SELECT_COLUMN_KEY,
          })
        );
        _setActionsColumnIndex(
          getColumnIndexByColumnKey({
            columnKeys: data.rows[0].tableData,
            columnKey: ACTIONS_COLUMN_KEY,
          })
        );
      }
    }
  }, [data]);

  // get and return all column values of the row
  const getColumnKeysFromTheRowObject = (row: Object) => {
    const columnKeys: Array<any> = [];
    if (typeof row === "object") {
      for (const [key] of Object.entries(row)) {
        columnKeys.push(key);
      }
    }
    return columnKeys;
  };

  // get column class name
  const getColumnClassName = (currentColumnIndex: number) => {
    let className = "";

    if (!data) {
      return className;
    }

    if (currentColumnIndex === selectColumnIndex) {
      className = SELECT_COLUMN_CLASS_NAME;
    } else if (currentColumnIndex === actionsColumnIndex) {
      className = ACTIONS_COLUMN_CLASS_NAME;
    }

    return className;
  };

  function textContent(elem: React.ReactElement | string): string {
    if (!elem) {
      return "";
    }
    if (typeof elem === "string") {
      return elem;
    }
    // Debugging for basic content shows that props.children, if any, is either a
    // ReactElement, or a string, or an Array with any combination. Like for
    // `<p>Hello <em>world</em>!</p>`:
    //
    //   $$typeof: Symbol(react.element)
    //   type: "p"
    //   props:
    //     children:
    //       - "Hello "
    //       - $$typeof: Symbol(react.element)
    //         type: "em"
    //         props:
    //           children: "world"
    //       - "!"
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(textContent).join("");
    }
    return textContent(children);
  }

  const onPrepareExport = () => {
    /*let csvRows = [];
    csvRows.push(
      columns.filter((c: string) => c !== "" && c !== "Actions").join(",")
    );
    csvRows = csvRows.concat(
      rows.map((row: any) =>
        getColumnKeysFromTheRowObject(row.tableData)
          .filter((c: string) => c !== "" && c !== "select" && c !== "actions")
          .map((columnKey) => textContent(getColumnValue({ row, columnKey })))
          .join(",")
      )
    );
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "export.csv");
    a.click();*/

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet([columns.filter((c: string) => c !== "" && c !== "Actions"), ...rows.map((row: any) =>
      getColumnKeysFromTheRowObject(row.tableData)
        .filter((c: string) => c !== "" && c !== "select" && c !== "actions")
        .map((columnKey) => textContent(getColumnValue({ row, columnKey })))
    )]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "export.xlsx");

    if (onExport) {
      onExport();
    }
  };

  // UPDATE TABLE SCROLL
  useEffect(() => {
    if (tableContainerRef.current && rows && rows.length > 0) {
      const tableContainer = tableContainerRef.current;

      const updateTableScroll = (event: any) => {
        _setTableScrollPosition(event.target.scrollTop);
      };

      if (tableContainer) {
        tableContainer.addEventListener("scroll", updateTableScroll);

        // Use the same function reference for removeEventListener
        return () => {
          tableContainer.removeEventListener("scroll", updateTableScroll);
        };
      }
    }
  }, [tableContainerRef.current, rows]);

  // SET THE TABLE HEAD
  useEffect(() => {
    if (tableHeadRef.current) {
      _setTableHeadBottomPosition(
        tableHeadRef.current.getBoundingClientRect().bottom
      );
    }
  }, [tableHeadRef.current]);

  // ADD / REMOVE FROM THE SELECTED LIST OF ROWS
  const toggleSelectRow = (selectedRowId: number) => {
    // check if the row is already selected
    if (selectedRowsIds.indexOf(selectedRowId) !== -1) {
      // remove the row's id from the list
      const ids = selectedRowsIds;
      const currentIdIndex = ids.indexOf(selectedRowId);
      ids.splice(currentIdIndex, 1);
      return ids;
    } else {
      // add the row's id to the list
      const ids = selectedRowsIds;
      ids.push(selectedRowId);
      return ids;
    }
  };

  // GET COLUMN VALUE
  const getColumnValue = ({ row, columnKey }: any) => {
    switch (columnKey) {
      case SELECT_COLUMN_KEY:
        return (
          row.tableData.select && (
            <input
              type="checkbox"
              value={row.infos.id}
              checked={selectedRowsIds.indexOf(row.infos.id) !== -1}
              onChange={(e) => {
                const newList = toggleSelectRow(parseInt(e.target.value));
                _setSelectedRowsIds([...newList]);
                if (onUpdatedSelectedRowsIds) {
                  onUpdatedSelectedRowsIds([...newList]);
                }
              }}
            />
          )
        );
      case ACTIONS_COLUMN_KEY:
        return row.tableData.actions && rowActions && onRowActionSelected ? (
          <TableRowActions
            row={row.infos}
            actions={rowActions}
            onActionSelected={(infos: { row: any; action: string }) =>
              onRowActionSelected(infos)
            }
            tableScrollPosition={tableScrollPosition}
            tableHeadBottomPosition={tableHeadBottomPosition}
          />
        ) : (
          row.tableData[columnKey]
        );
      default:
        return row.tableData[columnKey];
    }
  };

  // THE TABLE ------------------------------------------------------------------------------------
  return (
    <Wrapper style={{ maxHeight }}>
      {!hideHeader && (
        <TableHeader>
          <>
            {
              // if the add function click trigger is set, show the button
              onAddBtnClicked && (
                <button
                  className="btn"
                  onClick={onAddBtnClicked}
                  title="Ajouter un Agent"
                >
                  <AiOutlinePlus className="icon" />
                </button>
              )
            }
            {onImportBtnClicked && (
              <button
                className="btn"
                onClick={onImportBtnClicked}
                title="Importer des Agents"
              >
                <MdCloudUpload className="icon" />
              </button>
            )}
            {
              // if the delete function click trigger is set and
              // if there are selected rows
              onDeleteSelectedRows && (
                <button
                  title={"Archiver les Agents sélectionnés"}
                  disabled={
                    selectedRowsIds && selectedRowsIds.length < 1 ? true : false
                  }
                  style={{
                    opacity:
                      selectedRowsIds && selectedRowsIds.length < 1 ? 0.25 : 1,
                    cursor:
                      selectedRowsIds && selectedRowsIds.length < 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="btn"
                  onClick={onDeleteSelectedRows}
                >
                  <FiArchive className="icon" size={16} />
                </button>
              )
            }

            {
              // if the add function click trigger is set, show the button
              onRestoreSelectedRows && (
                <button
                  title="Restaurer les Agents sélectionnés"
                  disabled={
                    selectedRowsIds && selectedRowsIds.length < 1 ? true : false
                  }
                  style={{
                    opacity:
                      selectedRowsIds && selectedRowsIds.length < 1 ? 0.25 : 1,
                    cursor:
                      selectedRowsIds && selectedRowsIds.length < 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="btn"
                  onClick={onRestoreSelectedRows}
                >
                  <RiInboxUnarchiveLine className="icon" />
                </button>
              )
            }
            {
              // if the add function click trigger is set, show the button
              onAnonymiseSelectedRows && (
                <button
                  title="Anonymiser les Agents sélectionnés"
                  disabled={
                    selectedRowsIds && selectedRowsIds.length < 1 ? true : false
                  }
                  style={{
                    opacity:
                      selectedRowsIds && selectedRowsIds.length < 1 ? 0.25 : 1,
                    cursor:
                      selectedRowsIds && selectedRowsIds.length < 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="btn"
                  onClick={onAnonymiseSelectedRows}
                >
                  <AiOutlineDelete className="icon" />
                </button>
              )
            }
            {onExport && (
              <button
                title="Exporter le tableau"
                className="btn"
                onClick={() => onPrepareExport()}
              >
                Exporter
              </button>
            )}
          </>
        </TableHeader>
      )}
      {rows && rows.length > 0 ? (
        <TableContainer ref={tableContainerRef}>
          <Table cellSpacing="0" cellPadding="0">
            <thead ref={tableHeadRef}>
              <tr>
                {columns &&
                  columns.length > 0 &&
                  columns.map((column: any, index: number) => (
                    <th
                      style={{ fontSize: "0.9rem" }}
                      key={"table-column-" + index}
                      className={getColumnClassName(index)}
                    >
                      {column === ("" || " ") ? <>&nbsp;</> : column}{" "}
                      {/* if the column is an empty string, display an html space */}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: any, index: number) => (
                <tr key={"table-row-" + index}>
                  {getColumnKeysFromTheRowObject(row.tableData).map(
                    (columnKey, index) => (
                      <td
                        key={"table-column-value-" + index}
                        className={getColumnClassName(index)}
                      >
                        {getColumnValue({ row, columnKey })}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (
        // if no data -> inform the user
        <div>
          {noDataMessage ? (
            <NoDataInfo>{noDataMessage}</NoDataInfo>
          ) : (
            <NoDataInfo>Aucune donnée disponible</NoDataInfo>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default TableComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .btn {
    background-color: transparent;
    color: ${COLOR_BTN};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.3s ease;

    .icon {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: ${COLOR_BTN_HOVER};
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  color: ${COLOR_TABLE_TEXT};
  border: none;
  overflow: auto;
`;

const Table = styled.table`
  position: relative;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    background-color: ${COLOR_TABLE_HEADER};
    z-index: 1;

    th {
      text-align: start;
      font-weight: 400;
      font-size: 12px;
      padding: 20px 0px;
      color: ${COLOR_TEXT};
      word-wrap: break-word;
      padding: 10px 15px;
      min-width: 120px;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      &.${SELECT_COLUMN_CLASS_NAME} {
        position: sticky;
        left: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }

      &.${ACTIONS_COLUMN_CLASS_NAME} {
        position: sticky;
        right: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }
    }
  }

  tbody {
    // table data

    td {
      text-align: start;
      font-weight: 400;
      font-size: 0.9rem;
      padding: 10px 15px;
      border-bottom: 5px solid ${COLOR_TABLE_ROW_BORDER};
      word-wrap: break-word;
      min-width: 120px;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      &.${SELECT_COLUMN_CLASS_NAME} {
        position: sticky;
        left: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }

      &.${ACTIONS_COLUMN_CLASS_NAME} {
        position: sticky;
        right: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }

      .more-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        color: ${COLOR_TEXT};
      }
    }
  }
`;

const NoDataInfo = styled.div`
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_TEXT};
  font-size: 0.8rem;
  border-radius: 10px;
`;
