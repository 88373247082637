import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_CATEGORY_TYPE,
  EMPTY_CATEGORY_BODY,
  EMPTY_AGENTID,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateTeleworkingApi,
  onDeleteTeleworkingApi,
  onGetAllTeleworkingApi,
  onGetTeleworkingFromAgentApi,
  onUpdateTeleworkingApi,
} from "../../api/teleworking.api";
import {
  ITeleworkingFormValues,
  ITeleworkingContext,
} from "../../interfaces/teleworking";

const TeleworkingContext = createContext(null);

export function TeleworkingProvider(props: any) {
  const [isLoadingTeleworking, _setIsLoadingTeleworking] = useState(false);

  // CREATE
  const onCreateTeleworking = useCallback(
    async ({ agentId, days }: ITeleworkingFormValues) => {
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_AGENTID);
      }

      _setIsLoadingTeleworking(true);
      return onCreateTeleworkingApi({
        agentId,
        days,
      })
        .then((response) => {
          _setIsLoadingTeleworking(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingTeleworking(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllTeleworking = useCallback(() => {
    _setIsLoadingTeleworking(true);
    return onGetAllTeleworkingApi()
      .then((response) => {
        _setIsLoadingTeleworking(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingTeleworking(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE FROM AGENT
  const onGetTeleworkingFromAgent = useCallback((id: number) => {
    _setIsLoadingTeleworking(true);
    return onGetTeleworkingFromAgentApi(id)
      .then((response) => {
        _setIsLoadingTeleworking(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateTeleworking = useCallback(
    async ({ agentId, days }: ITeleworkingFormValues) => {
      _setIsLoadingTeleworking(true);
      return onUpdateTeleworkingApi({
        agentId,
        days,
      })
        .then((response) => {
          _setIsLoadingTeleworking(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingTeleworking(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteTeleworking = useCallback((id: number) => {
    _setIsLoadingTeleworking(true);
    return onDeleteTeleworkingApi(id)
      .then((response) => {
        _setIsLoadingTeleworking(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingTeleworking(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <TeleworkingContext.Provider
      {...props}
      value={{
        isLoadingTeleworking,
        // function
        onCreateTeleworking,
        onGetAllTeleworking,
        onUpdateTeleworking,
        onDeleteTeleworking,
        onGetTeleworkingFromAgent,
      }}
    />
  );
}

export const useTeleworking = (): ITeleworkingContext => {
  const context = React.useContext(TeleworkingContext);
  if (!context)
    throw new Error("useTeleworking must be used in TeleworkingProvider");

  return context;
};
