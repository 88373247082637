import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../../components/table/TableComponent";
import Modal from "../../../../components/Modal";
import AddEditDepartmentForm from "./addEditDepartmentForm";
import { IDivisionInfos } from "../../../../interfaces/division";
import { useDivisions } from "../../../../common/contexts/divisionContext";
import { DIVISION } from "../../../../constants/cts_divisions";
import { IDepartmentFormValues, IDepartmentInfos } from "../../../../interfaces/department";
import { useDepartments } from "../../../../common/contexts/departmentContext";
import ChangeParentDivisionForm from "./changeParentDivisionForm";
import styled from "styled-components";
import { COLOR_LOADING_ICON, COLOR_TITLE } from "../../../../constants/cts_colors";
import { ITableData } from "../../../../interfaces/table";
import ToastAlert from "../../../../components/ToastAlert";
import { RiLoader2Line } from "react-icons/ri";

const IndexDepartmentsModal = ({onClose, service}: {onClose: Function, service: IDivisionInfos}) => {
  // TABLE
  const [ tableData, _setTableData ] = useState<ITableData | null>(null);
  const ROW_ACTIONS = { edit: 'Modifier', /* changeParentDivision: 'Changer de branche' */ }
  // MODAL
  // const [ isModalOpenAddDepartment, _setIsModalOpenAddDepartment ] = useState<boolean>(false);
  const [ isModalOpenChangeParentDivision, _setIsModalOpenChangeParentDivision ] = useState<boolean>(false);
  const [ isModalOpenEditDepartment, _setIsModalOpenEditDepartment ] = useState<boolean>(false);
  // SERVICES
  const [ services, _setServices ] = useState<IDivisionInfos[]>([]);
  const [ currentService, _setCurrentService ] = useState<IDivisionInfos| null>(null);
  // DIVISION
  const { onGetAllDivisionsByType } = useDivisions();
  // DEPARTMENT
  const { onGetAllDepartmentsByService, isLoadingDepartments } = useDepartments();
  // EDIT DATA
  const [ editFormData, _setEditFormData ] = useState<IDepartmentFormValues | null>(null);
  // CHANGE PARENT DIVISION DATA
  const [ changeParentDivisionFormData, _setChangeParentDivisionFormData ] = useState<IDepartmentFormValues | null>(null);
  // toast
  const [toastMessage, _setToastMessage] = useState<string>('');

  // LOAD DATA
  const loadDepartments = useCallback(() => {
    if (service.type !== DIVISION.direction.type) {
      onGetAllDepartmentsByService({serviceId: service.id})
        .then((departments) => {
          loadTableData(departments)
        })
        .catch((error) => console.error(error))
    }
  }, [onGetAllDepartmentsByService])

  // GET AND SET ALL THE DEPARTMENTS ON FIRST RENDER
  useEffect(() => {
    loadDepartments();
  }, [loadDepartments])

  // GET THE SERVICES
  useEffect(() => {
    onGetAllDivisionsByType(DIVISION.service.type)
      .then((response) => {
        if (response && response.length > 0) {
          _setServices(response)
          _setCurrentService(response.filter((item) => item.id === service.id)[0])
        }
      })
      .catch((error) => console.error(error))
  }, [])

  // HANDLE WHEN ACTION SELECTED FOR THE ROW
  const handleRowActionSelected = ({row, action}: {row: IDepartmentInfos, action: string}) => {
    switch(action) {
      case ROW_ACTIONS.edit:
        editDepartment(row);
        break;
      // case ROW_ACTIONS.changeParentDivision:
      //   changeParentDivision(row);
      //   break;
    }
  }

  // ON EDIT DEPARTMENT
  const editDepartment = (department: IDepartmentInfos) => {
    _setIsModalOpenEditDepartment(true);
    _setEditFormData({...department});
  }

  // ON CHANGE PARENTDIVISION
  // const changeParentDivision = (department: IDepartmentInfos) => {
  //   _setIsModalOpenChangeParentDivision(true);
  //   _setChangeParentDivisionFormData({
  //     ...department,
  //     parentServiceId: department.parentService.id,
  //     parentDivisionId: department.parentDivision.id === department.parentService.id ? department.parentService.id  : department.parentDivision.id,
  //   });
  // }

  // LOAD TABLE'S DATA
  const loadTableData = (departments: IDepartmentInfos[]) => {
    const rows: any = [];
    const columns: string[] = [
      "Nom",
      "Type",
      "Actions",
    ]

    // create the rows
    departments.forEach((department) => {
      rows.push({
        infos: {
          ...department,
        },
        tableData: {
          name: department.name,
          type: department.type ? department.type.toUpperCase() : 'Pôle/Équipe/Cellule',
          actions: true,
        },
      })
    })

    // set the data with the columns and rows
    _setTableData({
      columns,
      rows,
    });
  }

  return (
    <Modal
        onClose={onClose}
      >
        <Title>{currentService?.name}</Title>
        <MainContainer>
          {
            isLoadingDepartments ? (
              <LoadingDiv>
                <RiLoader2Line
                  className="loading-icon"
                />
              </LoadingDiv> 
            ) : (
              <TableComponent
                data={tableData}
                // onAddBtnClicked={() => _setIsModalOpenAddDepartment(true)}
                maxHeight='calc(100vh - 100px)'
                rowActions={ROW_ACTIONS}
                onRowActionSelected={(infos: {row: IDepartmentInfos, action: string}) => handleRowActionSelected(infos)}
              />
            )
          }
        </MainContainer>
      {/* add modal */}
      {/* {
        isModalOpenAddDepartment &&
        <Modal
          onClose={() => _setIsModalOpenAddDepartment(false)}
          >
          <AddEditDepartmentForm
            title="Ajouter un pôle"
            actionString="Ajouter"
            currentService={currentService}
            onDepartmentAdded={() => {
              _setIsModalOpenAddDepartment(false)
              loadDepartments()
              _setToastMessage('Le pôle a été ajouté')
            }}
          />
        </Modal>
      } */}
      {/* edit modal */}
      {
        isModalOpenEditDepartment && editFormData &&
        <Modal
          onClose={() => _setIsModalOpenEditDepartment(false)}
          >
          <AddEditDepartmentForm
            title="Modifier le pôle"
            formData={editFormData}
            currentService={currentService}
            actionString="Modifier"
            onDepartmentUpdated={() => {
              _setIsModalOpenEditDepartment(false)
              loadDepartments()
              _setToastMessage('Le pôle a été modifié')
            }}
          />
        </Modal>
      }
      {/* change parent modal */}
      {
        isModalOpenChangeParentDivision && changeParentDivisionFormData &&
        <Modal
          onClose={() => _setIsModalOpenChangeParentDivision(false)}
        >
          <ChangeParentDivisionForm
            title="Changer la branche"
            formData={changeParentDivisionFormData}
            services={services}
            currentService={currentService}
            actionString="Changer"
            onSubmit={() => {
              _setIsModalOpenChangeParentDivision(false)
              loadDepartments()
              _setToastMessage('La branche a été modifiée')
            }}
          />
        </Modal>
      }
      {/* TOAST */}
      {toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastMessage('');
          }}
          endedTimer={() => {
            _setToastMessage('');
          }}
        />
      )}
    </Modal>
  )
}

export default IndexDepartmentsModal;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Title = styled.h3`
  text-align: center;
  padding: 10px 5px;
  color: ${COLOR_TITLE};
  word-wrap: break-word;
`

const MainContainer = styled.div`
  position: relative;
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
`

const LoadingDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255, 255, 255, 0.3);

  .loading-icon {

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }

    width: 30px;
    color: ${COLOR_LOADING_ICON};
    opacity: 0.75;
    animation: 2s rotation infinite linear;
  }
`