// the values are used for translation
// no match
export const PASSWORD_NO_MATCH = "Les mots de passe ne sont pas les mêmes";
// empty
export const EMPTY_EMAIL = "Veuillez insérer un e-mail";
export const EMPTY_PASSWORD = "Veuillez insérer un mot de passe";
export const EMPTY_FIRSTNAME = "Veuillez insérer le prénom";
export const EMPTY_LASTNAME = "Veuillez insérer le nom";
export const EMPTY_AGE = "Veuillez entrer l'âge";
export const EMPTY_ROLE = "Veuillez insérer un rôle";
export const EMPTY_TOKEN = "Veuillez insérer le token";
export const EMPTY_CONTACT_MESSAGE = "Veuillez insérer un message";
export const EMPTY_CONTACT_STATUS = "emptyContactStatus";
export const EMPTY_ITEM_TITLE = "emptyItemTitle";
export const EMPTY_ITEM_DESCRIPTION = "emptyItemDescription";
export const EMPTY_ITEM_IMAGE = "emptyItemImage";
export const EMPTY_ITEM_PRICE = "emptyItemPrice";
export const EMPTY_ITEM_STATUS = "emptyItemStatus";
export const EMPTY_FIRST_NAME = "Veuillez insérer le prénom";
export const EMPTY_LAST_NAME = "Veuillez insérer le nom";
export const EMPTY_SEX = "Veuillez insérer le genre";
export const EMPTY_BIRTH_DATE = "Veuillez insérer la date de naissance";
export const EMPTY_REGISTRATION_NUMBER = "Veuillez insérer le matricule";
export const EMPTY_MARITAL_STATUS = "Veuillez insérer le statut matrimonial";
export const EMPTY_SERVICE = "Veuillez insérer un service";
export const EMPTY_PROFESSIONAL_PHONE_NUMBER =
  "Veuillez insérer un téléphone professionnel";
export const EMPTY_NAME = "Veuillez insérer le nom";
// EMPTY - DIVISION
export const EMPTY_PARENT_DIVISION =
  "Pas trouvé à qui appartient cette division";
// EMPTY - JOB
export const EMPTY_JOB_NAME = "Veuillez insérer le nom";
export const EMPTY_JOB_LEVEL = "Veuillez insérer le niveau";
export const EMPTY_JOB_TYPE = "Veuillez insérer le type";
export const EMPTY_JOB_START_DATE = "Veuillez insérer la date de début";
export const EMPTY_JOB_END_DATE = "Veuillez insérer la date de fin";
export const EMPTY_JOB_AGENT = "Veuillez insérer un agent";
// EMPTY - CERTIFICATE
export const EMPTY_CERTIFICATE_NAME = "Veuillez insérer le nom";
export const EMPTY_CERTIFICATE_VALIDATION_DATE =
  "Veuillez insérer la date de validation";
export const EMPTY_CERTIFICATE_EXPIRATION_DATE =
  "Veuillez insérer la date d'expiration";
export const EMPTY_CERTIFICATE_AGENT = "Veuillez insérer un agent";
// EMPTY - SERVICE
export const EMPTY_SERVICE_NAME = "Veuillez insérer le nom";

// EMPTY - AGENTS FILES
export const EMPTY_AGENTID = "Veuillez insérer un agent";
export const EMPTY_FILEPATH = "Veuillez insérer un fichier";

// EMPTY - AGENTS INACTIVITIES
export const EMPTY_INACTIVITY_TITLE = "Veuillez insérer un titre";
export const EMPTY_INACTIVITY_STARTDATE = "Veuillez insérer une date de départ";

// EMPTY - AGENTS STATUS
export const EMPTY_STATUS_TYPE = "Veuillez insérer un type";
export const EMPTY_STATUS_STARTDATE = "Veuillez insérer une date de départ";

// EMPTY - CATEGORIES
export const EMPTY_CATEGORY_TYPE = "Veuillez insérer un type";
export const EMPTY_CATEGORY_BODY = "Veuillez insérer un corps";

// EMPTY EMPLOYMENTS
export const EMPTY_EMPLOYMENT_NAME = "Veuillez insérer un nom";
export const EMPTY_EMPLOYMENT_CODE = "Veuillez insérer un code";
export const EMPTY_EMPLOYMENT_FAMILY = "Veuillez insérer une famille";
export const EMPTY_EMPLOYMENT_SUBFAMILY = "Veuillez insérer une sous famille";
export const EMPTY_EMPLOYMENT_AGENTID = "Veuillez insérer un agent";

// EMPTY FORMATIONS
export const EMPTY_FORMATION_NAME = "Veuillez insérer un nom";
export const EMPTY_FORMATION_YEAR = "Veuillez insérer une année";
export const EMPTY_FORMATION_AGENTID = "Veuillez insérer un agent";

// EMPTY DEGREE
export const EMPTY_DEGREE_NAME = "Veuillez insérer un nom";
export const EMPTY_DEGREE_YEAR = "Veuillez insérer une année";
export const EMPTY_DEGREE_GRADE = "Veuillez insérer un grade";
export const EMPTY_DEGREE_AGENTID = "Veuillez insérer un agent";

// EMPTY BONIFIED VACATIONS
export const EMPTY_BONIFIEDVACATION_SIGLE = "Veuillez insérer un sigle";
export const EMPTY_BONIFIEDVACATION_YEAR = "Veuillez insérer une année";

// EMPTY RESPONSIBLE
export const EMPTY_RESPONSIBLE_AGENTID = "Veuillez indiquer qui a le rôle";
export const EMPTY_RESPONSIBLE_DIVISIONID = "Veuillez indiquer un service";
export const EMPTY_RESPONSIBLE_TYPE = "Veuillez indiquer un rôle";

// error
// ERROR - JOB
export const ERROR_JOB_END_DATE_IS_EARLIER_THAN_START_DATE =
  "Attention, la date de début doit être inférieure à la date de fin";
// ERROR - CERTIFICATE
export const ERROR_CERTIFICATE_VALIDATION_DATE_IS_EARLIER_THAN_EXPIRATION_DATE =
  "Attention, la date de validation doit être inférieure à la date d'expiration";

// not valid
export const NOT_VALID_EMAIL = "L'email n'est pas valide";
export const NOT_VALID_PASSWORD =
  "Le mot de passe n'est pas valide, veuillez enregistrer au moins 6 caractères";
export const NOT_VALID_AGE = "L'âge n'est pas valide";
