import React from "react";
import styled from "styled-components";
import { COLOR_TEXT_MUTED } from "../../constants/cts_colors";

const FormInfoMessage = ({ text }: { text: string }) => {
  return <Text>{text}</Text>;
};

export default FormInfoMessage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Text = styled.span`
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: 0.9rem;
  color: ${COLOR_TEXT_MUTED};

  @media (max-width: 800px) {
    font-size: 2.2vw;
  }
`;
