import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import { IReferensJobFormValues } from "../../../interfaces/referensJob";
import { useReferensJobs } from "../../../common/contexts/referensJobContext";
import { MdOutlineDescription } from "react-icons/md";

const EditReferensJobForm = ({
  title,
  actionString,
  onReferensJobUpdated,
  idReferensJob,
}: {
  title: string;
  actionString: string;
  onReferensJobUpdated: Function;
  idReferensJob: number;
}) => {
  const initialFormState = {
    id: idReferensJob,
    branchCode: null,
    branch: null,
    professionalFamily: null,
    reme: null,
    idStatutoryCorrespondence: null,
    codeStatutoryCorrespondence: null,
    statutoryCorrespondence: null,
    desiredTraining: null,
    jobs: null,
    mission: null,
    fapReme: null,
    mainActivities: null,
    specialExerciseConditions: null,
    knowledge: null,
    operationalSkills: null,
    behavioralSkills: null,
    statutoryDiplomaRequired: null,
    mediumtermDevelopmentFactors: null,
    impactOnJob: null,
    codeJob: null,
    jobTitle: null,
    previousCodeJob: null,
    previousJobTitle: null,
    tricarto: null,
    businessFamilyCode: null,
    desiredExperience: null,
    trifap: null,
    categoryReferens: null,
    referensSituationType: null,
    referensSituation: null,
    isExternalCompetition: null,
    externalCompetitionProgram: null,
    externalCompetitionProgramNormal: null,
    externalCompetitionProgramSuperior: null,
    pdfFile: null,
    referensKnowledgeDefinition: null,
    referensOperationalSkillsDefinition: null,
    referensBehavioralSkillsDefinition: null,
    idSkills: null,
    bap: null,
    fap: null,
    description: null,
  };

  const [formValues, _setFormValues] =
    useState<IReferensJobFormValues>(initialFormState);
  // const [errorMessage, _setErrorMessage] = useState null>(null);

  const { onGetReferensJob, onUpdateReferensJob } = useReferensJobs();
  const [actualReferensJob, _setActualReferensJob] =
    useState<IReferensJobFormValues>();

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (idReferensJob) {
      onGetReferensJob(idReferensJob).then(
        (response: IReferensJobFormValues) => {
          console.log(response);
          _setActualReferensJob(response);
          _setFormValues(response);
        }
      );
    }
  }, []);

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formValues) {
      console.log(formValues);
      onUpdateReferensJob(formValues)
        .then((response) => {
          console.log(response);
          onReferensJobUpdated();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // on change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <div>
                <FormLabel htmlFor="description" text="Description" />
                <FormInputContainer
                  icon={<MdOutlineDescription />}
                  input={
                    <input
                      type="text"
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={handleChange}
                      value={
                        formValues.description ? formValues.description : ""
                      }
                    />
                  }
                />
              </div>

              {/* form errors */}
              {/* {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )} */}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button text={actionString} onClick={handleSubmit} />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  );
};

export default EditReferensJobForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
