import React, { useState, useCallback, createContext } from "react";
import { EMPTY_STATUS_TYPE } from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateAgentStatusApi,
  onDeleteAgentStatusApi,
  onGetAgentStatusApi,
  onGetAgentStatusFromAgentApi,
  onGetAllAgentsStatusApi,
  onUpdateAgentStatusApi,
} from "../../api/agentStatus.api";
import {
  IAgentStatusFormValues,
  IAgentsStatusContext,
} from "../../interfaces/agentStatus";

const AgentsStatusContext = createContext(null);

export function AgentsStatusProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateAgentStatus = useCallback(
    async ({
      type,
      startDate,
      endDate,
      subtype,
      agentId,
    }: IAgentStatusFormValues) => {
      if (!checkEmptyInput(type)) {
        return Promise.reject(EMPTY_STATUS_TYPE);
      }
      // if (!checkEmptyInput(startDate)) {
      //   return Promise.reject(EMPTY_STATUS_STARTDATE);
      // }
      // if (!checkEmptyInput(subtype)) {
      //   return Promise.reject(EMPTY_STATUS_SUBTYPE);
      // }

      _setIsLoadingAgents(true);
      return onCreateAgentStatusApi({
        type,
        startDate,
        endDate,
        subtype,
        agentId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllAgentsStatus = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllAgentsStatusApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetAgentStatus = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAgentStatusApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE FROM AGENT
  const onGetAgentStatusFromAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAgentStatusFromAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateAgentStatus = useCallback(
    async ({
      id,
      type,
      startDate,
      endDate,
      subtype,
      agentId,
    }: IAgentStatusFormValues) => {
      if (!checkEmptyInput(type)) {
        return Promise.reject(EMPTY_STATUS_TYPE);
      }
      // if (!checkEmptyInput(startDate)) {
      //   return Promise.reject(EMPTY_STATUS_STARTDATE);
      // }
      // if (!checkEmptyInput(subtype)) {
      //   return Promise.reject(EMPTY_STATUS_SUBTYPE);
      // }

      _setIsLoadingAgents(true);
      return onUpdateAgentStatusApi({
        id,
        type,
        startDate,
        endDate,
        subtype,
        agentId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteAgentStatus = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onDeleteAgentStatusApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <AgentsStatusContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateAgentStatus,
        onGetAllAgentsStatus,
        onGetAgentStatus,
        onUpdateAgentStatus,
        onDeleteAgentStatus,
        onGetAgentStatusFromAgent,
      }}
    />
  );
}

export const useAgentsStatus = (): IAgentsStatusContext => {
  const context = React.useContext(AgentsStatusContext);
  if (!context)
    throw new Error("useAgentsStatus must be used in AgentsStatusProvider");

  return context;
};
