import styled from "styled-components";
import { RiLoader2Line } from "react-icons/ri";
import { COLOR_TITLE } from "../constants/cts_colors";

const LoadingContainer = () => {
  return (
    <LoadingDiv>
      <RiLoader2Line
        className="rotate"
        size={52}
        color={COLOR_TITLE}
        opacity={1}
      />
    </LoadingDiv>
  );
};

export default LoadingContainer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const LoadingDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  .rotate {
    animation: rotation 2s infinite linear;
  }
`;
