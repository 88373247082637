import React, { useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import ServicesTable from "./components/servicesTable";
import IndexDepartmentsModal from "./components/departments/indexDepartmentsModal";
import { IDivisionInfos } from "../../interfaces/division";
import styled from "styled-components";

const Services = () => {
  const [showDepartmentsForServiceId, _setShowDepartmentsForServiceId] =
    useState<IDivisionInfos | null>(null);

  return (
    <BodyContainer title="Services DLM">
      {/* SERVICES - table */}
      <ContentContainer>
        <ServicesTable
          onClickOpenServiceDepartmentsModal={(service: IDivisionInfos) =>
            _setShowDepartmentsForServiceId(service)
          }
        />
      </ContentContainer>
      {showDepartmentsForServiceId && (
        <IndexDepartmentsModal
          onClose={() => _setShowDepartmentsForServiceId(null)}
          service={showDepartmentsForServiceId}
        />
      )}
    </BodyContainer>
  );
};

export default Services;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ContentContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex: 1;
`
