import { COLOR_ORG_CHART_AGENTS_BG, COLOR_ORG_CHART_AGENTS_TEXT, COLOR_ORG_CHART_CONNECTION_LINE, COLOR_ORG_CHART_DIVISION_BG, COLOR_WHITE } from "../../constants/cts_colors"
import { IAgentInfos } from "../../interfaces/agent"
import { IDivisionBranch, IDivisionInfos } from "../../interfaces/division"
import { IOrgChartFilterOptions } from "../../interfaces/orgChart"
import { IResponsibleInfos } from "../../interfaces/responsible"
import { convertDateToString } from "../date"
import { getAgentsContainer, getDivisionContainer, getResponsiblesContainer, setNodesPositions } from "./tools"

// DRAW NODE CONNECTION ---------------------------------------------------------------------------------------------------------------------------------
const drawNodeConnection = ({
  ctx,
  parentMiddleBottomX,
  parentMiddleBottomY,
  childMiddleTopX,
  childY,
  startX,
  endX,
  endY,
  verticalSpacing,
}: {
  ctx: CanvasRenderingContext2D,
  parentMiddleBottomX: number,
  parentMiddleBottomY: number,
  childMiddleTopX: number,
  childY: number,
  startX: number,
  endX: number,
  endY: number,
  verticalSpacing: number,
}) => {
  ctx.beginPath();

  // Draw vertical middle bottom line from the parent
  ctx.moveTo(parentMiddleBottomX, parentMiddleBottomY);
  ctx.lineTo(parentMiddleBottomX, childY - (verticalSpacing / 2));
  ctx.strokeStyle = COLOR_ORG_CHART_CONNECTION_LINE;

  // Draw horizontal line
  ctx.moveTo(startX, endY);
  ctx.lineTo(endX, endY);
  ctx.strokeStyle = COLOR_ORG_CHART_CONNECTION_LINE;

  // Draw vertical line from the child
  ctx.moveTo(childMiddleTopX, childY);
  ctx.lineTo(childMiddleTopX, endY);
  ctx.lineWidth = 4;
  ctx.strokeStyle = COLOR_ORG_CHART_CONNECTION_LINE;
  ctx.stroke();
};

// DRAW DIVISION ----------------------------------------------------------------------------------------------------------------------------------------
const drawDivision = ({
  division,
  ctx,
  x,
  y,
  nodeWidth,
  verticalPadding,
  horizontalPadding,
}: {
  division: IDivisionInfos,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  nodeWidth: number,
  verticalPadding: number,
  horizontalPadding: number,
}) => {
  const divisionHeight = getDivisionContainer().wholeHeight
  ctx.beginPath();
  ctx.rect(x, y, nodeWidth, divisionHeight);
  ctx.fillStyle = COLOR_ORG_CHART_DIVISION_BG;
  ctx.fill();
  ctx.fillStyle = COLOR_WHITE;
  ctx.font = 'bold 14px Arial';
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  if (division.name) {
    // wrap text
    (() => {
      let textLine = '';
      const lineHeight = 20
      const text = division.name
      let words: string[] = []
      const textX = x + nodeWidth / 2
      let textY = y + lineHeight + verticalPadding

      if (text) {
        words = text.split(' ');
      }

      for(let i = 0; i < words.length; i++) {
        let testLine = textLine + words[i] + ' ';
        let metrics = ctx.measureText(testLine);
        let testWidth = metrics.width;

        if (testWidth > nodeWidth - horizontalPadding && i > 0) {
          ctx.fillText(textLine, textX, textY);
          textLine = words[i] + ' ';
          textY += lineHeight;
        }
        else {
          textLine = testLine;
        }
      }
      ctx.fillText(textLine, textX, textY);
    })()
  }

  // return the division infos
  return {
    height: divisionHeight,
  }
}

// DRAW RESPONSIBLES ------------------------------------------------------------------------------------------------------------------------------------
const drawResponsibles = ({
  ctx,
  responsibles,
  x,
  y,
  nodeWidth,
  horizontalPadding,
  verticalSpacing,
  filters,
}: {
  ctx: CanvasRenderingContext2D,
  responsibles: IResponsibleInfos[],
  x: number,
  y: number,
  nodeWidth: number,
  horizontalPadding: number,
  verticalSpacing: number,
  filters: IOrgChartFilterOptions,
}) => {
  let wholeHeight = 0
  const responsiblesContainer = getResponsiblesContainer({
    ctx,
    responsibles,
    x,
    y,
    nodeWidth,
    horizontalPadding,
    verticalSpacing,
    filters,
  })
  if (responsiblesContainer) {
    wholeHeight = responsiblesContainer.wholeHeight
    const containerHeight = responsiblesContainer.containerHeight
    const containerStartX = responsiblesContainer.containerStartX
    const containerStartY = responsiblesContainer.containerStartY
    const connectionHeight = responsiblesContainer.connectionHeight
    const textMetrics = responsiblesContainer.textMetrics
    const lineGap = responsiblesContainer.lineGap
    const lines = responsiblesContainer.lines
    const fontSizeAndName = responsiblesContainer.fontSizeAndName
  
    // add the connection
    ctx.strokeStyle = COLOR_ORG_CHART_CONNECTION_LINE;
    ctx.moveTo(containerStartX + nodeWidth / 2, containerStartY);
    ctx.lineTo(containerStartX + nodeWidth / 2, containerStartY - connectionHeight);
    ctx.stroke();
  
    // Draw the rectangle
    ctx.fillStyle = COLOR_ORG_CHART_AGENTS_BG;
    ctx.fillRect(containerStartX, containerStartY, nodeWidth, containerHeight);
  
    // for each line
    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      const line = lines[lineIndex]
      const lineY = horizontalPadding + lineIndex * (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent + lineGap);
      const lineTextWidth = ctx.measureText(line.text).width
      let lineWidth = 0
  
      for (let wordIndex = 0; wordIndex < line.words.length; wordIndex++) {
        const word = line.words[wordIndex]
  
        // Draw the word
        ctx.fillStyle = COLOR_ORG_CHART_AGENTS_TEXT;
        ctx.textAlign = "center";
        ctx.font = "normal " + fontSizeAndName;
  
        if (word.isBold) {
          ctx.font = "bold " + fontSizeAndName;
        }
  
        // Measure the width of the word
        const wordWidth = ctx.measureText(word.string).width
        const wordX = containerStartX + lineWidth + wordWidth / 2 + nodeWidth / 2 - lineTextWidth / 2
        const wordY = containerStartY + lineY + (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) / 2
  
        // add the space width for all the words except the last one
        if (wordIndex !== line.words.length - 1) {
          lineWidth += (wordWidth + ctx.measureText(' ').width)
        } else {
          lineWidth += wordWidth
        }
  
        ctx.fillText(word.string, wordX, wordY);
      }
    }
  }

  // return the division infos
  return {
    height: wholeHeight,
  }
}

// DRAW AGENTS ------------------------------------------------------------------------------------------------------------------------------------------
const drawAgents = ({
  ctx,
  agents,
  x,
  y,
  nodeWidth,
  horizontalPadding,
  verticalSpacing,
  filters,
}: {
  ctx: CanvasRenderingContext2D,
  agents: IAgentInfos[],
  x: number,
  y: number,
  nodeWidth: number,
  horizontalPadding: number,
  verticalSpacing: number,
  filters: IOrgChartFilterOptions,
}) => {
  let wholeHeight = 0
  const agentsContainer = getAgentsContainer({
    ctx,
    agents,
    x,
    y,
    nodeWidth,
    horizontalPadding,
    verticalSpacing,
    filters,
  })
  if (agentsContainer) {
    wholeHeight = agentsContainer.wholeHeight
    const containerHeight = agentsContainer.containerHeight
    const containerStartX = agentsContainer.containerStartX
    const containerStartY = agentsContainer.containerStartY
    const connectionHeight = agentsContainer.connectionHeight
    const textMetrics = agentsContainer.textMetrics
    const lineGap = agentsContainer.lineGap
    const lines = agentsContainer.lines
    const fontSizeAndName = agentsContainer.fontSizeAndName

    // add the connection
    ctx.strokeStyle = COLOR_ORG_CHART_CONNECTION_LINE;
    ctx.moveTo(containerStartX + nodeWidth / 2, containerStartY);
    ctx.lineTo(containerStartX + nodeWidth / 2, containerStartY - connectionHeight);
    ctx.stroke();

    // Draw the rectangle
    ctx.fillStyle = COLOR_ORG_CHART_AGENTS_BG;
    ctx.fillRect(containerStartX, containerStartY, nodeWidth, containerHeight);

    // for each line
    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      const line = lines[lineIndex]
      const lineY = horizontalPadding + lineIndex * (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent + lineGap);
      const lineTextWidth = ctx.measureText(line.text).width
      let lineWidth = 0

      for (let wordIndex = 0; wordIndex < line.words.length; wordIndex++) {
        const word = line.words[wordIndex]

        // Draw the word
        ctx.fillStyle = COLOR_ORG_CHART_AGENTS_TEXT;
        ctx.textAlign = "center";
        ctx.font = "normal " + fontSizeAndName;

        if (word.isBold) {
          ctx.font = "bold " + fontSizeAndName;
        }

        // Measure the width of the word
        const wordWidth = ctx.measureText(word.string).width
        const wordX = containerStartX + lineWidth + wordWidth / 2 + nodeWidth / 2 - lineTextWidth / 2
        const wordY = containerStartY + lineY + (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) / 2

        // add the space width for all the words except the last one
        if (wordIndex !== line.words.length - 1) {
          lineWidth += (wordWidth + ctx.measureText(' ').width)
        } else {
          lineWidth += wordWidth
        }

        ctx.fillText(word.string, wordX, wordY);
      }
    }
  }

  // return the division infos
  return {
    height: wholeHeight,
  }
}

// NEW DRAW NODE ----------------------------------------------------------------------------------------------------------------------------------------
const drawNode = ({
  node,
  ctx,
  horizontalSpacing,
  verticalSpacing,
  siblingSpacing,
  filters,
}: {
  node: IDivisionBranch,
  ctx: CanvasRenderingContext2D,
  horizontalSpacing: number,
  verticalSpacing: number,
  siblingSpacing: number,
  filters: IOrgChartFilterOptions,
}) => {
  if (node.orgChartPosition.x !== null && node.orgChartPosition.y !== null) {
    const nodeWidth = 200;
    const verticalPadding = 10;
    const horizontalPadding = 10;
  
    // draw division
    const drawnDivision = drawDivision({
      division: node.division,
      ctx,
      x: node.orgChartPosition.x,
      y: node.orgChartPosition.y,
      nodeWidth,
      verticalPadding,
      horizontalPadding,
    })
  
    let nodeHeight = drawnDivision.height
  
    // add responsibles
    const responsiblesStartY = node.orgChartPosition.y + nodeHeight
    const drawnResponsibles = drawResponsibles({
      responsibles: node.responsibles,
      ctx,
      x: node.orgChartPosition.x,
      y: responsiblesStartY,
      nodeWidth,
      horizontalPadding,
      verticalSpacing,
      filters,
    })
    nodeHeight += drawnResponsibles.height
  
  
    // add agents
    const agentsStartY = node.orgChartPosition.y + nodeHeight
    const drawnAgents = drawAgents({
      agents: node.agents,
      ctx,
      x: node.orgChartPosition.x,
      y: agentsStartY,
      nodeWidth,
      horizontalPadding,
      verticalSpacing,
      filters,
    })
    nodeHeight += drawnAgents.height
  
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        if (node.orgChartPosition.x !== null && node.orgChartPosition.y !== null) {
          const parentMiddleBottomX = node.orgChartPosition.x + nodeWidth / 2;
          const parentMiddleBottomY = node.orgChartPosition.y + nodeHeight;
          
          if (child.orgChartPosition.x !== null && child.orgChartPosition.y !== null) {
            // Draw connections to children
            drawNodeConnection({
              ctx,
              parentMiddleBottomX,
              parentMiddleBottomY,
              childMiddleTopX: child.orgChartPosition.x + nodeWidth / 2,
              childY: child.orgChartPosition.y,
              startX: parentMiddleBottomX,
              endX: child.orgChartPosition.x + nodeWidth / 2,
              endY: child.orgChartPosition.y - verticalSpacing / 2,
              verticalSpacing,
            });
            // draw children
            drawNode({
              node: child,
              ctx,
              horizontalSpacing,
              verticalSpacing,
              siblingSpacing,
              filters,
            });
          }
        }
      })
    }
  }
}

// DRAW BRANCH ------------------------------------------------------------------------------------------------------------------------------------------
export const drawBranch = ({
  branch,
  ctx,
  rootX,
  rootY,
  filters,
  nodeWidth,
  verticalSpacing,
  horizontalSpacing,
  siblingSpacing,
}: {
  canvas: HTMLCanvasElement,
  branch: IDivisionBranch,
  ctx: CanvasRenderingContext2D,
  rootX: number,
  rootY: number,
  filters: IOrgChartFilterOptions,
  nodeWidth: number,
  verticalSpacing: number,
  horizontalSpacing: number,
  siblingSpacing: number,
}) => {

  setNodesPositions({
    branch,
    rootX,
    rootY,
    xFromStartDrawing: rootX,
    yFromStartDrawing: rootY,
    siblingSpacing,
    nodeWidth,
    verticalSpacing,
    filters,
    ctx,
  })

  // draw the branch
  drawNode({
    node: branch,
    ctx,
    horizontalSpacing,
    verticalSpacing,
    siblingSpacing,
    filters,
  })
};