import { useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import TableComponent from "../../components/table/TableComponent";
import Modal from "../../components/Modal";
import { useAgents } from "../../common/contexts/agentContext";
import styled from "styled-components";
import { COLOR_TEXT } from "../../constants/cts_colors";
import { IAgentInfos } from "../../interfaces/agent";
import {
  changeDateFormatDDMMYYYY,
  convertDateToString,
} from "../../utils/date";
import Button from "../../components/Button";
import { ITableData } from "../../interfaces/table";
import ToastAlert from "../../components/ToastAlert";

const ArchivedAgents = () => {
  const {
    onGetAllArchivedAgents,
    onDeleteAllFromIdList,
    onRestoreAgent,
    onAnonymAgent,
  } = useAgents();

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  // DATA - VARIABLES
  const [data, _setData] = useState<ITableData | null>(null);
  const [triggerLoadDataFromDB, _setTriggerLoadDataFromDB] =
    useState<boolean>(false);

  const ROW_ACTIONS = {
    restore: "Restaurer",
    anonym: "Anonymiser",
  };
  // SELECT TABLE ROW - VARIABLES
  const [selectedRowsIds, _setSelectedRowsIds] = useState<Array<number>>([]);

  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  // set title of the page
  useEffect(() => {
    document.title = "Agents Archivés | Sorbonne";
  }, []);

  // get and set the table data
  useEffect(() => {
    onGetAllArchivedAgents()
      .then((returnData) => {
        if (returnData) {
          loadTableData(returnData);
        }
      })
      .catch((err) => console.error(err));
  }, [triggerLoadDataFromDB]);

  // on row action btn pressed
  const handleRowActionSelected = ({
    row,
    action,
  }: {
    row: IAgentInfos;
    action: string;
  }) => {
    switch (action) {
      case ROW_ACTIONS.restore:
        _setToastMessage(
          "Agent" +
            (selectedRowsIds.length > 1 ? "s" : "") +
            " restauré" +
            (selectedRowsIds.length > 1 ? "s" : "") +
            " avec succès !"
        );
        _setConfirmModal({
          message: "Voulez-vous restaurer cet agent ?",
          confirmedAction: () => restoreAgent(row),
        });
        break;
      case ROW_ACTIONS.anonym:
        _setToastMessage(
          "Agent" +
            (selectedRowsIds.length > 1 ? "s" : "") +
            " anonymisé" +
            (selectedRowsIds.length > 1 ? "s" : "") +
            " avec succès !"
        );
        _setConfirmModal({
          message: "Êtes vous sûr de vouloir anonymiser cet agent ?",
          confirmedAction: () => anonymAgent(row),
        });

        break;
    }
  };

  // format phone number with dot between 2 numbers
  const formatPhoneNumber = (phoneNumber: any) => {
    let numeros = phoneNumber.replace(/\D/g, "");
    let numeroFormate = numeros.match(/.{1,2}/g)?.join(".");

    return numeroFormate || "";
  };

  // load table's data
  const loadTableData = (data: Array<IAgentInfos>) => {
    let rows: any = [];
    const columns: Array<string> = [
      "", //checkbox
      "Matricule",
      "Nom",
      "Prénom",
      "Genre",
      "Âge",
      "Date de naissance",
      "Téléphone Professionnel",
      "E-mail",
      "Actions",
    ];

    // create the rows
    data.forEach((row) => {
      const rowId = row.id;
      rows.push({
        infos: {
          ...row,
        },
        tableData: {
          select: true,
          registrationNumber: row.registrationNumber,
          lastName: row.lastName ? row.lastName : "",
          firstName: row.firstName ? row.firstName : "",
          sex: row.sex,
          // maritalStatus: row.maritalStatus,
          age: row.age ? row.age : "",
          birthDate: changeDateFormatDDMMYYYY(row.birthDate),
          professionalPhoneNumber: formatPhoneNumber(
            row.professionalPhoneNumber
          ),
          email: row.email,
          actions: true,
        },
      });
    });

    // set the data with the columns and rows
    _setData({
      columns,
      rows,
    });
  };

  // restore agent
  const restoreAgent = (agent: IAgentInfos) => {
    _setConfirmModal(null);

    onRestoreAgent(agent.id)
      .then((response: any) => {
        onGetAllArchivedAgents()
          .then((returnData) => {
            if (returnData) {
              loadTableData(returnData);
            }
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // anonym agent
  const anonymAgent = (agent: IAgentInfos) => {
    _setConfirmModal(null);

    onAnonymAgent(agent.id)
      .then((response: any) => {
        onGetAllArchivedAgents()
          .then((returnData) => {
            if (returnData) {
              loadTableData(returnData);
            }
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // on restore selected rows
  const restoreSelectedRows = () => {
    for (let i = 0; i < selectedRowsIds.length; i++) {
      // console.log(selectedRowIds);
      onRestoreAgent(selectedRowsIds[i])
        .then((response: any) => {})
        .catch((err) => {
          console.log(err);
        });
    }
    _setToastMessage(
      `Agent${selectedRowsIds.length > 1 ? "s" : ""} restauré${
        selectedRowsIds.length > 1 ? "s " : ""
      } avec succès !`
    );
    _setToastVisible(true);
    _setTriggerLoadDataFromDB((lastVal) => !lastVal);
    _setConfirmModal(null);
    _setSelectedRowsIds([]);

    onGetAllArchivedAgents()
      .then((returnData) => {
        if (returnData) {
          loadTableData(returnData);
        }
      })
      .catch((err) => console.error(err));
  };

  // on anonymise selected rows
  const anonymiseSelectedRows = () => {
    for (let i = 0; i < selectedRowsIds.length; i++) {
      // console.log(selectedRowIds);
      onAnonymAgent(selectedRowsIds[i])
        .then((response: any) => {})
        .catch((err) => {
          console.log(err);
        });
    }
    _setToastMessage(
      `Agent${selectedRowsIds.length > 1 ? "s" : ""} anonymisé${
        selectedRowsIds.length > 1 ? "s " : ""
      } avec succès !`
    );
    _setToastVisible(true);
    _setTriggerLoadDataFromDB((lastVal) => !lastVal);
    _setConfirmModal(null);
    _setSelectedRowsIds([]);

    onGetAllArchivedAgents()
      .then((returnData) => {
        if (returnData) {
          loadTableData(returnData);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <BodyContainer title="Liste des Agents Archivés">
      {/* table */}
      <TableComponent
        data={data}
        onRestoreSelectedRows={() =>
          _setConfirmModal({
            message: `Voulez-vous restaurer ${
              selectedRowsIds.length > 1 ? "les " : "l'"
            }agent${selectedRowsIds.length > 1 ? "s " : " "} sélectionné${
              selectedRowsIds.length > 1 ? "s" : ""
            } ?`,
            confirmedAction: restoreSelectedRows,
          })
        }
        onAnonymiseSelectedRows={() => {
          _setConfirmModal({
            message: `Voulez-vous anonymiser ${
              selectedRowsIds.length > 1 ? "les " : "l'"
            }agent${selectedRowsIds.length > 1 ? "s " : " "} sélectionné${
              selectedRowsIds.length > 1 ? "s" : ""
            } ?`,
            confirmedAction: anonymiseSelectedRows,
          });
        }}
        rowActions={ROW_ACTIONS}
        onRowActionSelected={(infos: { row: IAgentInfos; action: string }) =>
          handleRowActionSelected(infos)
        }
        onUpdatedSelectedRowsIds={(ids: number[]) => _setSelectedRowsIds(ids)}
      />

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => {
                  confirmModal.confirmedAction();
                  _setToastVisible(true);
                }}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </BodyContainer>
  );
};

export default ArchivedAgents;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
