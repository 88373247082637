import { MouseEventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import {
  COLOR_BTN,
  COLOR_RED_BRIGHT,
  COLOR_SIDEBAR,
  COLOR_TEXT,
  COLOR_TEXT_MUTED,
  COLOR_TITLE,
} from "../constants/cts_colors";

const ToastAlert = ({
  text,
  handleYes,
  handleNo,
  handleOk,
  endedTimer,
}: {
  text: string;
  handleYes?: MouseEventHandler<HTMLButtonElement>;
  handleNo?: MouseEventHandler<HTMLButtonElement>;
  handleOk?: MouseEventHandler<HTMLButtonElement>;
  endedTimer: Function;
}) => {
  const [width, _setWidth] = useState<number>(100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      _setWidth((width) => (width > 0 ? width - 1 : 0));
    }, 24);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (width === 0) {
      endedTimer();
    }
  }, [width]);

  return (
    <ToastStyles style={{ display: width > 0 ? "block" : "none" }}>
      <p>{text}</p>
      <div className="buttons">
        {handleOk && (
          <button className="ok-button" onClick={handleOk}>
            OK
          </button>
        )}
        {handleYes && (
          <button className="yes-button" onClick={handleYes}>
            Oui
          </button>
        )}
        {handleNo && (
          <button className="no-button" onClick={handleNo}>
            Non
          </button>
        )}
      </div>
      <div style={{ width: `${width}%` }} className="timer"></div>
    </ToastStyles>
  );
};

export default ToastAlert;

const ToastStyles = styled.div`
  right: 24px;
  bottom: 24px;
  position: absolute;
  display: block;
  width: max-content;
  background-color: ${COLOR_SIDEBAR};
  border: 1px solid ${COLOR_TEXT_MUTED};
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  color: ${COLOR_TEXT};
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);

  p {
    font-style: italic;
    padding: 16px 24px;
    text-align: center;
    color: ${COLOR_TEXT};
  }

  .buttons {
    width: 100%;
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;
    transition: 250ms;

    .ok-button {
      font-size: 1.1rem;
      width: 96px;
      padding: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid ${COLOR_TITLE};
      background-color: ${COLOR_TITLE};
      color: ${COLOR_SIDEBAR};
      transition: 250ms;

      :hover {
        background-color: ${COLOR_SIDEBAR};
        color: ${COLOR_TITLE};
      }
    }

    .yes-button {
      font-size: 1.1rem;
      width: 96px;
      padding: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid ${COLOR_TITLE};
      background-color: ${COLOR_TITLE};
      color: ${COLOR_SIDEBAR};
      transition: 250ms;

      :hover {
        background-color: ${COLOR_SIDEBAR};
        color: ${COLOR_TITLE};
      }
    }

    .no-button {
      font-size: 1.1rem;
      width: 96px;
      padding: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid ${COLOR_BTN};
      background-color: ${COLOR_BTN};
      color: ${COLOR_SIDEBAR};
      transition: 250ms;

      :hover {
        background-color: ${COLOR_SIDEBAR};
        color: ${COLOR_BTN};
      }
    }
  }

  .timer {
    position: relative;
    height: 3px;
    border-radius: 4px;
    background-color: ${COLOR_RED_BRIGHT};
  }

  // ========= MEDIA QUERIES - Form / FORM_SUBMIT_BTN ============
  @media (max-width: 800px) {
    font-size: 3vw;
  }
`;
