import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  onLoginApi,
  onAutoLoginApi,
  onLogoutApi,
  onGetUserIfAuthenticatedApi,
} from "../../api/authentication.api";
import { instanceAxios } from "../../utils/axios-api";
import { IAuthenticationContext } from "../../interfaces/authenticationContext";
import { AUTHENTICATION_MSG } from "../../constants/cts_contextErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import { EMPTY_EMAIL, EMPTY_PASSWORD } from "../../constants/cts_formErrors";
import { TOKEN_USER_AUTHENTICATED } from "../../constants/cts_user";

const AuthenticationContext = createContext<IAuthenticationContext | null>(
  null
);

// THE PROVIDER
export const AuthenticationProvider = (props: any) => {
  const [user, _setUser] = useState(null);
  const [token, _setToken] = useState<string | null>(null);
  const [isLoading, _setIsLoading] = useState(false);
  const [isWaitingAutoLogin, _setIsWaitingAutoLogin] = useState(true);
  const [isUserAuthenticated, _setIsUserAuthenticated] = useState(false);
  const [isWaitingAuthenticationResponse, _setIsWaitingAuthenticationResponse] =
    useState(true);

  // login
  const onLogin = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(EMPTY_EMAIL);
        });
      }
      if (!checkEmptyInput(password)) {
        return new Promise((resolve, reject) => {
          reject(EMPTY_PASSWORD);
        });
      }
      _setIsLoading(true);
      return onLoginApi({ email, password })
        .then((returnUser) => {
          _setUser(returnUser.user + { role: returnUser.user.role });
          _setToken(returnUser.token);
          _setIsLoading(false);
        })
        .catch((err) => {
          _setIsLoading(false);
          if (err.response) {
            throw new Error(err.response.data);
          } else {
            throw new Error(err.message);
          }
        });
    },
    []
  );

  // auto login function
  const onAutoLogin = useCallback(async () => {
    const returnUser = await onAutoLoginApi();
    if (returnUser && returnUser.user) {
      _setUser(returnUser.user);
      _setToken(returnUser.token);
    }
    _setIsWaitingAutoLogin(false);
  }, []);

  // logout
  const onLogout = useCallback(() => {
    _setIsLoading(true);
    return onLogoutApi()
      .then((returnUser) => {
        _setIsLoading(false);
        _setUser(returnUser.user);
        _setToken(returnUser.token);
        localStorage.removeItem(TOKEN_USER_AUTHENTICATED);
      })
      .catch((err) => {
        _setIsLoading(false);
        if (err.response) {
          throw new Error(err.response.data);
        } else {
          throw new Error(err.message);
        }
      });
  }, []);

  // set user token
  useEffect(() => {
    if (localStorage.getItem(TOKEN_USER_AUTHENTICATED)) {
      _setToken(localStorage.getItem(TOKEN_USER_AUTHENTICATED));
    }
  }, []);

  // if token found, auto login every time user changes the route
  useEffect(() => {
    if (token) {
      instanceAxios.defaults.headers.common["Authorization"] = token;
      localStorage.setItem(TOKEN_USER_AUTHENTICATED, token);
      onAutoLogin();
    }
  }, [token, onAutoLogin]);

  // check if the user / admin is authenticated
  const onGetUserIfIsAuthenticated = useCallback(() => {
    const localStorageToken = localStorage.getItem(TOKEN_USER_AUTHENTICATED);
    if (localStorageToken) {
      const authenticatedToken = localStorageToken;
      instanceAxios.defaults.headers.common["Authorization"] =
        authenticatedToken;
    }
    return onGetUserIfAuthenticatedApi()
      .then((response) => {
        if (response.user) {
          return response.user;
        } else {
          return null;
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <AuthenticationContext.Provider
      {...props}
      value={{
        user,
        isLoading,
        isWaitingAutoLogin,
        isWaitingAuthenticationResponse,
        isUserAuthenticated,
        onLogin,
        onAutoLogin,
        onLogout,
        onGetUserIfIsAuthenticated,
      }}
    />
  );
};

export const useAuthentication = (): IAuthenticationContext => {
  const context = useContext(AuthenticationContext);
  if (!context) throw new Error(AUTHENTICATION_MSG);
  return context;
};
