import React, { useState, useCallback, createContext } from "react";
import {
  onCreateReferensJobApi,
  onDeleteReferensJobApi,
  onGetAllReferensJobsApi,
  onGetReferensJobApi,
  onGetReferensJobFromCodeApi,
  onUpdateReferensJobApi,
} from "../../api/referensJobs.api";
import {
  IReferensJobFormValues,
  IReferensJobsContext,
} from "../../interfaces/referensJob";

const ReferensJobsContext = createContext(null);

export function ReferensJobsProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateReferensJob = useCallback(
    async ({
      branchCode,
      branch,
      professionalFamily,
      reme,
      idStatutoryCorrespondence,
      codeStatutoryCorrespondence,
      statutoryCorrespondence,
      desiredTraining,
      jobs,
      mission,
      fapReme,
      mainActivities,
      specialExerciseConditions,
      knowledge,
      operationalSkills,
      behavioralSkills,
      statutoryDiplomaRequired,
      mediumtermDevelopmentFactors,
      impactOnJob,
      codeJob,
      jobTitle,
      previousCodeJob,
      previousJobTitle,
      tricarto,
      businessFamilyCode,
      desiredExperience,
      trifap,
      categoryReferens,
      referensSituationType,
      referensSituation,
      isExternalCompetition,
      externalCompetitionProgram,
      externalCompetitionProgramNormal,
      externalCompetitionProgramSuperior,
      pdfFile,
      referensKnowledgeDefinition,
      referensOperationalSkillsDefinition,
      referensBehavioralSkillsDefinition,
      idSkills,
      bap,
      fap,
      description,
    }: IReferensJobFormValues) => {
      _setIsLoadingAgents(true);
      return onCreateReferensJobApi({
        branchCode,
        branch,
        professionalFamily,
        reme,
        idStatutoryCorrespondence,
        codeStatutoryCorrespondence,
        statutoryCorrespondence,
        desiredTraining,
        jobs,
        mission,
        fapReme,
        mainActivities,
        specialExerciseConditions,
        knowledge,
        operationalSkills,
        behavioralSkills,
        statutoryDiplomaRequired,
        mediumtermDevelopmentFactors,
        impactOnJob,
        codeJob,
        jobTitle,
        previousCodeJob,
        previousJobTitle,
        tricarto,
        businessFamilyCode,
        desiredExperience,
        trifap,
        categoryReferens,
        referensSituationType,
        referensSituation,
        isExternalCompetition,
        externalCompetitionProgram,
        externalCompetitionProgramNormal,
        externalCompetitionProgramSuperior,
        pdfFile,
        referensKnowledgeDefinition,
        referensOperationalSkillsDefinition,
        referensBehavioralSkillsDefinition,
        idSkills,
        bap,
        fap,
        description,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllReferensJobs = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllReferensJobsApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetReferensJob = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetReferensJobApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE FROM CODE JOB
  const onGetReferensJobFromCode = useCallback((codeJob: string) => {
    _setIsLoadingAgents(true);
    return onGetReferensJobFromCodeApi(codeJob)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateReferensJob = useCallback(
    async ({
      id,
      branchCode,
      branch,
      professionalFamily,
      reme,
      idStatutoryCorrespondence,
      codeStatutoryCorrespondence,
      statutoryCorrespondence,
      desiredTraining,
      jobs,
      mission,
      fapReme,
      mainActivities,
      specialExerciseConditions,
      knowledge,
      operationalSkills,
      behavioralSkills,
      statutoryDiplomaRequired,
      mediumtermDevelopmentFactors,
      impactOnJob,
      codeJob,
      jobTitle,
      previousCodeJob,
      previousJobTitle,
      tricarto,
      businessFamilyCode,
      desiredExperience,
      trifap,
      categoryReferens,
      referensSituationType,
      referensSituation,
      isExternalCompetition,
      externalCompetitionProgram,
      externalCompetitionProgramNormal,
      externalCompetitionProgramSuperior,
      pdfFile,
      referensKnowledgeDefinition,
      referensOperationalSkillsDefinition,
      referensBehavioralSkillsDefinition,
      idSkills,
      bap,
      fap,
      description,
    }: IReferensJobFormValues) => {
      _setIsLoadingAgents(true);
      return onUpdateReferensJobApi({
        id,
        branchCode,
        branch,
        professionalFamily,
        reme,
        idStatutoryCorrespondence,
        codeStatutoryCorrespondence,
        statutoryCorrespondence,
        desiredTraining,
        jobs,
        mission,
        fapReme,
        mainActivities,
        specialExerciseConditions,
        knowledge,
        operationalSkills,
        behavioralSkills,
        statutoryDiplomaRequired,
        mediumtermDevelopmentFactors,
        impactOnJob,
        codeJob,
        jobTitle,
        previousCodeJob,
        previousJobTitle,
        tricarto,
        businessFamilyCode,
        desiredExperience,
        trifap,
        categoryReferens,
        referensSituationType,
        referensSituation,
        isExternalCompetition,
        externalCompetitionProgram,
        externalCompetitionProgramNormal,
        externalCompetitionProgramSuperior,
        pdfFile,
        referensKnowledgeDefinition,
        referensOperationalSkillsDefinition,
        referensBehavioralSkillsDefinition,
        idSkills,
        bap,
        fap,
        description,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteReferensJob = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onDeleteReferensJobApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);
  return (
    <ReferensJobsContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateReferensJob,
        onGetAllReferensJobs,
        onGetReferensJob,
        onGetReferensJobFromCode,
        onUpdateReferensJob,
        onDeleteReferensJob,
      }}
    />
  );
}

export const useReferensJobs = (): IReferensJobsContext => {
  const context = React.useContext(ReferensJobsContext);
  if (!context)
    throw new Error("useReferensJobs must be used in ReferensJobsProvider");

  return context;
};
