import React, { useState, useCallback, createContext } from "react";
import {
  onAddEditAgentDivisionApi,
  onAnonymAgentApi,
  onCheckRegistrationNumberOfAgentApi,
  onCreateAgentApi,
  onDeleteAgentApi,
  onDeleteAllFromIdListApi,
  onGetAgentApi,
  onGetAllAgentsApi,
  onGetAllAnonymisedAgentsApi,
  onGetAllArchivedAgentsApi,
  onRestoreAgentApi,
  onUpdateAgentApi,
  onUpdateAgentDivisionApi,
} from "../../api/agent.api";
import {
  IAgentAddEditDivisionFormValues,
  IAgentFormValues,
  IAgentsContext,
} from "../../interfaces/agent";
import { EMPTY_REGISTRATION_NUMBER } from "../../constants/cts_formErrors";

const AgentsContext = createContext(null);

export function AgentsProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateAgent = useCallback(
    async ({
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
    }: IAgentFormValues) => {
      if (!registrationNumber) {
        return Promise.reject(EMPTY_REGISTRATION_NUMBER);
      }

      _setIsLoadingAgents(true);
      return onCreateAgentApi({
        firstName,
        lastName,
        sex,
        birthDate,
        registrationNumber,
        maritalStatus,
        professionalPhoneNumber,
        observationsDifficultJobs,
        observationsParticularSituations,
        email,
        bonusVacation,
        departureDate,
        departureReason,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllAgents = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllAgentsApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL ANONYMISED
  const onGetAllAnonymisedAgents = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllAnonymisedAgentsApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL ARCHIVED
  const onGetAllArchivedAgents = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllArchivedAgentsApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // RESTORE AGENT FROM ARCHIVED
  const onRestoreAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onRestoreAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // CHECK IF MATRICULE EXISTS
  const onCheckRegistrationNumberOfAgent = useCallback(
    (registrationNumber: number) => {
      _setIsLoadingAgents(true);
      return onCheckRegistrationNumberOfAgentApi(registrationNumber)
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  // UPDATE AGENT DIVISION
  const onUpdateAgentDivision = useCallback(
    async ({ id, divisionId }: { id: number; divisionId: number }) => {
      _setIsLoadingAgents(true);
      return onUpdateAgentDivisionApi({
        id,
        divisionId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // UPDATE ONE
  const onUpdateAgent = useCallback(
    async ({
      id,
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
    }: IAgentFormValues) => {
      if (!registrationNumber) {
        return Promise.reject(EMPTY_REGISTRATION_NUMBER);
      }

      _setIsLoadingAgents(true);
      return onUpdateAgentApi({
        id,
        firstName,
        lastName,
        sex,
        birthDate,
        registrationNumber,
        maritalStatus,
        professionalPhoneNumber,
        observationsDifficultJobs,
        observationsParticularSituations,
        email,
        bonusVacation,
        departureDate,
        departureReason,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // ADD / EDIT AGENT DIVISION
  const onAddEditAgentDivision = useCallback(
    async ({ agentId, divisionId }: IAgentAddEditDivisionFormValues) => {
      _setIsLoadingAgents(true);
      return onAddEditAgentDivisionApi({
        agentId,
        divisionId,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onDeleteAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // DELETE ONE
  const onAnonymAgent = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onAnonymAgentApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // DELETE ALL FROM ID LIST
  const onDeleteAllFromIdList = useCallback((agentIds: Array<number>) => {
    _setIsLoadingAgents(true);
    return onDeleteAllFromIdListApi(agentIds)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <AgentsContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateAgent,
        onGetAllAgents,
        onGetAllArchivedAgents,
        onRestoreAgent,
        onGetAgent,
        onCheckRegistrationNumberOfAgent,
        onUpdateAgent,
        onUpdateAgentDivision,
        onAddEditAgentDivision,
        onDeleteAgent,
        onAnonymAgent,
        onGetAllAnonymisedAgents,
        onDeleteAllFromIdList,
      }}
    />
  );
}

export const useAgents = (): IAgentsContext => {
  const context = React.useContext(AgentsContext);
  if (!context) throw new Error("useAgents must be used in AgentsProvider");

  return context;
};
