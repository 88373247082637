import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_CATEGORY_TYPE,
  EMPTY_CATEGORY_BODY,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  ICategoriesContext,
  ICategoryFormValues,
} from "../../interfaces/category";
import {
  onCreateCategoryApi,
  onDeleteCategoryApi,
  onGetAllCategoriesApi,
  onGetCategoryApi,
  onUpdateCategoryApi,
  onGetCategoryFromAgentApi,
} from "../../api/category.api";
import {
  onGetAllBodiesGradesApi,
  onGetBodyGradeApi,
} from "../../api/bodyGrade.api";
import { IBodiesGradesContext } from "../../interfaces/bodyGrade";

const BodiesGradesContext = createContext(null);

export function BodiesGradesProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // // CREATE
  // const onCreateBodyGrade = useCallback(
  //   async ({ type, body, grade, agentId }: IBodyGradeFormValues) => {
  //     if (!checkEmptyInput(type)) {
  //       return Promise.reject(EMPTY_CATEGORY_TYPE);
  //     }
  //     if (!checkEmptyInput(body)) {
  //       return Promise.reject(EMPTY_CATEGORY_BODY);
  //     }

  //     _setIsLoadingAgents(true);
  //     return onCreateCategoryApi({
  //       type,
  //       body,
  //       grade,
  //       agentId,
  //     })
  //       .then((response) => {
  //         _setIsLoadingAgents(false);
  //         return response;
  //       })
  //       .catch((error) => {
  //         _setIsLoadingAgents(false);
  //         throw error;
  //       });
  //   },
  //   []
  // );

  // GET ALL
  const onGetAllBodiesGrades = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllBodiesGradesApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetBodyGrade = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetBodyGradeApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // // GET ONE FROM AGENT
  // const onGetCategoryFromAgent = useCallback((id: number) => {
  //   _setIsLoadingAgents(true);
  //   return onGetCategoryFromAgentApi(id)
  //     .then((response) => {
  //       _setIsLoadingAgents(false);
  //       return response;
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         throw new Error(error.message.data);
  //       } else {
  //         throw new Error(error.message);
  //       }
  //     });
  // }, []);

  // UPDATE ONE
  // const onUpdateCategory = useCallback(
  //   async ({ id, type, body, grade, agentId }: ICategoryFormValues) => {
  //     _setIsLoadingAgents(true);
  //     return onUpdateCategoryApi({
  //       id,
  //       type,
  //       body,
  //       grade,
  //       agentId,
  //     })
  //       .then((response) => {
  //         _setIsLoadingAgents(false);
  //         return response;
  //       })
  //       .catch((error) => {
  //         _setIsLoadingAgents(false);
  //         throw error;
  //       });
  //   },
  //   []
  // );

  // DELETE ONE
  // const onDeleteCategory = useCallback((id: number) => {
  //   _setIsLoadingAgents(true);
  //   return onDeleteCategoryApi(id)
  //     .then((response) => {
  //       _setIsLoadingAgents(false);
  //       return response;
  //     })
  //     .catch((error) => {
  //       _setIsLoadingAgents(false);
  //       if (error.response) {
  //         throw new Error(error.message.data);
  //       } else {
  //         throw new Error(error.message);
  //       }
  //     });
  // }, []);

  return (
    <BodiesGradesContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        // onCreateCategory,
        onGetAllBodiesGrades,
        onGetBodyGrade,
        // onUpdateCategory,
        // onDeleteCategory,
        // onGetCategoryFromAgent,
      }}
    />
  );
}

export const useBodiesGrades = (): IBodiesGradesContext => {
  const context = React.useContext(BodiesGradesContext);
  if (!context)
    throw new Error("useBodiesGrades must be used in BodiesGradesProvider");

  return context;
};
