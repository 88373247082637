import React from "react";
import { AuthenticationProvider } from "./authenticationContext";
import { UserProvider } from "./userContext";
import { AgentsProvider } from "./agentContext";
import { DivisionsProvider } from "./divisionContext";
import { AgentsFilesProvider } from "./agentFileContext";
import { AgentsInactivitiesProvider } from "./agentInactivityContext";
import { AgentsStatusProvider } from "./agentStatusContext";
import { CategoriesProvider } from "./categoryContext";
import { DepartmentsProvider } from "./departmentContext";
import { EmploymentsProvider } from "./employmentContext";
import { FormationsProvider } from "./formationContext";
import { DegreesProvider } from "./degreeContext";
import { BonifiedVacationsProvider } from "./bonifiedVacationContext";
import { OrgChartProvider } from "./orgChartContext";
import { ResponsiblesProvider } from "./responsibleContext";
import { BodiesGradesProvider } from "./bodyGradeContext";
import { TeleworkingProvider } from "./teleworkingContext";
import { FilesProvider } from "./fileContext";
import { ReferensJobsProvider } from "./referensJobContext";
import { NotificationsProvider } from "./notificationContext";

const Providers = (props: any) => {
  return (
    <NotificationsProvider>
      <OrgChartProvider>
        <DepartmentsProvider>
          <DivisionsProvider>
            <AgentsProvider>
              <UserProvider>
                <AgentsFilesProvider>
                  <AgentsInactivitiesProvider>
                    <AgentsStatusProvider>
                      <CategoriesProvider>
                        <EmploymentsProvider>
                          <FormationsProvider>
                            <DegreesProvider>
                              <BonifiedVacationsProvider>
                                <ResponsiblesProvider>
                                  <BodiesGradesProvider>
                                    <TeleworkingProvider>
                                      <FilesProvider>
                                        <ReferensJobsProvider>
                                          <AuthenticationProvider {...props} />
                                        </ReferensJobsProvider>
                                      </FilesProvider>
                                    </TeleworkingProvider>
                                  </BodiesGradesProvider>
                                </ResponsiblesProvider>
                              </BonifiedVacationsProvider>
                            </DegreesProvider>
                          </FormationsProvider>
                        </EmploymentsProvider>
                      </CategoriesProvider>
                    </AgentsStatusProvider>
                  </AgentsInactivitiesProvider>
                </AgentsFilesProvider>
              </UserProvider>
            </AgentsProvider>
          </DivisionsProvider>
        </DepartmentsProvider>
      </OrgChartProvider>
    </NotificationsProvider>
  );
};

export default Providers;
