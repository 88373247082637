import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_FORMATION_NAME,
  EMPTY_FORMATION_YEAR,
  EMPTY_FORMATION_AGENTID,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateFormationApi,
  onDeleteFormationApi,
  onGetAllFormationsApi,
  onGetFormationApi,
  onUpdateFormationApi,
  onGetAllFormationsFromAgentApi,
} from "../../api/formation.api";
import {
  IFormationFormValues,
  IFormationsContext,
} from "../../interfaces/formation";

const FormationsContext = createContext(null);

export function FormationsProvider(props: any) {
  const [isLoadingFormations, _setIsLoadingFormations] = useState(false);

  // CREATE
  const onCreateFormation = useCallback(
    async ({ name, year, agentId, comments, endValidationDate }: IFormationFormValues) => {
      if (!checkEmptyInput(name)) {
        return Promise.reject(EMPTY_FORMATION_NAME);
      }
      if (!checkEmptyInput(year)) {
        return Promise.reject(EMPTY_FORMATION_YEAR);
      }
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_FORMATION_AGENTID);
      }

      _setIsLoadingFormations(true);
      return onCreateFormationApi({
        name,
        year,
        agentId,
        comments,
        endValidationDate,
      })
        .then((response) => {
          _setIsLoadingFormations(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingFormations(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllFormations = useCallback(() => {
    _setIsLoadingFormations(true);
    return onGetAllFormationsApi()
      .then((response) => {
        _setIsLoadingFormations(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingFormations(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM AGENT
  const onGetAllFormationsFromAgent = useCallback((id: number) => {
    _setIsLoadingFormations(true);
    return onGetAllFormationsFromAgentApi(id)
      .then((response) => {
        _setIsLoadingFormations(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingFormations(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetFormation = useCallback((id: number) => {
    _setIsLoadingFormations(true);
    return onGetFormationApi(id)
      .then((response) => {
        _setIsLoadingFormations(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateFormation = useCallback(
    async ({ id, name, year, agentId, comments, endValidationDate }: IFormationFormValues) => {
      _setIsLoadingFormations(true);
      return onUpdateFormationApi({
        id,
        name,
        year,
        agentId,
        comments,
        endValidationDate,
      })
        .then((response) => {
          _setIsLoadingFormations(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingFormations(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteFormation = useCallback((id: number) => {
    _setIsLoadingFormations(true);
    return onDeleteFormationApi(id)
      .then((response) => {
        _setIsLoadingFormations(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingFormations(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <FormationsContext.Provider
      {...props}
      value={{
        isLoadingFormations,
        // function
        onCreateFormation,
        onGetAllFormations,
        onGetFormation,
        onUpdateFormation,
        onDeleteFormation,
        onGetAllFormationsFromAgent,
      }}
    />
  );
}

export const useFormations = (): IFormationsContext => {
  const context = React.useContext(FormationsContext);
  if (!context)
    throw new Error("useFormations must be used in FormationsProvider");

  return context;
};
