import { IAgentStatusFormValues } from "../interfaces/agentStatus";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateAgentStatusApi = async ({
  type,
  startDate,
  endDate,
  subtype,
  agentId,
}: IAgentStatusFormValues) => {
  return instanceAxios
    .post("/agents-status/create-agent-status", {
      type,
      startDate,
      endDate,
      subtype,
      agentId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllAgentsStatusApi = async () => {
  return instanceAxios
    .get(`/agents-status/get-all-agents-status`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetAgentStatusApi = async (id: number) => {
  return instanceAxios
    .get(`/agents-status/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE FROM AGENT
export const onGetAgentStatusFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/agents-status/unique-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteAgentStatusApi = async (id: number) => {
  return instanceAxios
    .delete(`/agents-status/delete-agent-status/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateAgentStatusApi = async ({
  id,
  type,
  startDate,
  endDate,
  subtype,
  agentId,
}: IAgentStatusFormValues) => {
  return instanceAxios
    .put("/agents-status/update-agent-status/" + id, {
      type,
      startDate,
      endDate,
      subtype,
      agentId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
