import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../../components/form/FormBase";
import Button from "../../../../components/Button";
import FormErrorMessageContainer from "../../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../../components/form/FormLabel";
import FormInputContainer from "../../../../components/form/FormInputContainer";
import { DIVISION } from "../../../../constants/cts_divisions";
import { MdOutlineSchema } from "react-icons/md";
import { IDepartmentFormValues } from "../../../../interfaces/department";
import { useDivisions } from "../../../../common/contexts/divisionContext";
import { IDivisionInfos } from "../../../../interfaces/division";
import { RiHomeGearLine } from "react-icons/ri";
import FormInfoMessage from "../../../../components/form/FormInfoMessage";

const ChangeParentDivisionForm = ({
  title,
  formData,
  services,
  currentService,
  actionString,
  onSubmit,
}: {
  title: string,
  formData: IDepartmentFormValues,
  services: IDivisionInfos[],
  currentService: IDivisionInfos| null,
  actionString: string,
  onSubmit: Function
}) => {
  const [ formValues, _setFormValues ] = useState<IDepartmentFormValues>(formData);
  const [ errorMessage, _setErrorMessage ] = useState<string | null>(null);
  const { onGetDivisionChildren, onChangeParentDivision } = useDivisions();
  const [ parentDivisions, _setParentDivisions ] = useState<IDivisionInfos[]>([]);
  const [ allowSelectParentDivision, _setAllowSelectParentDivision ] = useState<boolean>(false);

  // load parent divisions
  const loadParentDivisions = useCallback((serviceId?: number | null) => {
    if (serviceId) {
      onGetDivisionChildren(serviceId)
      .then((response) => {
        _setParentDivisions(response)
        _setAllowSelectParentDivision(true)
      })
      .catch((error) => console.error(error))
    } else {
      _setAllowSelectParentDivision(false)
    }
  }, [onGetDivisionChildren])

  // load parent divisions on the first modal render
  useEffect(() => {
    loadParentDivisions(formData.parentServiceId)
  }, [loadParentDivisions])

  // on change
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'parentServiceId') {
      onGetDivisionChildren(parseInt(value))
        .then((response) => _setParentDivisions(response))
        .catch((error) => console.error(error))
      return _setFormValues({
        ...formValues,
        parentServiceId: parseInt(value),
        parentDivisionId: parseInt(value),
      })
    }

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    })
  }

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    onChangeParentDivision({
      divisionId: formValues.id,
      oldParentDivisionId: formData.parentDivisionId,
      newParentDivisionId: formValues.parentDivisionId,
    })
    .then(() => {
      onSubmit()
    })
    .catch((err) => {
      _setErrorMessage(err)
    })
  }

  return (
    <FormBase
      title={title}
    >
      <FormContainer>
        <div className="service-info">
          <FormInfoMessage text={currentService?.name || ''} />
          <FormInfoMessage text={`${formData.type ? formData.type.toUpperCase() + ' ' : '' }| ${formData.name}`} />
        </div>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className='inputs-container'>
              {/* select service */}
              <div>
                <FormLabel htmlFor="parentServiceId" text="À quel service appartient ?" />
                <FormInputContainer
                  icon={<RiHomeGearLine />}
                  input={
                    <select id="parentServiceId" value={formValues.parentServiceId ? formValues.parentServiceId : ""} name="parentServiceId" onChange={handleChange}>
                      {
                        services &&
                        services.length > 0 &&
                        <>
                          <option value=''>Choisir</option>
                          {
                            services.map((service) => 
                            <option
                              key={`parent-service-${service.id}`}
                              value={service.id}>
                                {service.name}
                            </option>
                            )
                          }
                        </>
                      }
                    </select>
                  }
                />
              </div>

              {/* select service's division */}
              {
                allowSelectParentDivision &&
                <div>
                  <FormLabel htmlFor="parentDivisionId" text="La branche" />
                  <FormInputContainer
                    icon={<MdOutlineSchema />}
                    input={
                      <select id="parentDivisionId" value={formValues.parentDivisionId ? formValues.parentDivisionId : ""} name="parentDivisionId" onChange={handleChange}>
                        <option value={formValues.parentServiceId ? formValues.parentServiceId : ''}>
                          Appartient au service sélectionné
                        </option>
                        {
                          parentDivisions &&
                          parentDivisions.length > 0 &&
                          parentDivisions.map((parentDivision) =>
                            {
                              return parentDivision.id !== formData.id && // exclude the current division
                                <option
                                  key={`parent-division-${parentDivision.id}`}
                                  value={parentDivision.id}>
                                    {`Niv. ${(parentDivision.level - DIVISION.service.level) * -1} | ${parentDivision.type ? parentDivision.type.toUpperCase() + ' ' : 'Pôle/Équipe/Cellule ' }| ${parentDivision.name}`}
                                </option>
                            }
                          )
                        }
                      </select>
                    }
                  />
                </div>
              }

              {/* form errors */}
              {
              errorMessage &&
              <FormErrorMessageContainer errorMessage={errorMessage} />
              }

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={actionString}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  )
}

export default ChangeParentDivisionForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;

  .service-info {
    margin-bottom: 10px;
    padding: 0px 20px;
  }
`

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`