import { ICategoryFormValues } from "../interfaces/category";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateCategoryApi = async ({
  type,
  sigleBody,
  body,
  sigleGrade,
  grade,
  agentId,
  bodygradeId,
}: ICategoryFormValues) => {
  return instanceAxios
    .post("/categories/create-category", {
      type,
      sigleBody,
      body,
      sigleGrade,
      grade,
      agentId,
      bodygradeId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllCategoriesApi = async () => {
  return instanceAxios
    .get(`/categories/get-all-categories`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetCategoryApi = async (id: number) => {
  return instanceAxios
    .get(`/categories/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE FROM AGENT
export const onGetCategoryFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/categories/unique-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteCategoryApi = async (id: number) => {
  return instanceAxios
    .delete(`/categories/delete-category/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateCategoryApi = async ({
  id,
  type,
  sigleBody,
  body,
  sigleGrade,
  grade,
  agentId,
  bodygradeId,
}: ICategoryFormValues) => {
  return instanceAxios
    .put("/categories/update-category/" + id, {
      type,
      sigleBody,
      body,
      sigleGrade,
      grade,
      agentId,
      bodygradeId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
