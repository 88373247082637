import { CUSTOM_POSITION_DIVISION_REFS } from "../../constants/cts_divisions";
import { IAgentInfos } from "../../interfaces/agent";
import { IDivisionBranch } from "../../interfaces/division";
import { IOrgChartFilterOptions } from "../../interfaces/orgChart";
import { IResponsibleInfos } from "../../interfaces/responsible";
import { convertDateToString } from "../date";

// FIND AND GET THE PARENT BRANCH
const getParentNode = ({root, childNode}: {root: IDivisionBranch, childNode: IDivisionBranch}) => {

  const crossNode = (node: IDivisionBranch): IDivisionBranch | null => {
    // if the parent branch is found, return the branch
    if (node.id == childNode.divisionNode.parentDivisionNodeId) {
      return node
    } else if (node.children) {
      // if the branch is not yet found, continue the function
      let response = null
      for (let i = 0; response == null && i < node.children.length; i++) {
        response = crossNode(node.children[i])
      }
      return response
    }
    return null
  }

  return crossNode(root)
}

// GET DIVISION CONTAINER
export const getDivisionContainer = () => {
  const divisionHeight = 80
  return {
    wholeHeight: divisionHeight,
  }
}

// GET RESPONSIBLES CONTAINER
export const getResponsiblesContainer = ({
  responsibles,
  x,
  y,
  ctx,
  nodeWidth,
  horizontalPadding,
  verticalSpacing,
  filters,
}: {
  responsibles: IResponsibleInfos[],
  x: number,
  y: number,
  ctx: CanvasRenderingContext2D,
  nodeWidth: number,
  horizontalPadding: number,
  verticalSpacing: number,
  filters: IOrgChartFilterOptions,
}) => {
  // check if there are responsibles
  if (responsibles.length > 0) {
    let wholeHeight = 0

    const connectionHeight = verticalSpacing / 4
    wholeHeight += connectionHeight
    const containerStartX = x
    const containerStartY = y + connectionHeight
    // Set the font before measuring text
    const fontSizeAndName = "14px Arial"
    ctx.font = fontSizeAndName;

    // generate the text / lines
    let lines = [];
    // for each responsible
    for (let responsibleIndex = 0; responsibleIndex < responsibles.length; responsibleIndex++) {
      const responsible = responsibles[responsibleIndex];
      const responsibleNameWords = (responsible.agent.firstName + ' ' + responsible.agent.lastName).split(' ');
      const maxTextWidth = nodeWidth - 2 * horizontalPadding;
      let responsibleStatusWords = ''

      if (responsible.agent.status && filters.showResponsibleStatus) {
        const { type, subtype, startDate, endDate } = responsible.agent.status
        responsibleStatusWords = `(${type} ${subtype ? '- ' + subtype : ''} | ${convertDateToString(startDate)} ${endDate ? '- ' + convertDateToString(endDate) : ''})`
      }

      let responsibleTypeWords: string[] = []
      if (filters.showResponsibleType) {
        responsibleTypeWords = (responsible.responsibleType + ' : ').split(' ');
      }

      const words = []

      for (let i = 0; i < responsibleTypeWords.length; i++) {
        const word = responsibleTypeWords[i]
        words.push({
          string: word,
          isBold: true,
        })
      }

      for (let i = 0; i < responsibleNameWords.length; i++) {
        const word = responsibleNameWords[i]
        words.push({
          string: word,
          isBold: false,
        })
      }

      if (responsibleStatusWords) {
        const splitWords = responsibleStatusWords.split(' ')
        for (let i = 0; i < splitWords.length; i++) {
          const word = splitWords[i]
          words.push({
            string: word,
            isBold: false,
          })
        }
      }

      let lineText = words[0].string;
      let lineWords = [{
        string: words[0].string,
        isBold: words[0].isBold,
      }];

      // wrap the text in lines
      // and push the lines
      for (let wordIndex = 1; wordIndex < words.length; wordIndex++) {
        const word = words[wordIndex];
        const testLine = lineText + ' ' + word.string;
        const testWidth = ctx.measureText(testLine).width;

        if (testWidth <= maxTextWidth) {
          lineText = testLine;
          lineWords.push(word);
        } else {
          lines.push({
            text: lineText,
            words: lineWords,
          });
          // reset line values
          lineText = words[wordIndex].string;
          lineWords = [{
            string: words[wordIndex].string,
            isBold: words[wordIndex].isBold,
          }];
        }
      }

      lines.push({
        text: lineText,
        words: lineWords,
      });
    }

    // the container with the lines
    const lineGap = 10;
    const textMetrics = ctx.measureText(lines[0].text);
    let containerHeight = lines.length * (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) + (lines.length - 1) * lineGap + 2 * horizontalPadding;
    wholeHeight += containerHeight;

    // return the infos
    return {
      wholeHeight,
      containerHeight,
      containerStartX,
      containerStartY,
      connectionHeight,
      textMetrics,
      lineGap,
      lines,
      fontSizeAndName,
    }
  }
}

// GET AGENTS CONTAINER
export const getAgentsContainer = ({
  agents,
  x,
  y,
  verticalSpacing,
  nodeWidth,
  horizontalPadding,
  filters,
  ctx,
}: {
  agents: IAgentInfos[],
  x: number,
  y: number,
  verticalSpacing: number,
  nodeWidth: number,
  horizontalPadding: number,
  filters: IOrgChartFilterOptions,
  ctx: CanvasRenderingContext2D,
}) => {
  let wholeHeight = 0
  let containerStartX = null
  let containerStartY = null
  // check if there are agents
  if (agents.length > 0) {
    const connectionHeight = verticalSpacing / 4
    wholeHeight += connectionHeight
    containerStartX = x
    containerStartY = y + connectionHeight
    // Set the font before measuring text
    const fontSizeAndName = "14px Arial"
    ctx.font = fontSizeAndName;

    // generate the text / lines
    let lines = [];
    // for each agent
    for (let agentIndex = 0; agentIndex < agents.length; agentIndex++) {
      const agent = agents[agentIndex];
      const agentNameWords = (agent.firstName + ' ' + agent.lastName).split(' ');
      const maxTextWidth = nodeWidth - 2 * horizontalPadding;
      let agentStatusWords = ''
      if (agent.status && filters.showAgentStatus) {
        const { type, subtype, startDate, endDate } = agent.status
        agentStatusWords = `(${type} ${subtype ? '- ' + subtype : ''} | ${convertDateToString(startDate)} ${endDate ? '- ' + convertDateToString(endDate) : ''})`
      }
      let agentTypeWords: string[] = [];
      if (agent.employment && filters.showAgentType) {
        agentTypeWords = (agent.employment.name + ' : ').split(' ')
      }

      const words = []

      for (let i = 0; i < agentTypeWords.length; i++) {
        const word = agentTypeWords[i]
        words.push({
          string: word,
          isBold: true,
        })
      }

      for (let i = 0; i < agentNameWords.length; i++) {
        const word = agentNameWords[i]
        words.push({
          string: word,
          isBold: false,
        })
      }

      if (agentStatusWords) {
        const splitWords = agentStatusWords.split(' ')
        for (let i = 0; i < splitWords.length; i++) {
          const word = splitWords[i]
          words.push({
            string: word,
            isBold: false,
          })
        }
      }

      let lineText = words[0].string;
      let lineWords = [{
        string: words[0].string,
        isBold: words[0].isBold,
      }];

      // wrap the text in lines
      // and push the lines
      for (let wordIndex = 1; wordIndex < words.length; wordIndex++) {
        const word = words[wordIndex];
        const testLine = lineText + ' ' + word.string;
        const testWidth = ctx.measureText(testLine).width;

        if (testWidth <= maxTextWidth) {
          lineText = testLine;
          lineWords.push(word);
        } else {
          lines.push({
            text: lineText,
            words: lineWords,
          });
          // reset line values
          lineText = words[wordIndex].string;
          lineWords = [{
            string: words[wordIndex].string,
            isBold: words[wordIndex].isBold,
          }];
        }
      }

      lines.push({
        text: lineText,
        words: lineWords,
      });
    }

    // the container with the lines
    const lineGap = 10;
    const textMetrics = ctx.measureText(lines[0].text);
    let containerHeight = lines.length * (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) + (lines.length - 1) * lineGap + 2 * horizontalPadding;
    wholeHeight += containerHeight;

    // return the infos
    return {
      wholeHeight,
      containerHeight,
      containerStartX,
      containerStartY,
      connectionHeight,
      textMetrics,
      lineGap,
      lines,
      fontSizeAndName,
    }
  }
}

// GET NODE CONTAINER
const getNodeContainer = ({
  node,
  x,
  y,
  verticalSpacing,
  nodeWidth,
  filters,
  ctx,
}: {
  node: IDivisionBranch,
  x?: number,
  y?: number,
  verticalSpacing: number,
  nodeWidth: number,
  filters: IOrgChartFilterOptions,
  ctx: CanvasRenderingContext2D,
}) => {
  let height = 0;
  const horizontalPadding = 10;

  // when the position is not given, set the default [0, 0]
  if (x === undefined) {
    x = 0;
  }
  if (y === undefined) {
    y = 0;
  }

  const divisionContainer = getDivisionContainer();
  height += divisionContainer.wholeHeight
  const responsiblesContainer = getResponsiblesContainer({
    responsibles: node.responsibles,
    x,
    y: y + height,
    ctx,
    nodeWidth,
    horizontalPadding,
    verticalSpacing,
    filters
  });

  height += responsiblesContainer ? responsiblesContainer.wholeHeight : 0
  const agentsContainer = getAgentsContainer({
    agents: node.agents,
    x,
    y: y + height,
    nodeWidth,
    horizontalPadding,
    verticalSpacing,
    filters,
    ctx
  });

  height += agentsContainer ? agentsContainer.wholeHeight : 0

  return {node: {height}, division: divisionContainer, agents: agentsContainer, responsibles: responsiblesContainer};
}

// FIND THE NODE BY REF
const findNodeByRef = ({branch, ref}: {branch: IDivisionBranch, ref: string}): IDivisionBranch | null => {

  if (branch.division.refOrgChart === ref) {
    return branch;
  }

  let response = null
  for (let i = 0; !response && i < branch.children.length; i++) {
    response = findNodeByRef({branch: branch.children[i], ref})

    if (response)
      return response
  }

  return response
}

// GET CUSTOM POSITION
const getCustomPosition = ({
  node,
  parentNode,
  verticalSpacing,
  siblingSpacing,
  nodeWidth,
  filters,
  ctx,
}: {
  node: IDivisionBranch,
  parentNode: IDivisionBranch,
  verticalSpacing: number,
  siblingSpacing: number,
  nodeWidth: number,
  filters: IOrgChartFilterOptions,
  ctx: CanvasRenderingContext2D,
}) => {
  const nodeHeight = getNodeContainer({node, verticalSpacing, nodeWidth, filters, ctx}).node.height
  const { childrenRowWidth } = getChildrenRowInfos({node, nodeWidth})
  if (
    parentNode.orgChartPosition &&
    parentNode.orgChartPosition.x !== null &&
    parentNode.orgChartPosition.y !== null
  ) {
    const parentX = parentNode.orgChartPosition.x
    const parentY = parentNode.orgChartPosition.y
    switch (node.division.refOrgChart) {
      // service_administratif_et_financier
      case CUSTOM_POSITION_DIVISION_REFS.service_administratif_et_financier:
        return {
          x: parentX - childrenRowWidth / 2 - siblingSpacing,
          y: parentY + nodeHeight + verticalSpacing * 2,
        };

      // pole_relation_et_service_a_l_usager
      case CUSTOM_POSITION_DIVISION_REFS.pole_relation_et_service_a_l_usager:
        return {
          x: parentX + childrenRowWidth / 2 + siblingSpacing,
          y: parentY + nodeHeight + verticalSpacing * 3,
        };

      // service_securite_incendie
      case CUSTOM_POSITION_DIVISION_REFS.coordination_technique_ssi:
        return {
          x: parentX - nodeWidth - siblingSpacing,
          y: parentY + verticalSpacing + nodeHeight,
        };

      default:
        return null;
    }
  }
};

// GET BRANCH MIN / MAX POSITIONS
const getBranchMinMaxPositions = ({node, xPositions, yPositions}: {node: IDivisionBranch, xPositions: number[], yPositions: number[]}) => {
  if (
    node.orgChartPosition.x !== null &&
    node.orgChartPosition.y !== null
  ) {
    xPositions.push(node.orgChartPosition.x)
    yPositions.push(node.orgChartPosition.y)
  }

  for (let i = 0; i < node.children.length; i++) {
    getBranchMinMaxPositions({node: node.children[i], xPositions, yPositions})
  }

  return {
    branchMaxX: Math.max(...xPositions),
    branchMinX: Math.min(...xPositions),
    branchMaxY: Math.max(...yPositions),
    branchMinY: Math.min(...yPositions),
  };
}

// GET CHILDREN ROW WIDTH
const getChildrenRowInfos = ({node, nodeWidth}: {node: IDivisionBranch, nodeWidth: number}) => {
  const childrenXPositions = []
  // find childrenXPositions
  for (let i = 0; i < node.children.length; i++) {
    const nodeChild = node.children[i]
    if (nodeChild.orgChartPosition.x !== null) {
      childrenXPositions.push(nodeChild.orgChartPosition.x)
    }
  }
  const childrenMinX = Math.min(...childrenXPositions)
  const childrenMaxX = Math.max(...childrenXPositions)
  const childrenRowWidth = childrenMaxX + nodeWidth - childrenMinX // we need to add the node width of the last x position because the x starts from the leftmost of the node
  return {
    childrenRowMaxX: childrenMaxX,
    childrenRowMinX: childrenMinX,
    childrenRowWidth: childrenRowWidth,
  }
}

// CHANGE BRANCH POSITION
const changeBranchPosition = ({root, newPosition}: {root: IDivisionBranch, newPosition: {x: number, y: number}}) => {
  if (
    root.orgChartPosition &&
    root.orgChartPosition.x !== null &&
    root.orgChartPosition.y !== null
  ) {
    const offset = {
      x: -(root.orgChartPosition.x - newPosition.x),
      y: -(root.orgChartPosition.y - newPosition.y),
    }
  
    const changeNodePosition = (node: IDivisionBranch) => {

      if (!node) {
        return;
      }

      if (
        node.orgChartPosition &&
        node.orgChartPosition.x !== null &&
        node.orgChartPosition.y !== null
      ) {
        node.orgChartPosition.x += offset.x
        node.orgChartPosition.y += offset.y
      }

      for(let i = 0; i < node.children.length; i++) {
        changeNodePosition(node.children[i])
      }
    }
  
    changeNodePosition(root)
  }
}

// GET ORG CHART WIDTH AND HEIGHT -----------------------------------------------------------------------------------------------------------------------------------
export const getOrgChartWidthAndHeight = ({
  branch,
  verticalSpacing,
  nodeWidth,
  filters,
  ctx,
}: {
  branch: IDivisionBranch,
  verticalSpacing: number,
  nodeWidth: number,
  filters: IOrgChartFilterOptions,
  ctx: CanvasRenderingContext2D
}) => {
  const { branchMaxX, branchMinX, branchMaxY, branchMinY } = getBranchMinMaxPositions({node: branch, xPositions: [], yPositions: []})
  const bottomNodes: IDivisionBranch[] = []

  const findNodesByYPosition = ({node, y, bottomNodes}: {node: IDivisionBranch, y: number, bottomNodes: IDivisionBranch[]}) => {
    if (
      node.orgChartPosition &&
      node.orgChartPosition.y &&
      node.orgChartPosition.y === y
    ) {
      bottomNodes.push(node)
    }

    for (let i = 0; i < node.children.length; i++) {
      findNodesByYPosition({node: node.children[i], y, bottomNodes})
    }
  }

  findNodesByYPosition({node: branch, y: branchMaxY, bottomNodes})

  const bottomHeights = []
  // find the highest height of these nodes
  for (let i = 0; i < bottomNodes.length; i++) {
    const nodeHeight = getNodeContainer({
      node: bottomNodes[i],
      verticalSpacing,
      nodeWidth,
      filters,
      ctx,
    }).node.height
    bottomHeights.push(nodeHeight)
  }

  const maxNodeHeight = Math.max(...bottomHeights)

  return {
    orgChartMinX: branchMinX,
    orgChartWidth: (branchMaxX + nodeWidth) - branchMinX,
    orgChartHeight: (branchMaxY + maxNodeHeight) - branchMinY,
  }
}

// SET NODES POSITIONS ----------------------------------------------------------------------------------------------------------------------------------------------
export const setNodesPositions = ({
  branch,
  rootX,
  rootY,
  xFromStartDrawing,
  yFromStartDrawing,
  siblingSpacing,
  nodeWidth,
  verticalSpacing,
  filters,
  ctx,
}: {
  branch: IDivisionBranch,
  rootX: number,
  rootY: number,
  xFromStartDrawing: number,
  yFromStartDrawing: number,
  siblingSpacing: number,
  nodeWidth: number,
  verticalSpacing: number,
  filters: IOrgChartFilterOptions,
  ctx: CanvasRenderingContext2D,
}) => {

  let xFromStartDrawingToRightmostDrawn = xFromStartDrawing
  let firstChildStartX = xFromStartDrawing

  // GET AND SET PARENTS' POSITIONS BASED ON CHILDREN
  const setParentsPositionsBasedOnChildren = ({
    node,
    nodeIndex,
  }: {
    node: IDivisionBranch,
    nodeIndex: number,
  }) => {
    // Base case: If the node is null, return
    if (!node) {
      return;
    }

    // Recursively traverse each child from left to right
    for (let i = 0; i < node.children.length; i++) {
      setParentsPositionsBasedOnChildren({node: node.children[i], nodeIndex: i});
    }

    // PROCESS THE CURRENT NODE
    node.orgChartPosition = {x: null, y: null}
    // if the node has children, that means it is a parent
    if (node.children.length > 0) {
      // when the node is a parent
      const { branchMaxX, branchMinY } = getBranchMinMaxPositions({node, xPositions: [], yPositions: []})
      const rightmostDrawnX = branchMaxX + nodeWidth // we need to add the node width of the last x position because the x starts from the leftmost of the node
      const {childrenRowMinX, childrenRowWidth} = getChildrenRowInfos({node, nodeWidth})
      // put the parent on the top center of children
      // (in the center of the row length of children)
      const parentNodeHeight = getNodeContainer({
        node,
        verticalSpacing,
        nodeWidth,
        filters,
        ctx
      }).node.height;
      node.orgChartPosition.x = childrenRowMinX + childrenRowWidth / 2 - nodeWidth / 2
      node.orgChartPosition.y = branchMinY - parentNodeHeight - verticalSpacing // set the position to the top of the children
      firstChildStartX = rightmostDrawnX
    } else {
      // when the node is not a parent
      const spacing = nodeIndex === 0 ? 0 : siblingSpacing
      const spacingLeftOnStartChildrenRow = nodeIndex === 0 ? siblingSpacing : 0
      const childrenRowWidth = xFromStartDrawingToRightmostDrawn + spacingLeftOnStartChildrenRow + nodeWidth - firstChildStartX + spacing // width with current node included
      const xPosition = firstChildStartX + (childrenRowWidth - nodeWidth) // extract the width of the current node
      node.orgChartPosition.x = xPosition
      node.orgChartPosition.y = yFromStartDrawing
      xFromStartDrawingToRightmostDrawn = xPosition + nodeWidth
    }
  }

  // SET THE LEVEL POSITIONS
  const setLevelPositions = ({node, parentNode}: {node: IDivisionBranch, parentNode: IDivisionBranch | null}) => {

    if (!node) {
      return;
    }

    if (parentNode) {
      // reset the y of each child to be the same as the parent y
      node.orgChartPosition.y = parentNode.orgChartPosition.y;

      // add height to the y of the node based on the level and parent height
      const parentNodeHeight = getNodeContainer({
        node: parentNode,
        verticalSpacing,
        nodeWidth,
        filters,
        ctx,
      }).node.height;

      if (node.orgChartPosition.y !== null) {
        node.orgChartPosition.y += parentNodeHeight + verticalSpacing;
      }
    }

    for (let i = 0; i < node.children.length; i++) {
      setLevelPositions({node: node.children[i], parentNode: node})
    }
  }

  // SET POSITIONS STARTING FROM THE ROOT
  // step 1
  setParentsPositionsBasedOnChildren({node: branch, nodeIndex: 0});
  // step 2
  setLevelPositions({node: branch, parentNode: null});
  // step 3 (add custom positions)
  // for (const [key, ref] of Object.entries(CUSTOM_POSITION_DIVISION_REFS)) {
  //   const node = findNodeByRef({branch, ref})
  //   if (node) {
  //     const parentNode = getParentNode({root: branch, childNode: node})
  //     if (parentNode) {
  //       const customPosition = getCustomPosition({node, parentNode, verticalSpacing, siblingSpacing, nodeWidth, filters, ctx})
  //       if (customPosition) {
  //         changeBranchPosition({
  //           root: node,
  //           newPosition: {
  //             x: customPosition.x,
  //             y: customPosition.y,
  //           }
  //         })
  //       }
  //     }
  //   }
  // }
  changeBranchPosition({
    root: branch,
    newPosition: {
      x: rootX,
      y: rootY,
    }
  })
}

// GET SHORTENED BRANCH ---------------------------------------------------------------------------------------------------------------------------------------------
export const getDirectionShortBranch = (branch: IDivisionBranch) => {
  const responseBranch = { ...branch };

  // remove the children of the direct children of the root
  if (responseBranch && responseBranch.children) {
    for (let i = 0; i < responseBranch.children.length; i++) {
      const child = responseBranch.children[i];
      child.children = [];
    }
  }

  return responseBranch;
};