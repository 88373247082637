import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import { MdCloudUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import * as xlsx from "xlsx";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues } from "../../../interfaces/agent";
import { useAgentsStatus } from "../../../common/contexts/agentStatusContext";
import { useFormations } from "../../../common/contexts/formationContext";
import { useCategories } from "../../../common/contexts/categoryContext";
import { useBodiesGrades } from "../../../common/contexts/bodyGradeContext";
import { useTeleworking } from "../../../common/contexts/teleworkingContext";
import { useDegrees } from "../../../common/contexts/degreeContext";
import { useReferensJobs } from "../../../common/contexts/referensJobContext";
import { IReferensJobInfos } from "../../../interfaces/referensJob";
import { useEmployments } from "../../../common/contexts/employmentContext";
import { IDivisionInfos } from "../../../interfaces/division";
import { useDivisions } from "../../../common/contexts/divisionContext";

const ImportAgentForm = ({
  title,
  formData,
  actionString,
  onAgentImported,
}: {
  title: string;
  formData?: IAgentFormValues;
  actionString: string;
  onAgentImported?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialAgentFormState = {
    firstName: null,
    lastName: null,
    sex: null,
    birthDate: null,
    registrationNumber: null,
    maritalStatus: null,
    professionalPhoneNumber: null,
    observationsDifficultJobs: null,
    observationsParticularSituations: null,
    email: null,
    bonusVacation: null,
    departureDate: null,
    departureReason: null,
  };

  const { onGetBodyGrade } = useBodiesGrades();

  const { onCreateTeleworking } = useTeleworking();

  const { onCreateDegree } = useDegrees();

  const { onGetDivisionFromAcronym, onGetDivisionFromReference } =
    useDivisions();

  const {
    onCheckRegistrationNumberOfAgent,
    onUpdateAgent,
    onUpdateAgentDivision,
  } = useAgents();

  // on drop
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    // console.log(acceptedFiles[0]);

    const reader = new FileReader();
    reader.readAsBinaryString(acceptedFiles[0]);
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "binary", cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      let parsedData = xlsx.utils.sheet_to_json(sheet);
      _setData(parsedData);
      // organize xlsx data into desired format

      // BIRTH DAY
      parsedData = parsedData.map((parse: any) => {
        const newDate = new Date(parse["date naissance"]);
        parse.birthDate = new Date(newDate.setDate(newDate.getDate() + 1));
        return parse;
      });

      // STATUS START DATE IF EXISTS
      parsedData = parsedData.map((parse: any) => {
        if (parse.statusStartDate !== undefined) {
          const newStartDate = new Date(parse.statusStartDate);
          parse.statusStartDate = new Date(
            newStartDate.setDate(newStartDate.getDate() + 1)
          );
        }
        return parse;
      });

      // STATUS END DATE IF EXISTS
      parsedData = parsedData.map((parse: any) => {
        if (parse.statusEndDate !== undefined) {
          const newEndDate = new Date(parse.statusEndDate);
          parse.statusEndDate = new Date(
            newEndDate.setDate(newEndDate.getDate() + 1)
          );
        }
        return parse;
      });

      // console.log("FINAL PARSED DATA ", parsedData.length);
    };
    // reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { onCreateAgent } = useAgents();
  const { onCreateAgentStatus } = useAgentsStatus();
  const { onCreateFormation } = useFormations();
  const { onCreateCategory } = useCategories();

  const { onGetReferensJobFromCode } = useReferensJobs();
  const { onCreateEmployment } = useEmployments();

  const [agentFormValues, _setAgentFormValues] = useState<IAgentFormValues>(
    initialAgentFormState
  );

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (formData) {
      _setAgentFormValues(formData);
      // _setIsEditMode(true);
    }
  }, []);

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (data && data.length !== 0) {
      data.map(async (d: any) => {
        console.log("D : ", d);

        // CHECK IF MATRICULE EXISTS
        if ((await onCheckRegistrationNumberOfAgent(d["dossier"])) !== -1) {
          // IF YES UPDATE
          onCheckRegistrationNumberOfAgent(d["dossier"]).then(
            (agentId: any) => {
              onUpdateAgent({
                id: agentId,
                // firstName: d.prenom,
                firstName: d["Prénom"],
                // lastName: d.nom,
                lastName: d["Nom usuel"],
                sex: d["genre"],
                // birthDate: d.birthDate,
                birthDate: d["Date de naissance"],
                registrationNumber: d["Dossier"] ? d["Dossier"].toString() : "",
                maritalStatus: null,
                professionalPhoneNumber: d["telephone pro"]
                  ? d["telephone pro"].toString()
                  : "",
                // observationsDifficultJobs: d.observations,
                observationsDifficultJobs: d["Observations"],
                observationsParticularSituations: d["Situation particulière"],
                email: d["email"],
                bonusVacation: null,
                departureDate: null,
                departureReason: null,
              })
                .then(() => {
                  // console.log("AGENT ", agentId, " MIS A JOUR");
                })
                .catch((err) => {
                  _setErrorMessage(err);
                });
            }
          );
        } else {
          // IF NOT CREATE
          onCreateAgent({
            // firstName: d.prenom,
            firstName: d["Prénom"],
            // lastName: d.nom,
            lastName: d["Nom usuel"],
            sex: d["genre"],
            // birthDate: d.birthDate,
            birthDate: d["Date de naissance"],
            registrationNumber: d["Dossier"] ? d["Dossier"].toString() : "",
            professionalPhoneNumber: d["telephone pro"]
              ? d["telephone pro"].toString()
              : "",
            // observationsDifficultJobs: d.observations,
            observationsDifficultJobs: d["Observations"],
            observationsParticularSituations: d["Situation particulière"],
            email: d["email"],
            bonusVacation: null,
            departureDate: null,
            departureReason: null,
          })
            .then((response: any) => {
              // console.log("CREATED ", response);

              // CHECK SERVICE DLM
              if (d["Pôle/Equipe/Cellule"]) {
                if (d["Pôle/Equipe/Cellule"] === "Responsable du service") {
                  // ON CHOPE L'ID DU SERVICE
                  onGetDivisionFromAcronym(d["dlm"]).then(
                    (division: IDivisionInfos) => {
                      console.log('division', division)
                      // UPDATE AGENT
                      onUpdateAgentDivision({
                        id: response.id,
                        divisionId: division.id,
                      })
                        .then((res: any) => {})
                        .catch((err: any) => {
                          console.log(err);
                        });
                    }
                  );
                } else {
                  // console.log("NON IL APPARTIENT A ", d["Pôle/Equipe/Cellule"]);
                  onGetDivisionFromAcronym(d["dlm"]).then(
                    async (divisionAcronym: IDivisionInfos) => {
                      // console.log(divisionAcronym.refOrgChart?.split("."));
                      const ref = (divisionAcronym.refOrgChart || '').split(".");

                      let finalReference = "";

                      if (ref) {
                        finalReference =
                          ref[0].toString() + "." + ref[1].toString();
                      }

                      // console.log("\n\n Final reference : ", finalReference);

                      await onGetDivisionFromReference(
                        finalReference,
                        d["Pôle/Equipe/Cellule"].toLowerCase()
                      ).then((divisionReference: IDivisionInfos) => {
                        // console.log(
                        //   "\n\nDivision reference : ",
                        //   divisionReference
                        // );

                        if (divisionReference) {
                          // UPDATE AGENT
                          onUpdateAgentDivision({
                            id: response.id,
                            divisionId: divisionReference.id,
                          })
                            .then((res: any) => {})
                            .catch((err: any) => {
                              console.log(err);
                            });
                        }
                      });
                    }
                  );
                }
              }

              // CHECK IF EMPLOIS TYPE POSTE OCCUPE
              if (d["Emploi type poste occupé"]) {
                onGetReferensJobFromCode(
                  d["Emploi type poste occupé"].split("-")[0].trim()
                ).then((referensJob: IReferensJobInfos) => {
                  // console.log("REFERENS JOB ", referensJob);
                  onCreateEmployment({
                    name: referensJob.jobTitle || '',
                    code: referensJob.codeJob,
                    family: referensJob.professionalFamily,
                    agentId: response.id,
                    subFamily: referensJob.businessFamilyCode,
                    startDate: d["date depart statut"],
                    endDate: null,
                    referensJobId: referensJob.id,
                  }).then((response: any) => {
                    // console.log(response);
                  });
                });

                // GET VALUES FROM JOB CODE
              }

              // CHECK IF STATUS AND ADD IF YES
              if (d.statut) {
                onCreateAgentStatus({
                  type: d.statut,
                  startDate: d["date depart statut"],
                  endDate: d["date fin statut"],
                  subtype: d["type statut"],
                  agentId: response.id,
                }).then((response: any) => {
                  // console.log(response);
                });
              }

              // CHECK NIVEAU D'ÉTUDES AND ADD IF YES
              if (d["niveau etudes"]) {
                // console.log("NIVEAU ETUDE ", d["niveau etudes"]);
                onCreateDegree({
                  name: d["niveau etudes"],
                  year: null,
                  grade: "",
                  agentId: response.id,
                  comments: "",
                })
                  .then((response: any) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }

              // CHECK IF FORMATION TA AND ADD IF YES
              if (d["INFOS obtention TA"]) {
                let finalYear = d["INFOS obtention TA"].split(" ")[1];
                onCreateFormation({
                  name: "TA",
                  year: Number(finalYear),
                  agentId: response.id,
                  comment: "",
                }).then((response) => {
                  // console.log(response);
                });
              }
              // CHECK IF FORMATION LA AND ADD IF YES
              if (d["INFOS obtention LA"]) {
                let finalYear = d["INFOS obtention LA"].split(" ")[1];
                onCreateFormation({
                  name: "LA",
                  year: Number(finalYear),
                  agentId: response.id,
                  comment: "",
                }).then((response) => {
                  // console.log(response);
                });
              }

              // CHECK IF CATEGORY AND ADD IF YES
              if (d.categorie) {
                let finalCategorieId = 0;
                switch (d.categorie) {
                  case "IGR HC":
                    finalCategorieId = 1;
                    break;
                  case "IGR":
                    finalCategorieId = 2;
                    break;
                  case "IGE HC":
                    finalCategorieId = 3;
                    break;
                  case "IGE CN":
                    finalCategorieId = 4;
                    break;
                  case "ASI":
                    finalCategorieId = 5;
                    break;
                  case "TECH CE":
                    finalCategorieId = 6;
                    break;
                  case "TECH CS":
                    finalCategorieId = 7;
                    break;
                  case "TECH CN":
                    finalCategorieId = 8;
                    break;
                  case "ATRF 1C":
                    finalCategorieId = 9;
                    break;
                  case "ATRF 2C":
                    finalCategorieId = 10;
                    break;
                  case "ATRF":
                    finalCategorieId = 11;
                    break;
                }
                onGetBodyGrade(finalCategorieId).then((res) => {
                  onCreateCategory({
                    bodygradeId: res.id,
                    type: res.categoryType,
                    sigleBody: res.sigleBody,
                    body: res.body,
                    sigleGrade: res.sigleGrade,
                    grade: res.grade,
                    agentId: response.id,
                  }).then((response) => {
                    // console.log(response);
                  });
                });
              }

              // IMPORT TT
              // CHECK IF tt AND ADD IF YES
              if (d.TT && d.TT === "oui") {
                let day0Checked,
                  day1Checked,
                  day2Checked,
                  day3Checked,
                  day4Checked,
                  day5Checked,
                  day6Checked = false;

                if (d["jours TT"]) {
                  const days = d["jours TT"].split(",");
                  for (let i = 0; i < days.length; i++) {
                    switch (days[i]) {
                      case "lundi":
                        day0Checked = true;
                        break;
                      case "mardi":
                        day1Checked = true;
                        break;
                      case "mercredi":
                        day2Checked = true;
                        break;
                      case "jeudi":
                        day3Checked = true;
                        break;
                      case "vendredi":
                        day4Checked = true;
                        break;
                      case "samedi":
                        day5Checked = true;
                        break;
                      case "dimanche":
                        day6Checked = true;
                        break;
                    }
                  }
                }

                onCreateTeleworking({
                  agentId: response.id,
                  days: [
                    day0Checked,
                    day1Checked,
                    day2Checked,
                    day3Checked,
                    day4Checked,
                    day5Checked,
                    day6Checked,
                  ],
                }).then((response: any) => {
                  // console.log(response);
                });
              }

              if (onAgentImported) {
                // IF IMPORTED
                onAgentImported();
              }
            })
            .catch((err) => {
              _setErrorMessage(err);
            });
        }
      });
    }
  };

  const [data, _setData] = useState<any[] | null>([]);

  const [displayDataLength, _setDisplayDataLength] = useState<boolean>(false);

  useEffect(() => {
    // console.log(data?.length);
    if (data) _setDisplayDataLength(data?.length > 0);
  }, [data]);

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* type file */}
              <div className="input-import" {...getRootProps()}>
                {/* <div className="input-import"> */}
                <input
                  {...getInputProps()}
                  type="file"
                  name="importFile"
                  id="importFile"
                  className="inputfile"
                  accept=".xlsx"
                  // onChange={handleFileUpload}
                />
                <label htmlFor="importFile">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MdCloudUpload size={32} />
                  </div>
                  {displayDataLength && (
                    <p style={{ marginTop: 16, textAlign: "center" }}>
                      {data?.length} agent
                      {data && data.length > 1 ? "s" : ""} à importer de Pollux
                    </p>
                  )}
                  {data?.length === 0 && <p>Importer le fichier des agents</p>}
                </label>
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={actionString}
                  onClick={handleSubmit}
                  disabled={data?.length !== 0 ? false : true}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default ImportAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4em;

    .input-import {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .inputfile + label {
      font-size: 1em;
      font-weight: 700;
      color: white;
      background-color: ${COLOR_RED_BRIGHT};
      padding: 48px 64px;
      display: inline-block;
      border-radius: 8px;
      border: 1px solid ${COLOR_RED_BRIGHT};
      transition: 250ms;
      outline: none;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
      background-color: white;
      color: ${COLOR_RED_BRIGHT};
      margin: auto;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label {
      cursor: pointer; /* "hand" cursor */
    }

    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueFormations = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const StatusCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
