import React, { useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import styled from "styled-components";
import { INotificationInfos } from "../../interfaces/notification";
import { COLOR_TEXT, COLOR_WHITE } from "../../constants/cts_colors";
import { useNotifications } from "../../common/contexts/notificationContext";
import { NavLink } from "react-router-dom";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";

const Notifications = ({notifications}: {notifications: INotificationInfos[]}) => {

  return (
    <BodyContainer title="Notifications">
      {/* MAIN CONTAINER */}
      <MainContainer>
        <NotificationsStyles>
          {
            notifications.length > 0 ? (
              notifications.map((notification) => 
                <StyledNavLink
                to={getPageUrl(`${PATH.notification}/${notification.id}`)}
                  className={notification.isActive ? 'active' : ''}
                  key={'notification-' + notification.id}
                  >
                    {notification.text.length > 100 ? notification.text.substring(0, 97) + '...' : notification.text}
                  </StyledNavLink>)
            ) : (
              <NotFoundData>Aucune notification trouvée</NotFoundData>
            )
          }
        </NotificationsStyles>
      </MainContainer>
    </BodyContainer>
  );
}

export default Notifications;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

// MAIN CONTAINER STYLES
const MainContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const NotificationsStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const StyledNavLink = styled(NavLink)`
  width: 100%;
  text-decoration: none;
  background-color: ${COLOR_WHITE};
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  text-align: start;
  cursor: pointer;
  transition: 0.3s background-color ease;
  color: ${COLOR_TEXT};

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.active {
    font-weight: bold;
  }
`

const NotFoundData = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 12px 24px;
  text-align: center;
  color: ${COLOR_TEXT};
`