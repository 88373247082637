import { IAgentInactivityFormValues } from "../interfaces/agentInactivity";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateAgentInactivityApi = async ({
  title,
  agentId,
  startDate,
  endDate,
}: IAgentInactivityFormValues) => {
  return instanceAxios
    .post("/agents-inactivities/create-agent-inactivity", {
      title,
      agentId,
      startDate,
      endDate,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllAgentsInactivitiesApi = async () => {
  return instanceAxios
    .get(`/agents-inactivities/get-all-agents-inactivities`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT
export const onGetAllAgentsInactivitiesFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/agents-inactivities/get-all-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetAgentInactivityApi = async (id: number) => {
  return instanceAxios
    .get(`/agents-inactivities/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteAgentInactivityApi = async (id: number) => {
  return instanceAxios
    .delete(`/agents-inactivities/delete-agent-inactivity/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateAgentInactivityApi = async ({
  id,
  title,
  agentId,
  startDate,
  endDate,
}: IAgentInactivityFormValues) => {
  // console.log("ID : ", id);
  return instanceAxios
    .put("/agents-inactivities/update-agent-inactivity/" + id, {
      title,
      agentId,
      startDate,
      endDate,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
