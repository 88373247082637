import { IEmploymentFormValues } from "../interfaces/employment";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateEmploymentApi = async ({
  name,
  code,
  family,
  subFamily,
  agentId,
  startDate,
  endDate,
  referensJobId,
}: IEmploymentFormValues) => {
  return instanceAxios
    .post("/employments/create-employment", {
      name,
      code,
      family,
      subFamily,
      agentId,
      startDate,
      endDate,
      referensJobId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllEmploymentsApi = async () => {
  return instanceAxios
    .get(`/employments/get-all-employments`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT
export const onGetAllEmploymentsFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/employments/get-all-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetEmploymentApi = async (id: number) => {
  return instanceAxios
    .get(`/employments/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteEmploymentApi = async (id: number) => {
  return instanceAxios
    .delete(`/employments/delete-employment/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateEmploymentApi = async ({
  id,
  name,
  code,
  family,
  subFamily,
  agentId,
  startDate,
  endDate,
  referensJobId,
}: IEmploymentFormValues) => {
  return instanceAxios
    .put("/employments/update-employment/" + id, {
      name,
      code,
      family,
      subFamily,
      agentId,
      startDate,
      endDate,
      referensJobId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
