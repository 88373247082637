import React, { useState } from "react";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";
import {
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_PLACEHOLDER,
  COLOR_LINK,
  COLOR_LINK_HOVER,
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_ERROR_MESSAGE,
} from "../../constants/cts_colors";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineKey } from "react-icons/hi";
import AuthPagesBase from "../../components/authPages/Base";

const LoginPage = () => {
  const initialFormState = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialFormState);
  const { onLogin } = useAuthentication();
  const [redirectToHome, _setRedirectToHome] = useState(false);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    _setErrorMessage(null);
    e.preventDefault();
    onLogin({
      email: formValues.email,
      password: formValues.password,
    })
      .then(() => _setRedirectToHome(true))
      .catch((error: any) => {
        if (error.message) {
          _setErrorMessage(error.message);
        } else if (error) {
          _setErrorMessage(error);
        }
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  // after user connected, redirect to home
  if (redirectToHome) {
    return <Navigate to={getPageUrl(PATH.home)} />;
  }

  return (
    <AuthPagesBase title="Bienvenue dans l'espace administrateur">
      <Form onSubmit={handleSubmit}>
        {errorMessage && (
          <ErrorMessageStyles>{errorMessage}</ErrorMessageStyles>
        )}
        <div className="inputs-container">
          {/* email */}
          <div className="input-line">
            <AiOutlineUser className="icon" />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Votre e-mail"
              required
              onChange={handleChange}
            />
          </div>
          {/* password */}
          <div className="input-line">
            <HiOutlineKey className="icon" />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Votre mot de passe"
              minLength={6}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        {/* lost password link */}
        <Links>
          <Link className="link" to={getPageUrl(PATH.lost_pwd)}>
            Mot de passe oublié
          </Link>
          <Link className="link" to={getPageUrl(PATH.cgv)}>
            Conditions Générales de Vente
          </Link>
        </Links>
        {/* submit btn */}
        <button className="submit-btn">Connexion</button>
      </Form>
    </AuthPagesBase>
  );
};

export default LoginPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ErrorMessageStyles = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${COLOR_ERROR_MESSAGE};

  // ========= MEDIA QUERIES - ErrorMessageStyles ============
  @media (max-width: 800px) {
    font-size: 2.5vw;
  }
`;

const Form = styled.form`
  margin-top: 20px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;

  // ========= MEDIA QUERIES - Form ============
  @media (max-width: 800px) {
    width: 100%;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .input-line {
      position: relative;
      display: flex;

      .icon {
        flex-basis: 0px;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        width: 15px;
      }

      input {
        flex-basis: 100%;
        color: ${COLOR_TEXT};
        font-size: 1.1rem;
        padding: 5px;
        padding-left: 22px;
        text-align: center;

        // ========= MEDIA QUERIES - Form / .inputs-container / .input-line / input ============
        @media (max-width: 800px) {
          font-size: 3vw;
        }

        &::placeholder {
          color: ${COLOR_PLACEHOLDER};
        }
      }
    }
  }

  .submit-btn {
    display: block;
    margin-left: auto;
    margin-top: 10px;
    background-color: ${COLOR_BTN};
    width: 50%;
    font-size: 1.1rem;
    padding: 10px 5px;
    border: none;
    border-radius: 5px;
    color: ${COLOR_WHITE};
    cursor: pointer;

    // ========= MEDIA QUERIES - Form / .submit-btn ============
    @media (max-width: 800px) {
      font-size: 3vw;
    }

    &:hover {
      background-color: ${COLOR_BTN_HOVER};
    }
  }
`;

const Links = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .link {
    display: block;
    width: max-content;
    color: ${COLOR_LINK};
    text-decoration: none;

    @media (max-width: 800px) {
      font-size: 2.5vw;
    }

    &:hover {
      color: ${COLOR_LINK_HOVER};
    }
  }
`
