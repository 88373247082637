import React, { useState, useCallback, createContext } from "react";
import { onReadFileApi } from "../../api/file.api";
import { IFileFormValues, IFilesContext } from "../../interfaces/file";

const FilesContext = createContext(null);

export function FilesProvider(props: any) {
  const [isLoadingFiles, _setIsLoadingFiles] = useState(false);

  // READ FILE
  const onReadFile = useCallback((name: string) => {
    _setIsLoadingFiles(true);
    return onReadFileApi(name)
      .then((response) => {
        _setIsLoadingFiles(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingFiles(false);
        throw error;
      });
  }, []);

  return (
    <FilesContext.Provider
      {...props}
      value={{
        isLoadingFiles,
        // function
        onReadFile,
      }}
    />
  );
}

export const useFiles = (): IFilesContext => {
  const context = React.useContext(FilesContext);
  if (!context) throw new Error("useFiles must be used in FilesProvider");

  return context;
};
