import React from "react";
import styled from "styled-components";
import { COLOR_FORM_LABEL } from "../../constants/cts_colors";

const FormLabel = (props: any) => {
  const { text } = props;

  return <Label {...props}>{text}</Label>
}

export default FormLabel;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: 1rem;
  color: ${COLOR_FORM_LABEL};
  margin-bottom: 5px;

  @media (max-width: 800px) {
    font-size: 2.5vw;
  }
`