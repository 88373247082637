import React, { useState, useCallback, createContext } from "react";
import { EMPTY_AGENTID, EMPTY_FILEPATH } from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  IAgentFileFormValues,
  IAgentsFilesContext,
} from "../../interfaces/agentFile";
import {
  onCreateAgentFileApi,
  onDeleteAgentFileApi,
  onGetAgentFilesApi,
  onGetAllAgentsFilesApi,
  onUpdateAgentFileApi,
} from "../../api/agentFiles.api";

const AgentsFilesContext = createContext(null);

export function AgentsFilesProvider(props: any) {
  const [isLoadingAgents, _setIsLoadingAgents] = useState(false);

  // CREATE
  const onCreateAgentFile = useCallback(
    async ({ agentId, filePath }: IAgentFileFormValues) => {
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_AGENTID);
      }

      if (!filePath) {
        return Promise.reject(EMPTY_FILEPATH);
      }

      _setIsLoadingAgents(true);
      return onCreateAgentFileApi({
        agentId,
        filePath,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllAgentsFiles = useCallback(() => {
    _setIsLoadingAgents(true);
    return onGetAllAgentsFilesApi()
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetAgentFiles = useCallback((id: number) => {
    _setIsLoadingAgents(true);
    return onGetAgentFilesApi(id)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateAgentFile = useCallback(
    async ({ id, agentId, filePath }: IAgentFileFormValues) => {
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_AGENTID);
      }
      if (!filePath) {
        return Promise.reject(EMPTY_FILEPATH);
      }

      _setIsLoadingAgents(true);
      return onUpdateAgentFileApi({
        id,
        agentId,
        filePath,
      })
        .then((response) => {
          _setIsLoadingAgents(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingAgents(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteAgentFile = useCallback((name: string) => {
    _setIsLoadingAgents(true);
    return onDeleteAgentFileApi(name)
      .then((response) => {
        _setIsLoadingAgents(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingAgents(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <AgentsFilesContext.Provider
      {...props}
      value={{
        isLoadingAgents,
        // function
        onCreateAgentFile,
        onGetAllAgentsFiles,
        onGetAgentFiles,
        onUpdateAgentFile,
        onDeleteAgentFile,
      }}
    />
  );
}

export const useAgentsFiles = (): IAgentsFilesContext => {
  const context = React.useContext(AgentsFilesContext);
  if (!context)
    throw new Error("useAgentsFiles must be used in AgentsFilesProvider");

  return context;
};
