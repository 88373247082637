import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import { useDivisions } from "../../../common/contexts/divisionContext";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import { FaPen } from "react-icons/fa";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import { IDivisionInfos } from "../../../interfaces/division";

const EditDivisionForm = (
  {
    title,
    formData,
    actionString,
    onDivisionUpdated
  }: {
    title: string,
    formData: IDivisionInfos,
    actionString: string,
    onDivisionUpdated: Function
  }
) => {
  const [ formValues, _setFormValues ] = useState<IDivisionInfos>(formData);
  const [ errorMessage, _setErrorMessage ] = useState<string | null>(null);
  const { onUpdateDivision } = useDivisions();

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    onUpdateDivision({
      ...formValues,
    })
      .then(() => {
        onDivisionUpdated()
      })
      .catch((err) => {
        _setErrorMessage(err)
      })
  }

  // on change
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    })
  }

  return (
    <FormBase
      title={title}
    >
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className='inputs-container'>

              {/* name */}
              <div>
                <FormLabel htmlFor="name" text="Nom Service" />
                <FormInputContainer
                  icon={<FaPen />}
                  input={
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Nom Service"
                      onChange={handleChange}
                      value={formValues.name ? formValues.name : ""} />
                  }
                />
              </div>

              {/* acronym */}
              <div>
                <FormLabel htmlFor="acronym" text="Acronyme Service" />
                <FormInputContainer
                  icon={<FaPen />}
                  input={
                    <input
                      type="text"
                      id="acronym"
                      name="acronym"
                      placeholder="Acronyme Service"
                      onChange={handleChange}
                      value={formValues.acronym ? formValues.acronym : ""} />
                  }
                />
              </div>

              {/* form errors */}
              {
              errorMessage &&
              <FormErrorMessageContainer errorMessage={errorMessage} />
              }

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={actionString}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  )
}

export default EditDivisionForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`