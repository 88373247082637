import React, { useState, createContext, useCallback } from "react";
import { onGetAllDepartmentsApi, onGetAllDepartmentsByServiceApi } from "../../api/department.api";
import { IDepartmentsContext } from "../../interfaces/department";

const DepartmentsContext = createContext(null);

export function DepartmentsProvider(props : any) {
  const [ isLoadingDepartments, _setIsLoadingDepartments ] = useState(false);

  // GET ALL DEPARTMENTS
  const onGetAllDepartments = useCallback(async () => {
    _setIsLoadingDepartments(true);
    return onGetAllDepartmentsApi()
    .then((response) => {
      _setIsLoadingDepartments(false)
      return (response)
    })
    .catch((error) => {
      _setIsLoadingDepartments(false)
      throw error
    })
  }, []);

  // GET ALL DEPARTMENTS BY SERVICE
  const onGetAllDepartmentsByService = useCallback(async ({ serviceId }: { serviceId: number }) => {
    _setIsLoadingDepartments(true);
    return onGetAllDepartmentsByServiceApi({ serviceId })
    .then((response) => {
      _setIsLoadingDepartments(false)
      return (response)
    })
    .catch((error) => {
      _setIsLoadingDepartments(false)
      throw error
    })
  }, []);

  return (
    <DepartmentsContext.Provider
      {...props}
      value={{
        isLoadingDepartments,
        // functions
        onGetAllDepartments,
        onGetAllDepartmentsByService,
      }}
    />
  );
}

export const useDepartments = () : IDepartmentsContext => {
  const context = React.useContext(DepartmentsContext);
  if (!context)
    throw new Error(
      "useDepartments must be used in DepartmentsProvider"
    );

  return context;
};