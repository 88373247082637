import { number } from "prop-types";
import { IDegreeFormValues } from "../interfaces/degree";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateDegreeApi = async ({
  name,
  year,
  grade,
  agentId,
  comments,
}: IDegreeFormValues) => {
  return instanceAxios
    .post("/degrees/create-degree", {
      name,
      year,
      grade,
      agentId,
      comments,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllDegreesApi = async () => {
  return instanceAxios
    .get(`/degrees/get-all-degree`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL FROM AGENT
export const onGetAllDegreesFromAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/degrees/get-all-from-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetDegreeApi = async (id: number) => {
  return instanceAxios
    .get(`/degrees/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteDegreeApi = async (id: number) => {
  return instanceAxios
    .delete(`/degrees/delete-degree/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateDegreeApi = async ({
  id,
  name,
  year,
  grade,
  agentId,
  comments,
}: IDegreeFormValues) => {
  return instanceAxios
    .put("/degrees/update-degree/" + id, {
      name,
      year,
      grade,
      agentId,
      comments,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
