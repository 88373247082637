import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import { MdCloudUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import * as xlsx from "xlsx";
import { IReferensJobFormValues } from "../../../interfaces/referensJob";
import { useReferensJobs } from "../../../common/contexts/referensJobContext";

const ImportEmploymentForm = ({
  title,
  formData,
  actionString,
  onEmploymentImported,
}: {
  title: string;
  formData?: IReferensJobFormValues;
  actionString: string;
  onEmploymentImported?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialReferensJobFormState = {
    branchCode: null,
    branch: null,
    professionalFamily: null,
    reme: null,
    idStatutoryCorrespondence: null,
    codeStatutoryCorrespondence: null,
    statutoryCorrespondence: null,
    desiredTraining: null,
    jobs: null,
    mission: null,
    fapReme: null,
    mainActivities: null,
    specialExerciseConditions: null,
    knowledge: null,
    operationalSkills: null,
    behavioralSkills: null,
    statutoryDiplomaRequired: null,
    mediumtermDevelopmentFactors: null,
    impactOnJob: null,
    codeJob: null,
    jobTitle: null,
    previousCodeJob: null,
    previousJobTitle: null,
    tricarto: null,
    businessFamilyCode: null,
    desiredExperience: null,
    trifap: null,
    categoryReferens: null,
    referensSituationType: null,
    referensSituation: null,
    isExternalCompetition: null,
    externalCompetitionProgram: null,
    externalCompetitionProgramNormal: null,
    externalCompetitionProgramSuperior: null,
    pdfFile: null,
    referensKnowledgeDefinition: null,
    referensOperationalSkillsDefinition: null,
    referensBehavioralSkillsDefinition: null,
    idSkills: null,
    bap: null,
    fap: null,
    description: null,
  };

  // on drop
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    // console.log(acceptedFiles[0]);

    const reader = new FileReader();
    reader.readAsBinaryString(acceptedFiles[0]);
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "binary", cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      let parsedData = xlsx.utils.sheet_to_json(sheet);
      _setData(parsedData);
      // organize xlsx data into desired format

      // BIRTH DAY
      parsedData = parsedData.map((parse: any) => {
        const newDate = new Date(parse["date naissance"]);
        parse.birthDate = new Date(newDate.setDate(newDate.getDate() + 1));
        return parse;
      });

      // STATUS START DATE IF EXISTS
      parsedData = parsedData.map((parse: any) => {
        if (parse.statusStartDate !== undefined) {
          const newStartDate = new Date(parse.statusStartDate);
          parse.statusStartDate = new Date(
            newStartDate.setDate(newStartDate.getDate() + 1)
          );
        }
        return parse;
      });

      // STATUS END DATE IF EXISTS
      parsedData = parsedData.map((parse: any) => {
        if (parse.statusEndDate !== undefined) {
          const newEndDate = new Date(parse.statusEndDate);
          parse.statusEndDate = new Date(
            newEndDate.setDate(newEndDate.getDate() + 1)
          );
        }
        return parse;
      });

      // console.log("FINAL PARSED DATA ", parsedData.length);
    };
    // reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { onCreateReferensJob } = useReferensJobs();

  const [referensJobFormValues, _setReferensJobFormValues] =
    useState<IReferensJobFormValues>(initialReferensJobFormState);

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (formData) {
      _setReferensJobFormValues(formData);
      // _setIsEditMode(true);
    }
  }, []);

  // on delete
  const handleDelete = () => {
    console.log(handleDelete);
  };

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (data && data.length !== 0) {
      data.map((d: any) => {
        // console.log("D : ", d);

        // if it's not on edit mode, create the agent
        onCreateReferensJob({
          branchCode: d["Code de la branche d’activité professionnelle"],
          branch: d["Branche d’activité professionnelle"],
          professionalFamily: d["Famille d’activité professionnelle"],
          reme: d["Emploi type de rattachement (REME)"],
          idStatutoryCorrespondence: d["Id de la correspondance statutaire"],
          codeStatutoryCorrespondence:
            d["Code de la correspondance statutaire"],
          statutoryCorrespondence: d["Correspondance statutaire"],
          desiredTraining: d["Domaine de formation souhaité/exigé"],
          jobs: d["Métiers"],
          mission: d["Mission"],
          fapReme: d["FAP REME"],
          mainActivities: d["Activités principales"],
          specialExerciseConditions: d["Conditions particulières d’exercices"],
          knowledge: d["Connaissances"],
          operationalSkills: d["Compétences opérationnelles"],
          behavioralSkills: d["Compétences comportementales"],
          statutoryDiplomaRequired:
            d[
              "Diplôme réglementaire exigé - Formation professionnelle si souhaitable"
            ],
          mediumtermDevelopmentFactors: d["Facteurs d’évolution à moyen terme"],
          impactOnJob: d["Impacts sur l’emploi-type"],
          codeJob: d["Code emploi type"],
          jobTitle: d["Intitulé de l’emploi type"],
          previousCodeJob: d["Code du précédent emploi type"],
          previousJobTitle: d["Intitulé de l’emploi type précédent"],
          tricarto: d["TriCarto"],
          businessFamilyCode:
            d["Code de la famille d’activité professionnelle"],
          desiredExperience: d["Expérience souhaitée/exigée"],
          trifap: d["TriFAP"],
          categoryReferens: d["REFERENS_CATEGORIE_EMPLOI"],
          referensSituationType: d["Type de situation REFERENS III"],
          referensSituation: d["Situation REFERENS III"],
          isExternalCompetition:
            d["Il existe un programme de concours externe"] === "Oui"
              ? true
              : false,
          externalCompetitionProgram: d["Programme de concours externe"],
          externalCompetitionProgramNormal:
            d["Programme de concours externe (classe normale)"],
          externalCompetitionProgramSuperior:
            d["Programme de concours externe (classe supérieure)"],
          pdfFile: d["Fiche au format pdf"],
          referensKnowledgeDefinition: d["REFERENS_CONNAISSANCES_DEFINITIONS"],
          referensOperationalSkillsDefinition:
            d["REFERENS_COMPETENCES_OPERATIONNELLES_DEFINITIONS"],
          referensBehavioralSkillsDefinition:
            d["REFERENS_COMPETENCES_COMPORTEMENTALES_DEFINITIONS"],
          idSkills: d["COMPETENCES_ID"],
          bap: d["BAP"],
          fap: d["FAP"],
          description: "",
        })
          .then((response: any) => {
            // console.log("CREATED ", response);
            if (onEmploymentImported) {
              // IF IMPORTED
              onEmploymentImported();
            }
          })
          .catch((err) => {
            _setErrorMessage(err);
          });
      });
    }
  };

  const [data, _setData] = useState<any[] | null>([]);

  const [displayDataLength, _setDisplayDataLength] = useState<boolean>(false);

  useEffect(() => {
    // console.log(data?.length);
    if (data) _setDisplayDataLength(data?.length > 0);
  }, [data]);

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* type file */}
              <div className="input-import" {...getRootProps()}>
                <input
                  {...getInputProps()}
                  type="file"
                  name="importFile"
                  id="importFile"
                  className="inputfile"
                  accept=".xlsx"
                />
                <label htmlFor="importFile">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MdCloudUpload size={32} />
                  </div>
                  {displayDataLength && (
                    <p style={{ marginTop: 16, textAlign: "center" }}>
                      {data?.length} emploi
                      {data && data.length > 1 ? "s" : ""} à importer
                    </p>
                  )}
                  {data?.length === 0 && (
                    <p> Sélectionner un fichier à importer</p>
                  )}
                </label>
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={actionString}
                  onClick={handleSubmit}
                  disabled={data?.length !== 0 ? false : true}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default ImportEmploymentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4em;

    .input-import {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .inputfile + label {
      font-size: 1em;
      font-weight: 700;
      color: white;
      background-color: ${COLOR_RED_BRIGHT};
      padding: 48px 64px;
      display: inline-block;
      border-radius: 8px;
      border: 1px solid ${COLOR_RED_BRIGHT};
      transition: 250ms;
      outline: none;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
      background-color: white;
      color: ${COLOR_RED_BRIGHT};
      margin: auto;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label {
      cursor: pointer; /* "hand" cursor */
    }

    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueFormations = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const StatusCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
