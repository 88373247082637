import { instanceAxios } from "../utils/axios-api";

// GET ALL NOTIFICATIONS
export const onGetAllNotificationsApi = async () => {
  return instanceAxios
    .get(`/notifications/get-all-notifications`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL ACTIVE NOTIFICATIONS
export const onGetAllActiveNotificationsApi = async () => {
  return instanceAxios
    .get(`/notifications/get-all-active-notifications`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET NOTIFICATION
export const onGetNotificationApi = async (id: number) => {
  return instanceAxios
    .get(`/notifications/get-notification/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// TOGGLE NOTIFICATION IS ACTIVE
export const onToggleNotificationIsActiveApi = async (id: number) => {
  return instanceAxios
    .put(`/notifications/toggle-notification-is-active`, {id})
    .then((response) => (response.data ? response.data.data : null));
};
