import React, { ReactNode } from "react";
import styled from "styled-components";
import LogoSrc from "../../assets/images/logo.svg";
import { COLOR_BACKGROUND, COLOR_TITLE } from "../../constants/cts_colors";

// a container with the logo and the title of the form
const FormBase = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Wrapper>
      <FormHeader>
        <img className="form-logo" src={LogoSrc} />
        <h1 className="form-title">{title}</h1>
      </FormHeader>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default FormBase;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormHeader = styled.div`
  width: 100%;
  padding: 10px;
  background-color: ${COLOR_BACKGROUND};
  z-index: 1;

  .form-logo {
    display: block;
    width: 200px;

    // ========= MEDIA QUERIES - FormHeader / .logo ============
    @media (max-width: 800px) {
      width: 150px;
    }
  }

  .form-title {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.1;
    color: ${COLOR_TITLE};
    text-align: center;

    // ========= MEDIA QUERIES - FormHeader / .title ============
    @media (max-width: 800px) {
      font-size: 5vw;
    }
  }
`;

const Content = styled.div`
  overflow: auto;
`;
