import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { COLOR_BACKGROUND, COLOR_TEXT } from "../constants/cts_colors";
import { SIDEBAR_WIDTH } from "../constants/cts_sizes";
import { useAuthentication } from "../common/contexts/authenticationContext";
import Modal from "./Modal";
import Button from "./Button";
import { INotificationInfos } from "../interfaces/notification";

const WrapperConnected = ({ children, notifications }: { children: any, notifications: INotificationInfos[] }) => {
  const [openModal, _setOpenModal] = useState<boolean>(false);

  const { onLogout } = useAuthentication();

  const [redirectToLogIn, _setRedirectToLogIn] = useState<boolean>(false);

  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  // logout
  const handleLogout = () => {
    onLogout()
      .then(() => {
        _setRedirectToLogIn(true);
      })
      .catch((error) => alert(error));
  };

  return (
    <Wrapper>
      <SidebarContainer>
        <Sidebar
          handleLogout={() => {
            _setConfirmModal({
              message: "Êtes vous sûr de vouloir vous déconnecter ?",
              confirmedAction: () => {
                handleLogout();
              },
            });
          }}
          notifications={notifications}
        />
      </SidebarContainer>
      <MainContainer /*scroll={scroll}*/>{children}</MainContainer>

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => {
                  confirmModal.confirmedAction();
                }}
              />
              <Button
                text="Non"
                onClick={() => {
                  _setConfirmModal(null);
                }}
              />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </Wrapper>
  );
};

export default WrapperConnected;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  background-color: ${COLOR_BACKGROUND};
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${SIDEBAR_WIDTH};
  height: 100vh;
`;

const MainContainer = styled.div`
  position: absolute;
  left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  min-height: 100vh;
  background-color: ${COLOR_BACKGROUND};
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
