// Cet object contient tout les chemins nécéssaire à l'application
// Lors de la création d'une nouvelle page pour l'application,
// il faut ajouter ici le chemin vers celle-ci associé à un nom (clé)

const PATH = {
  home: "",
  login: "login",
  agents: "agents",
  archivedAgents: "agents-archives",
  anonymisedAgents: "agents-anonymises",
  referensJobs: "emplois-referens",
  services: "services",
  certificates: "certificates",
  organizationalChart: "organigramme",
  users: "utilisateurs",
  notification: "notification",
  notifications: "notifications",
  messages: "messages",
  items: "items",
  change_pwd: "change-password",
  lost_pwd: "lost-password",
  reset_pwd: "reset-password",
  // account: "account",
  edit_account: "edit-account",
  about: "about",
  contact: "contact",
  cgv: "conditions-generales-de-vente",
  logout: "logout",
};

export default PATH;
