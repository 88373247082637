import React, { ReactNode } from "react";
import styled from "styled-components";
import { COLOR_WHITE, COLOR_TITLE } from "../../constants/cts_colors";
import backgroundImgSrc from "../../assets/images/bg-1920-1080.jpg";
import LogoSrc from "../../assets/images/logo.svg";

const AuthPagesBase = ({children, title}: {children: ReactNode, title: string}) => {

  return (
    <Wrapper>
      <CenterContainer>
        <img className="logo" src={LogoSrc} />
        <h1 className="title">{title}</h1>
        {children}
      </CenterContainer>
    </Wrapper>
  )
}

export default AuthPagesBase;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  // background
  background: url(${backgroundImgSrc}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const CenterContainer = styled.div`
  position: absolute;
  top: 30vh;
  left: 50%;
  width: 600px;
  transform: translate(-50%);
  background-color: ${COLOR_WHITE};
  padding: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

  // ========= MEDIA QUERIES - CenterContainer ============
  @media (max-width: 800px) {
    width: 80%;
  }

  .logo {
    display: block;
    width: 200px;

    // ========= MEDIA QUERIES - CenterContainer / .logo ============
    @media (max-width: 800px) {
      width: 150px;
    }
  }

  .title {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.1;
    color: ${COLOR_TITLE};
    text-align: center;

    // ========= MEDIA QUERIES - CenterContainer / .title ============
    @media (max-width: 800px) {
      font-size: 5vw;
    }
  }
`