import { instanceAxios } from "../utils/axios-api";

// GET ALL DEPARTMENTS
export const onGetAllDepartmentsApi = async () => {
  return instanceAxios
    .get('/departments/get-all-departments/')
    .then((response) => response.data ? response.data.data : null)
    .catch((error) => { throw error.response ? error.response.data : error.message })
}

// GET ALL DEPARTMENTS BY SERVICE
export const onGetAllDepartmentsByServiceApi = async ({ serviceId }: { serviceId: number }) => {
  return instanceAxios
    .get(`/departments/get-all-departments-by-service/${serviceId}`)
    .then((response) => response.data ? response.data.data : null)
    .catch((error) => { throw error.response ? error.response.data : error.message })
}