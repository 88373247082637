import React, { useState, createContext, useCallback } from "react";
import {
  IDivisionChangeParent,
  IDivisionCreateUpdate,
  IDivisionsContext,
} from "../../interfaces/division";
import {
  onCreateDivisionApi,
  onGetDivisionBranchApi,
  onUpdateDivisionApi,
  onGetDivisionChildrenApi,
  onGetOneDivisionByIdApi,
  onGetAllDivisionsByTypeApi,
  onGetAllDivisionsByTypesApi,
  onChangeParentDivisionApi,
  onGetAllDivisionsApi,
  onGetDivisionFromAcronymApi,
  onGetDivisionFromReferenceApi,
} from "../../api/division.api";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  EMPTY_NAME,
  EMPTY_PARENT_DIVISION,
} from "../../constants/cts_formErrors";
import { DIVISION } from "../../constants/cts_divisions";

const DivisionsContext = createContext(null);

export function DivisionsProvider(props: any) {
  const [isLoadingDivisions, _setIsLoadingDivisions] = useState(false);

  // CREATE
  const onCreateDivision = useCallback(
    async ({
      name,
      acronym,
      type,
      parentDivisionId,
    }: IDivisionCreateUpdate) => {
      if (!checkEmptyInput(name)) {
        return Promise.reject(EMPTY_NAME);
      }
      if (type !== DIVISION.direction.type) {
        if (!checkEmptyInput(parentDivisionId)) {
          return Promise.reject(EMPTY_PARENT_DIVISION);
        }
      }

      _setIsLoadingDivisions(true);
      return onCreateDivisionApi({
        name,
        acronym,
        type,
        parentDivisionId,
      })
        .then((response) => {
          _setIsLoadingDivisions(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDivisions(false);
          throw error;
        });
    },
    []
  );

  // ON UPDATE
  const onUpdateDivision = useCallback(
    async ({
      id,
      name,
      acronym,
      type,
      parentDivisionId,
    }: IDivisionCreateUpdate) => {
      if (!checkEmptyInput(name)) {
        return Promise.reject(EMPTY_NAME);
      }

      _setIsLoadingDivisions(true);
      return onUpdateDivisionApi({
        id,
        name,
        acronym,
        type,
        parentDivisionId,
      })
        .then((response) => {
          _setIsLoadingDivisions(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDivisions(false);
          throw error;
        });
    },
    []
  );

  // GET ONE DIVISION BY ID
  const onGetOneDivisionById = useCallback(async (id: number) => {
    _setIsLoadingDivisions(true);
    return onGetOneDivisionByIdApi(id)
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET DIVISION FROM ACRONYM
  const onGetDivisionFromAcronym = useCallback(async (acronym: string) => {
    _setIsLoadingDivisions(true);
    return onGetDivisionFromAcronymApi(acronym)
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET DIVISION FROM REFERENCE
  const onGetDivisionFromReference = useCallback(
    async (reference: string, checkName: string) => {
      _setIsLoadingDivisions(true);

      return onGetDivisionFromReferenceApi({ reference, checkName })
        .then((response) => {
          _setIsLoadingDivisions(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDivisions(false);
          throw error;
        });
    },
    []
  );

  // GET ALL DIVISIONS
  const onGetAllDivisions = useCallback(() => {
    _setIsLoadingDivisions(true);
    return onGetAllDivisionsApi()
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET ALL DIVISIONS BY TYPE
  const onGetAllDivisionsByType = useCallback(async (type: string) => {
    _setIsLoadingDivisions(true);
    return onGetAllDivisionsByTypeApi(type)
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET ALL DIVISIONS BY TYPES
  const onGetAllDivisionsByTypes = useCallback(async (types: string[]) => {
    _setIsLoadingDivisions(true);
    return onGetAllDivisionsByTypesApi(types)
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET DIVISION CHILDREN
  const onGetDivisionChildren = useCallback(async (divisionId: number) => {
    _setIsLoadingDivisions(true);
    return onGetDivisionChildrenApi(divisionId)
      .then((response) => {
        _setIsLoadingDivisions(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDivisions(false);
        throw error;
      });
  }, []);

  // GET DIVISION BRANCH
  const onGetDivisionBranch = useCallback(
    async (divisionId: number, date?: Date) => {
      _setIsLoadingDivisions(true);
      return onGetDivisionBranchApi(divisionId, date)
        .then((response) => {
          _setIsLoadingDivisions(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDivisions(false);
          throw error;
        });
    },
    []
  );

  // CHANGE PARENT DIVISION
  const onChangeParentDivision = useCallback(
    async ({
      divisionId,
      oldParentDivisionId,
      newParentDivisionId,
    }: IDivisionChangeParent) => {
      _setIsLoadingDivisions(true);
      return onChangeParentDivisionApi({
        divisionId,
        oldParentDivisionId,
        newParentDivisionId,
      })
        .then((response) => {
          _setIsLoadingDivisions(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDivisions(false);
          throw error;
        });
    },
    []
  );

  return (
    <DivisionsContext.Provider
      {...props}
      value={{
        isLoadingDivisions,
        // functions
        onCreateDivision,
        onUpdateDivision,
        onGetOneDivisionById,
        onGetAllDivisions,
        onGetAllDivisionsByType,
        onGetAllDivisionsByTypes,
        onGetDivisionFromAcronym,
        onGetDivisionFromReference,
        onGetDivisionChildren,
        onGetDivisionBranch,
        onChangeParentDivision,
      }}
    />
  );
}

export const useDivisions = (): IDivisionsContext => {
  const context = React.useContext(DivisionsContext);
  if (!context)
    throw new Error("useDivisions must be used in DivisionsProvider");

  return context;
};
