import React, { useCallback, useEffect, useRef, useState } from "react"
import BodyContainer from "../../components/BodyContainer"
import styled from "styled-components"
import { useDivisions } from "../../common/contexts/divisionContext"
import { DIVISION } from "../../constants/cts_divisions"
import { IDivisionBranch } from "../../interfaces/division"
import { drawBranch } from "../../utils/orgChart/draw"
import { COLOR_LOADING_ICON, COLOR_WHITE } from "../../constants/cts_colors"
import FiltersForm from "./components/filtersForm"
import { RiLoader2Line } from "react-icons/ri"
import { getDirectionShortBranch, getOrgChartWidthAndHeight } from "../../utils/orgChart/tools"
import { IOrgChartFilterOptions } from "../../interfaces/orgChart"

const OrganizationalChart = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const defaultNodeWidth = 200
  const orgChartMarginTop = 100
  const orgChartVerticalSpacing = 50
  const orgChartHorizontalSpacing = 80
  const orgChartSiblingSpacing = 50
  const [canvas, _setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [ctx, _setCtx] = useState<CanvasRenderingContext2D | null>(null)
  const orgChartContainerRef = useRef<HTMLDivElement>(null)
  const { onGetAllDivisionsByType, onGetDivisionBranch, isLoadingDivisions } = useDivisions()
  const [defaultBranch, _setDefaultBranch] = useState<IDivisionBranch | null>(null)
  const [branch, _setBranch] = useState<IDivisionBranch | null>(null)
  const canvasOffsetRef = useRef({ x: 0, y: 0 })
  const dragStartRef = useRef({ x: 0, y: 0 })
  const isDraggingRef = useRef(false)
  const zoomLevelRef = useRef(1)
  const zoomScale = 0.05 // Adjust this value to control the zoom sensitivity
  const defaultDate = new Date()
  const defaultFilterOptions = {
    showResponsibleType: false,
    showResponsibleStatus: false,
    showAgentType: false,
    showAgentStatus: false,
  }
  const [filterOptions, _setFilterOptions] = useState<IOrgChartFilterOptions>(defaultFilterOptions)

  // SET THE CANVAS
  useEffect(() => {
    if (orgChartContainerRef.current && canvasRef.current) {
      _setCanvas(canvasRef.current)
      createCanvasAndContext(canvasRef.current)
    }
  }, [orgChartContainerRef.current, canvasRef.current])

  // CREATE THE CANVAS AND ORG CHART
  const createCanvasAndContext = useCallback((canvas: HTMLCanvasElement | null) => {
    if (orgChartContainerRef.current) {
      const orgChartContainer = orgChartContainerRef.current
      if (canvas) {
        canvas.width = orgChartContainer.clientWidth
        canvas.height = orgChartContainer.clientHeight
        _setCtx(canvas.getContext('2d'))
        generateOrgChart({
          canvas,
          ctx: canvas.getContext('2d'),
        })
      }
    }
  }, [canvas, orgChartContainerRef])

  // GET THE BRANCH
  const getBranch = useCallback(async (divisionId: number, date?: Date) => {
    return onGetDivisionBranch(divisionId, date)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return null
      });
  }, [])

  // GET THE DEFAULT BRANCH
  const getDefaultBranch = useCallback(async () => {
    const response = await onGetAllDivisionsByType(
      DIVISION.direction.type
    );
    const division =
      response && response.length > 0
        ? response[0]
        : null;
    if (division) {
      const responseBranch = await getBranch(division.id)
      _setDefaultBranch(responseBranch)
      return responseBranch
    }
    return null
  }, [])

  // GENERATE THE ORG CHART
  const generateOrgChart = useCallback(async ({
    divisionBranch,
    canvas,
    ctx,
    givenFilterOptions,
    position,
    zoomLevel,
  }: {
    divisionBranch?: IDivisionBranch,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D | null,
    givenFilterOptions?: IOrgChartFilterOptions,
    position?: {x: number, y: number},
    zoomLevel?: number,
  }) => {
    // check if a branch was given
    let givenBranch = null
    if (!divisionBranch) {
      givenBranch = await getDefaultBranch()
    }else {
      givenBranch = divisionBranch
    }

    // set the filters
    let filters = filterOptions
    if (givenFilterOptions) {
      filters = givenFilterOptions
    }
    
    // use the branch as a constant
    const currentBranch = givenBranch

    // START THE DRAW OF THE BRANCH
    if (canvas && currentBranch && ctx) {
      let startPositionX = canvas.width / 2 - defaultNodeWidth / 2
      let startPositionY = orgChartMarginTop
      
      // on drag - change position
      startPositionX += canvasOffsetRef.current.x;
      startPositionY += canvasOffsetRef.current.y;

      // Apply zoom
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate the scaled size of the canvas
      const scaledWidth = canvas.width * zoomLevelRef.current;
      const scaledHeight = canvas.height * zoomLevelRef.current;

      // Calculate the offset to keep the chart centered
      const offsetX = (scaledWidth - canvas.width) / 2;
      const offsetY = (scaledHeight - canvas.height) / 2;

      // Apply zoom and offset
      ctx.setTransform(zoomLevelRef.current, 0, 0, zoomLevelRef.current, -offsetX, -offsetY);
      
      if (zoomLevel) {
        ctx.setTransform(zoomLevel, 0, 0, zoomLevel, 0, 0);
      }
      
      drawBranch({
        canvas,
        branch: currentBranch,
        ctx,
        rootX: position ? position.x : startPositionX,
        rootY: position ? position.y : startPositionY,
        filters,
        nodeWidth: defaultNodeWidth,
        verticalSpacing: orgChartVerticalSpacing,
        horizontalSpacing: orgChartHorizontalSpacing,
        siblingSpacing: orgChartSiblingSpacing,
      })

      // Reset transformation to prevent the drawing overflowing or drawing infinitely when scrolling out
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    // set the current branch
    if (currentBranch) {
      _setBranch(currentBranch)
    }
  }, [canvas, ctx, filterOptions])

  // DRAG IN CANVAS
  useEffect(() => {
    if (canvas && branch) {
      const handleMouseDown = (e: MouseEvent) => {
        isDraggingRef.current = true;
        dragStartRef.current = { x: e.clientX, y: e.clientY };
      };

      const handleMouseMove = (e: MouseEvent) => {
        if (isDraggingRef.current) {
          const deltaX = e.clientX - dragStartRef.current.x;
          const deltaY = e.clientY - dragStartRef.current.y;

          // Adjust drag distance based on the current zoom level
          const scaledOffsetX = deltaX / zoomLevelRef.current;
          const scaledOffsetY = deltaY / zoomLevelRef.current;

          canvasOffsetRef.current.x += scaledOffsetX;
          canvasOffsetRef.current.y += scaledOffsetY;
          
          generateOrgChart({
            divisionBranch: branch,
            canvas,
            ctx,
          })

          dragStartRef.current = { x: e.clientX, y: e.clientY };
        }
      };

      const handleMouseUp = () => {
        isDraggingRef.current = false;
      };
  
      canvas.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);

      return () => {
        canvas.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    };
  }, [canvas, branch, isDraggingRef, dragStartRef, canvasOffsetRef])

  // ZOOM IN / OUT THE CANVAS
  useEffect(() => {
    if (canvas && branch) {
      const handleWheel = (event: WheelEvent) => {
        // Determine the direction of the scroll
        const delta = event.deltaY;
    
        // Adjust zoom level based on the scroll direction
        if (delta > 0) {
          zoomLevelRef.current = zoomLevelRef.current + zoomScale;
        } else if (delta < 0 && zoomLevelRef.current > 0.1) {
          zoomLevelRef.current = zoomLevelRef.current - zoomScale;
        }

        generateOrgChart({
          divisionBranch: branch,
          canvas,
          ctx,
        })
      };
  
      canvas.addEventListener('wheel', handleWheel);

      return () => {
        canvas.removeEventListener('wheel', handleWheel);
      };
    }
  }, [canvas, branch, zoomLevelRef])

  // ON SCREEN RESIZE, CHANGE CANVAS WIDTH AND HEIGHT
  useEffect(() => {
    if (canvas && branch) {
      const handleScreenResize = () => {
        const orgChartContainer = orgChartContainerRef.current
        if (orgChartContainer) {
          canvas.width = orgChartContainer.clientWidth
          canvas.height = orgChartContainer.clientHeight
          
          generateOrgChart({
            divisionBranch: branch,
            canvas,
            ctx,
          })
        }
      };
  
      window.addEventListener('resize', handleScreenResize);

      return () => {
        window.removeEventListener('resize', handleScreenResize);
      };
    }
  }, [canvas, branch, orgChartContainerRef])

  // HANDLE SUBMIT FILTERS
  const handleSubmitFilters = async ({
    date,
    shortBranchRootId,
    showResponsibleType,
    showAgentType,
    showAgentStatus,
    showResponsibleStatus,
  }: {
    date: Date,
    shortBranchRootId: number,
    showResponsibleType: boolean,
    showAgentType: boolean,
    showAgentStatus: boolean,
    showResponsibleStatus: boolean,
  }) => {
    if (canvas && defaultBranch) {
      // reset default settings
      canvasOffsetRef.current = { x: 0, y: 0 }
      dragStartRef.current = { x: 0, y: 0 }
      isDraggingRef.current = false
      zoomLevelRef.current = 1
      let newBranch = await getBranch(defaultBranch.division.id, date)

      if (shortBranchRootId) {
        // put the the root 
        const responseBranch = await getBranch(shortBranchRootId, date)
        if (responseBranch) {
          if (responseBranch.division.type === DIVISION.direction.type) {
            newBranch = getDirectionShortBranch(responseBranch)
          }else {
            newBranch = responseBranch
          }
        }
      }

      const filters = {
        showResponsibleType,
        showAgentType,
        showAgentStatus,
        showResponsibleStatus,
      }

      if (newBranch) {
        generateOrgChart({
          divisionBranch: newBranch,
          canvas,
          ctx,
          givenFilterOptions: filters,
        })

        _setBranch(newBranch)
        _setFilterOptions(filters)
      }
    }
  }

    // DOWNLOAD CANVAS IMAGE
    const downloadCanvasImage = () => {
      const visibleCanvas = canvasRef.current;
      if (
        visibleCanvas &&
        canvas &&
        orgChartContainerRef.current &&
        branch &&
        branch.orgChartPosition &&
        branch.orgChartPosition.x !== null &&
        branch.orgChartPosition.y !== null &&
        ctx
      ) {

        // get the full width and height of the drawn elements inside the canvas
        const {orgChartMinX, orgChartWidth, orgChartHeight} = getOrgChartWidthAndHeight({
          branch,
          verticalSpacing: orgChartVerticalSpacing,
          nodeWidth: defaultNodeWidth,
          filters: filterOptions,
          ctx,
        })
        const imagePadding = 100
        visibleCanvas.width = orgChartWidth + 2 * imagePadding
        visibleCanvas.height = orgChartHeight + 2 * imagePadding
        const distanceFromMinXToRootX = branch.orgChartPosition.x - orgChartMinX;
        generateOrgChart({
          divisionBranch: branch,
          canvas,
          ctx,
          position: {
            x: imagePadding + distanceFromMinXToRootX,
            y: imagePadding,
          },
          zoomLevel: 1,
        })
        // Create an off-screen canvas with the full dimensions
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = visibleCanvas.width;
        offScreenCanvas.height = visibleCanvas.height;
        const offScreenCtx = offScreenCanvas.getContext('2d');
    
        // Draw the content of the visible canvas onto the off-screen canvas
        if (offScreenCtx) {
          offScreenCtx.drawImage(visibleCanvas, 0, 0);
        }
    
        // Get the data URL of the off-screen canvas content
        const dataURL = offScreenCanvas.toDataURL('image/png');

        // reset the initial values before the download
        visibleCanvas.width = orgChartContainerRef.current.clientWidth
        visibleCanvas.height = orgChartContainerRef.current.clientHeight

        generateOrgChart({
          divisionBranch: branch,
          canvas,
          ctx,
        })

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'organigramme.png';
    
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
      }
    }

  return (
    <BodyContainer title="Organigramme">
      <MainContainer>
        <FiltersContainer>
          {
            canvasRef.current &&
            <FiltersForm
              defaultDate={defaultDate}
              defaultFilterOptions={defaultFilterOptions}
              onHandleSubmit={(response: {
                date: Date,
                shortBranchRootId: number,
                showResponsibleType: boolean,
                showResponsibleStatus: boolean,
                showAgentType: boolean,
                showAgentStatus: boolean,
              }) => handleSubmitFilters(response)}
              onDownloadCanvasImage={() => downloadCanvasImage()}
            />
          }
        </FiltersContainer>
        {/* MAIN CONTAINER */}
        <OrgChartContainer ref={orgChartContainerRef}>
          {
            isLoadingDivisions &&
            <LoadingDiv>
              <RiLoader2Line
                className="loading-icon"
              />
            </LoadingDiv>
          }
          <canvas ref={canvasRef}></canvas>
        </OrgChartContainer>
      </MainContainer>
    </BodyContainer>
  );
};

export default OrganizationalChart;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const FiltersContainer = styled.div`
  background-color: ${COLOR_WHITE};
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
`

const OrgChartContainer = styled.div`
  position: relative;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  cursor: grab;

  // block user selection (on drag)
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const LoadingDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255, 255, 255, 0.3);

  .loading-icon {

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }

    width: 30px;
    color: ${COLOR_LOADING_ICON};
    opacity: 0.75;
    animation: 2s rotation infinite linear;
  }
`
