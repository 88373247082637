import React, { useState, useCallback, createContext } from "react";
import {
  EMPTY_DEGREE_NAME,
  EMPTY_DEGREE_YEAR,
  EMPTY_DEGREE_GRADE,
  EMPTY_DEGREE_AGENTID,
} from "../../constants/cts_formErrors";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  onCreateDegreeApi,
  onDeleteDegreeApi,
  onGetAllDegreesApi,
  onGetAllDegreesFromAgentApi,
  onGetDegreeApi,
  onUpdateDegreeApi,
} from "../../api/degree.api";
import { IDegreeFormValues, IDegreesContext } from "../../interfaces/degree";

const DegreesContext = createContext(null);

export function DegreesProvider(props: any) {
  const [isLoadingDegrees, _setIsLoadingDegrees] = useState(false);

  // CREATE
  const onCreateDegree = useCallback(
    async ({ name, year, grade, agentId, comments }: IDegreeFormValues) => {
      if (!checkEmptyInput(name)) {
        return Promise.reject(EMPTY_DEGREE_NAME);
      }
      // if (!checkEmptyInput(year)) {
      //   return Promise.reject(EMPTY_DEGREE_YEAR);
      // }
      // if (!checkEmptyInput(grade)) {
      //   return Promise.reject(EMPTY_DEGREE_GRADE);
      // }
      if (!checkEmptyInput(agentId)) {
        return Promise.reject(EMPTY_DEGREE_AGENTID);
      }

      _setIsLoadingDegrees(true);
      return onCreateDegreeApi({
        name,
        year,
        grade,
        agentId,
        comments,
      })
        .then((response) => {
          _setIsLoadingDegrees(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDegrees(false);
          throw error;
        });
    },
    []
  );

  // GET ALL
  const onGetAllDegrees = useCallback(() => {
    _setIsLoadingDegrees(true);
    return onGetAllDegreesApi()
      .then((response) => {
        _setIsLoadingDegrees(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDegrees(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ALL FROM AGENT
  const onGetAllDegreesFromAgent = useCallback((id: number) => {
    _setIsLoadingDegrees(true);
    return onGetAllDegreesFromAgentApi(id)
      .then((response) => {
        _setIsLoadingDegrees(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDegrees(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // GET ONE
  const onGetDegree = useCallback((id: number) => {
    _setIsLoadingDegrees(true);
    return onGetDegreeApi(id)
      .then((response) => {
        _setIsLoadingDegrees(false);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  // UPDATE ONE
  const onUpdateDegree = useCallback(
    async ({ id, name, year, grade, agentId, comments }: IDegreeFormValues) => {
      _setIsLoadingDegrees(true);
      return onUpdateDegreeApi({
        id,
        name,
        year,
        grade,
        agentId,
        comments,
      })
        .then((response) => {
          _setIsLoadingDegrees(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingDegrees(false);
          throw error;
        });
    },
    []
  );

  // DELETE ONE
  const onDeleteDegree = useCallback((id: number) => {
    _setIsLoadingDegrees(true);
    return onDeleteDegreeApi(id)
      .then((response) => {
        _setIsLoadingDegrees(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingDegrees(false);
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <DegreesContext.Provider
      {...props}
      value={{
        isLoadingDegrees,
        // function
        onCreateDegree,
        onGetAllDegrees,
        onGetDegree,
        onUpdateDegree,
        onDeleteDegree,
        onGetAllDegreesFromAgent,
      }}
    />
  );
}

export const useDegrees = (): IDegreesContext => {
  const context = React.useContext(DegreesContext);
  if (!context) throw new Error("useDegrees must be used in DegreesProvider");

  return context;
};
