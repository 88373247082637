import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import {
  IAgentInactivityFormValues,
  IAgentInactivityInfos,
} from "../../../interfaces/agentInactivity";
import FormInfoMessage from "../../../components/form/FormInfoMessage";
import { useAgentsInactivities } from "../../../common/contexts/agentInactivityContext";
import Modal from "../../../components/Modal";
import ToastAlert from "../../../components/ToastAlert";

const AddEditInactivitiesAgentForm = ({
  title,
  agentId,
  formData,
  actionString,
  onAgentInactivitiesUpdated,
}: {
  title: string;
  agentId: number;
  formData?: IAgentInactivityFormValues;
  actionString: string;
  onAgentInactivitiesUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    agentId: agentId,
    title: null,
    startDate: null,
    endDate: null,
  };

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [formValues, _setFormValues] =
    useState<IAgentInactivityFormValues>(initialFormState);

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  const {
    onCreateAgentInactivity,
    onGetAllAgentsInactivitiesFromAgent,
    onUpdateAgentInactivity,
    onDeleteAgentInactivity,
  } = useAgentsInactivities();

  const [inactivities, _setInactivities] = useState<
    Array<IAgentInactivityInfos>
  >([]);

  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (agentId) {
      onGetAllAgentsInactivitiesFromAgent(agentId).then((response: any) => {
        _setInactivities(response);
      });
    }

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // console.log(formValues);

  // delete agent inactivity
  const deleteAgentInactivity = (agentInactivityId: number) => {
    onDeleteAgentInactivity(agentInactivityId)
      .then(() => {
        _setToastMessage("Inactivité supprimée avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        _setConfirmModal(null);
        onGetAllAgentsInactivitiesFromAgent(agentId).then((response: any) => {
          _setInactivities(response);
        });
      })
      .catch((err) => console.error(err));
  };

  // on delete
  const handleDelete = (agentInactivityId: number) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer cette inactivité ?",
      confirmedAction: () => deleteAgentInactivity(agentInactivityId),
    });
  };

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isEditMode) {
      onCreateAgentInactivity(formValues)
        .then(() => {
          _setToastMessage("Inactivité ajoutée avec succès !");
          _setToastVisible(true);
          _setFormValues(initialFormState);
          onGetAllAgentsInactivitiesFromAgent(agentId).then((response: any) => {
            _setInactivities(response);
          });
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    } else {
      // if is on edit mode, update the agent inactivity
      // console.log(formValues);
      onUpdateAgentInactivity(formValues).then(() => {
        _setToastMessage("Inactivité mise à jour avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        onGetAllAgentsInactivitiesFromAgent(agentId).then((response: any) => {
          _setIsEditMode(false);
          _setInactivities(response);
        });
      });
    }
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueInactivities>
                <h4>- Historique -</h4>
                {inactivities && inactivities.length > 0 && (
                  <div className="latest-vacations">
                    {inactivities.map((inactivity, key) => {
                      return (
                        <InactivityCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              handleDelete(inactivity.id);
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-edit-line"
                            onClick={() => {
                              _setIsEditMode(true);
                              _setFormValues(inactivity);
                            }}
                          ></EditDeleteIcons>

                          <h4>{inactivity.title}</h4>
                          <p>-</p>
                          <h4>
                            {new Date(inactivity.startDate).toLocaleDateString(
                              "fr-FR",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </h4>
                          <p>au</p>
                          <h4>
                            {new Date(inactivity.endDate).toLocaleDateString(
                              "fr-FR",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </h4>
                        </InactivityCard>
                      );
                    })}
                  </div>
                )}
                {!inactivities ||
                  (inactivities.length < 1 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: COLOR_TEXT,
                        paddingTop: 12,
                      }}
                    >
                      Aucune inactivité pour le moment.
                    </p>
                  ))}
              </HistoriqueInactivities>

              {/* title */}
              <div>
                <FormLabel htmlFor="title" text="Titre" />
                <FormInputContainer
                  input={
                    <input
                      type="text"
                      id="title"
                      name="title"
                      placeholder="Titre"
                      onChange={handleChange}
                      value={formValues.title ? formValues.title : ""}
                    />
                  }
                />
              </div>

              {/* start date */}
              <div>
                <FormLabel htmlFor="startDate" text="Date de début" />
                <FormInputContainer
                  input={
                    <input
                      id="startDate"
                      type="date"
                      onChange={handleChange}
                      name="startDate"
                      value={
                        formValues.startDate
                          ? new Date(formValues.startDate)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                    />
                  }
                />
                <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
              </div>

              {/* end date */}
              <div>
                <FormLabel htmlFor="endDate" text="Date de fin" />
                <FormInputContainer
                  input={
                    <input
                      id="endDate"
                      type="date"
                      onChange={handleChange}
                      name="endDate"
                      value={
                        formValues.endDate
                          ? new Date(formValues.endDate)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                    />
                  }
                />
                <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={!isEditMode ? actionString : "Modifier"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditInactivitiesAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueInactivities = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const InactivityCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
