import { useState, useEffect } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import styled from "styled-components";
import { COLOR_RED_BRIGHT, COLOR_WHITE } from "../constants/cts_colors";
import { useRef } from "react";

function InputFile({
  id,
  name,
  _setFile,
  onReset,
}: {
  id: string;
  name: string;
  _setFile: Function;
  onReset?: boolean;
}) {
  const [agentFile, _setAgentFile] = useState<File>();

  const [fileName, _setFileName] = useState("Aucun fichier importé");

  const refInputFile = useRef<HTMLInputElement>(null);

  const updateCV = () => {
    if (refInputFile.current) {
      refInputFile.current.click();
      if (refInputFile.current.value) refInputFile.current.value = ""; //reset input file value
    }
  };

  useEffect(() => {
    _setFile(agentFile);

    if (onReset) {
      _setFileName("Aucun fichier importé");
      _setFile(undefined);
      _setAgentFile(undefined);
    }
  }, [agentFile, onReset]);

  return (
    <InputFileStyles>
      <InputLabel htmlFor={name}>Fichier</InputLabel>
      <InputDiv onClick={updateCV} htmlFor={name}>
        <input
          id={id}
          name={name}
          type="file"
          accept=".pdf"
          className="input-field"
          hidden
          required
          ref={refInputFile}
          onChange={({ target: { files } }) => {
            if (files) {
              files[0] && _setFileName(files[0].name);
              if (files) _setAgentFile(files[0]);
            }
          }}
        />

        {agentFile && !onReset ? (
          <PreviewCV src={URL.createObjectURL(agentFile)} />
        ) : (
          <InputFormBrowseFile>
            <BiCloudUpload color={COLOR_RED_BRIGHT} size={60} />
            <p>Cliquez pour importer un fichier PDF</p>
          </InputFormBrowseFile>
        )}
      </InputDiv>
      <UploadedRow>
        <UploadContent
          onClick={() => {
            _setFileName("Aucun fichier importé");
            _setFile(undefined);
            _setAgentFile(undefined);
          }}
        >
          {fileName} -{"  "}
          <DeleteContent>
            <AiFillDelete />
          </DeleteContent>
        </UploadContent>
      </UploadedRow>
    </InputFileStyles>
  );
}

export default InputFile;

const InputFileStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;
`;

const InputLabel = styled.label`
  font-size: 18px;
  margin: 10px 0px;
  cursor: pointer;

  // ========= MEDIA QUERIES - InputLabel ============
  @media (max-width: 680px) {
    margin: 12px 0px;
  }
`;

const PreviewCV = styled.embed`
  width: 100%;
  height: auto;
`;

const InputDiv = styled.label`
  margin: 0px;
  cursor: pointer;
  padding: 36px 18px;
  text-align: center;

  display: flex;
  font-size: 18px;
  border: 3px dashed ${COLOR_RED_BRIGHT};
  border-radius: 8px;

  // ========= MEDIA QUERIES - InputLabel ============
  @media (max-width: 680px) {
    margin: 0px;
    height: 200px;
  }
`;

const InputFormBrowseFile = styled.div`
  margin: auto;
  /* background-color: red; */
`;

const UploadedRow = styled.section`
  /* margin-bottom: 10px; */
  cursor: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
  background-color: ${COLOR_WHITE};
`;

const UploadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const DeleteContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
