import React, { useState, useCallback, createContext } from "react";
import { onGetAllNotificationsApi, onGetAllActiveNotificationsApi, onGetNotificationApi, onToggleNotificationIsActiveApi } from "../../api/notification.api";
import { INotificationsContext } from "../../interfaces/notification";

const NotificationsContext = createContext(null);

export function NotificationsProvider(props: any) {
  const [isLoadingNotifications, _setIsLoadingNotifications] = useState(false);

  // GET ALL NOTIFICATIONS
  const onGetAllNotifications = useCallback(async () => {
      _setIsLoadingNotifications(true);
      return onGetAllNotificationsApi()
        .then((response) => {
          _setIsLoadingNotifications(false);
          return response;
        })
        .catch((error) => {
          _setIsLoadingNotifications(false);
          throw error;
        });
    },
    []
  );

  // GET ALL ACTIVE NOTIFICATIONS
  const onGetAllActiveNotifications = useCallback(async () => {
    _setIsLoadingNotifications(true);
    return onGetAllActiveNotificationsApi()
      .then((response) => {
        _setIsLoadingNotifications(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingNotifications(false);
        throw error;
      });
    },
    []
  );

  // GET NOTIFICATION
  const onGetNotification = useCallback(async (id: number) => {
    _setIsLoadingNotifications(true);
    return onGetNotificationApi(id)
      .then((response) => {
        _setIsLoadingNotifications(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingNotifications(false);
        throw error;
      });
    },
    []
  );

  // GET TOGGLE NOTIFICATION IS ACTIVE
  const onToggleNotificationIsActive = useCallback(async (id: number) => {
    _setIsLoadingNotifications(true);
    return onToggleNotificationIsActiveApi(id)
      .then((response) => {
        _setIsLoadingNotifications(false);
        return response;
      })
      .catch((error) => {
        _setIsLoadingNotifications(false);
        throw error;
      });
    },
    []
  );

  return (
    <NotificationsContext.Provider
      {...props}
      value={{
        isLoadingNotifications,
        // function
        onGetAllNotifications,
        onGetAllActiveNotifications,
        onGetNotification,
        onToggleNotificationIsActive,
      }}
    />
  );
}

export const useNotifications = (): INotificationsContext => {
  const context = React.useContext(NotificationsContext);
  if (!context) throw new Error("useNotifications must be used in NotificationsProvider");

  return context;
};