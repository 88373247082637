import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { useUser } from "../../common/contexts/userContext";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { IUserInfos } from "../../interfaces/user";
import AccountCard from "../../components/AccountCard";
import EditCreateAccount from "../../components/EditCreateAccount";
import {
  COLOR_ACTIVE_LINK,
  COLOR_BLUE_MORE,
  COLOR_BTN,
  COLOR_BUTTON,
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import BodyContainer from "../../components/BodyContainer";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ToastAlert from "../../components/ToastAlert";
import AddEditAccountForm from "./components/addEditAccountForm";

const FormInitialState = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  image: "",
  roleName: "Client",
  password: "",
  id: -1,
  created_at: "",
};

const AccountsList = () => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const {
    onGetAllUsersAdmin,
    onUpdateAccountByAdmin,
    onDeleteAccountByAdmin,
    onCreateAccountByAdmin,
  } = useUser();
  const [accounts, _setAccounts] = useState<IUserInfos[]>([]);
  const [totalAccounts, _setTotalAccounts] = useState<number>(0);
  const [totalAdmin, _setTotalAdmin] = useState<number>(0);
  const [totelClients, _setTotalClients] = useState<number>(0);
  const [editId, _setEditId] = useState<number>(-1);
  const [createAccount, _setCreateAccount] = useState<boolean>(false);
  const [formValues, _setFormValues] = useState<IUserInfos>(FormInitialState);
  const [selectedIds, _setSelectedIds] = useState<number[]>([]);
  const [deleteButton, _setDeleteButton] = useState<boolean>(false);
  const [selectedFilter, _setSelectedFilter] = useState<string>("all");

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  // ADD ACCOUNT - VARIABLES
  const [isAddAccountModalOpen, _setIsAddAccountModalOpen] =
    useState<boolean>(false);

  // set title of the page
  useEffect(() => {
    document.title = "Utilisateurs | Sorbonne";
  }, []);

  useEffect(() => {
    onGetAllUsersAdmin()
      .then((response: any) => {
        _setAccounts(response);
        _setTotalAccounts(response.length);
        _setTotalAdmin(
          response.filter(
            (user: IUserInfos) => user.roleName === "Administrateur"
          ).length
        );
        _setTotalClients(
          response.filter(
            (user: IUserInfos) => user.roleName !== "Administrateur"
          ).length
        );
      })
      .catch((error: any) => console.error(error));
  }, [createAccount]);

  useEffect(() => {
    if (editId < 0) _setFormValues(FormInitialState);
  }, [editId]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const { email, firstName, lastName, phoneNumber, roleName, password, id } =
      formValues;
    if (createAccount) {
      onCreateAccountByAdmin({
        email,
        firstName,
        lastName,
        phoneNumber,
        roleName,
        password,
      })
        .then((response: any) => {
          _setToastMessage("Utilisateur ajouté avec succès !");
          _setToastVisible(true);
          _setAccounts((accounts) => [...accounts, response]);
        })
        .catch((error: any) => alert(error));
      _setCreateAccount(false);
    } else {
      const update = accounts.map((account) => {
        if (account.id === id) {
          delete formValues.password;
          return formValues;
        }
        return account;
      });
      _setEditId(-1);
      onUpdateAccountByAdmin({
        email,
        firstName,
        lastName,
        phoneNumber,
        roleName,
        password,
        id,
      })
        .then(() => {
          _setToastMessage("Utilisateur modifié avec succès !");
          _setToastVisible(true);
          _setAccounts(update);
          _setFormValues(FormInitialState);
        })
        .catch((error: any) => {
          alert(error);
        });
    }
    _setFormValues(FormInitialState);
  };

  const handleDeleteAccounts = (usersId: number[]) => {
    usersId.map((id) =>
      onDeleteAccountByAdmin({ id })
        .then(() => {
          _setConfirmModal(null);
          _setToastMessage(
            "Utilisateur" +
              (selectedIds.length > 1 ? "s" : "") +
              " supprimé" +
              (selectedIds.length > 1 ? "s" : "") +
              " avec succès !"
          );
          _setSelectedIds([]);
          _setToastVisible(true);
          _setAccounts((accounts) =>
            accounts.filter((user) => {
              return user.id !== id;
            })
          );
        })
        .catch((error: any) => {
          alert(error);
        })
    );
  };

  const handleChangeAccountData = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    _setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    _setFormValues({ ...formValues, [name]: value });
  };

  const handleEditId = (event: any, user: IUserInfos) => {
    event.preventDefault();
    _setEditId(user.id);
    _setCreateAccount(false);
    _setFormValues({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber ?? "",
      image: user.image,
      roleName: user.roleName,
      password: user.password,
      id: user.id,
      created_at: user.created_at,
    });
  };

  return (
    <BodyContainer title="Utilisateurs">
      <Wrapper>
        <Menu>
          {/* <div className="header">
            <span>{t(`mainTitle`, { ns: "accountList" })}</span>
          </div> */}
          <div
            className="category-element"
            onClick={() => _setSelectedFilter("all")}
          >
            <p>{t(`filter.all`, { ns: "accountList" })}</p>
            <div className="total all">
              <div className="number">{totalAccounts}</div>
            </div>
          </div>
          <div
            className="category-element"
            onClick={() => _setSelectedFilter("Client")}
          >
            <p>{t(`filter.clients`, { ns: "accountList" })}s</p>
            <div className="total blue">
              <div className="number">{totelClients}</div>
            </div>
          </div>
          <div
            className="category-element"
            onClick={() => _setSelectedFilter("Administrateur")}
          >
            <p>{t(`filter.admin`, { ns: "accountList" })}s</p>
            <div className="total red">
              <div className="number">{totalAdmin}</div>
            </div>
          </div>
        </Menu>
        {
          <Table>
            <Header>
              {!createAccount && (
                <button
                  onClick={() => (
                    editId < 0 ? _setCreateAccount(true) : null,
                    _setIsAddAccountModalOpen(true)
                  )}
                  className="btn add"
                >
                  <AiOutlinePlus className="icon" />
                </button>
              )}
              {
                <button
                  className={
                    "btn " + (selectedIds.length > 0 ? "" : "disabled")
                  }
                  onClick={() => {
                    selectedIds.length > 0
                      ? _setConfirmModal({
                          message:
                            "Voulez-vous supprimer " +
                            (selectedIds.length > 1 ? "les " : "l'") +
                            "utilisateur" +
                            (selectedIds.length > 1 ? "s " : " ") +
                            "sélectionné" +
                            (selectedIds.length > 1 ? "s " : " ") +
                            "?",
                          confirmedAction: () => {
                            handleDeleteAccounts(selectedIds);
                          },
                        })
                      : null;
                  }}
                >
                  <BiTrash className="icon" />
                </button>
              }
            </Header>
            <Accounts>
              {createAccount && editId < 0 && isAddAccountModalOpen && (
                <Modal
                  onClose={() => (
                    _setIsAddAccountModalOpen(false), _setCreateAccount(false)
                  )}
                  width="90%"
                  maxHeight="90%"
                >
                  <AddEditAccountForm
                    title="Ajouter un utilisateur"
                    actionString="Ajouter"
                    values={formValues}
                    createAccount={createAccount}
                    _setCreateAccount={_setCreateAccount}
                    handleChangeAccountData={handleChangeAccountData}
                    handleSelectChange={handleSelectChange}
                    handleSubmit={handleSubmit}
                  />
                </Modal>
              )}
              {(((selectedFilter === "Client" && totelClients === 0) ||
                (selectedFilter === "Administrateur" && totalAdmin === 0)) && (
                <p
                  style={{ paddingTop: 24, paddingLeft: 12, paddingBottom: 24 }}
                >
                  Aucun résultat.
                </p>
              )) ||
                accounts.map(
                  (account) =>
                    (account.roleName === selectedFilter ||
                      selectedFilter === "all") && (
                      <Fragment key={account.id}>
                        {editId === account.id && !isAddAccountModalOpen ? (
                          <Modal
                            onClose={() => (
                              _setIsAddAccountModalOpen(false), _setEditId(-1)
                            )}
                            width="90%"
                            maxHeight="90%"
                          >
                            <AddEditAccountForm
                              title="Modifier un utilisateur"
                              actionString="Modifier"
                              values={formValues}
                              createAccount={createAccount}
                              _setCreateAccount={_setCreateAccount}
                              handleChangeAccountData={handleChangeAccountData}
                              handleSelectChange={handleSelectChange}
                              handleSubmit={handleSubmit}
                            />
                          </Modal>
                        ) : (
                          <AccountCard
                            account={account}
                            handleEditId={handleEditId}
                            handleDeleteAccounts={handleDeleteAccounts}
                            createAccount={createAccount}
                            _setSelectedId={_setSelectedIds}
                            selectedId={selectedIds}
                          />
                        )}
                      </Fragment>
                    )
                )}
            </Accounts>
          </Table>
        }

        {/* confirm modal */}
        {confirmModal && (
          <Modal onClose={() => _setConfirmModal(null)}>
            <ConfirmModalContainer>
              <div className="message">{confirmModal.message}</div>
              <div className="buttons">
                <Button
                  text="Oui"
                  onClick={() => {
                    confirmModal.confirmedAction();
                    _setToastMessage(
                      "Utilisateur" +
                        (selectedIds.length > 1 ? "s" : "") +
                        " supprimé" +
                        (selectedIds.length > 1 ? "s" : "") +
                        " avec succès !"
                    );
                    _setToastVisible(true);
                  }}
                />
                <Button
                  text="Non"
                  onClick={() => {
                    _setDeleteButton(false);
                    _setConfirmModal(null);
                  }}
                />
              </div>
            </ConfirmModalContainer>
          </Modal>
        )}

        {/* TOAST */}
        {toastVisible && toastMessage && (
          <ToastAlert
            text={toastMessage}
            handleOk={() => {
              _setToastVisible(false);
            }}
            endedTimer={() => {
              _setToastVisible(false);
            }}
          />
        )}

        {/* {deleteButton && (
          <Modal>
            <div className="content">
              {t(`removeConfirmation.text`, { ns: "accountList" })}
              <div className="buttons">
                <button onClick={() => handleDeleteAccounts(selectedIds)}>
                  {t(`removeConfirmation.yes`, { ns: "accountList" })}
                </button>
                <button onClick={() => _setDeleteButton(false)}>
                  {t(`removeConfirmation.no`, { ns: "accountList" })}
                </button>
              </div>
            </div>
          </Modal>
        )} */}
      </Wrapper>
    </BodyContainer>
  );
};

export default AccountsList;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-arround;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  transition: opacity 0.15s linear;
  padding: 0px 16px 12px 0px;
  color: ${COLOR_TEXT};
  min-width: 180px;
  text-align: left;

  .header {
    min-height: 48px;
    padding: 12px 8px;
    font-size: 20px;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${COLOR_TEXT};
  }

  .category-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-size: 14px;
    margin: 2px 0px;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      p {
        color: ${COLOR_RED_BRIGHT};
      }
    }

    .total {
      position: relative;
      border-radius: 160px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: bold;
      z-index: 1;
      min-width: 15px;
      max-width: 30px;
    }

    .number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .all {
      z-index: 1;
      color: ${COLOR_WHITE};
      background: ${COLOR_BLUE_MORE};
    }

    .blue {
      color: ${COLOR_WHITE};
      background: ${COLOR_ACTIVE_LINK};
    }

    .red {
      color: ${COLOR_WHITE};
      background: ${COLOR_RED_BRIGHT};
    }
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  color: ${COLOR_TEXT};
  z-index: 1;
`;

const Accounts = styled.div`
  background-color: ${COLOR_WHITE};
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid rgba(19, 24, 44, 0.125);
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
  color: ${COLOR_TEXT};
`;

const Header = styled.div`
  display: flex;
  justify-content: end;

  .btn {
    background-color: transparent;
    color: ${COLOR_BTN};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    transition: color 0.3s ease;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .disabled {
    color: ${COLOR_BUTTON};
    opacity: 0.33;
    cursor: not-allowed;
  }

  .add {
    margin-right: 10px;
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
