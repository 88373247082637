import {
  IAgentAddEditDivisionFormValues,
  IAgentFormValues,
} from "../interfaces/agent";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateAgentApi = async ({
  firstName,
  lastName,
  sex,
  birthDate,
  registrationNumber,
  maritalStatus,
  professionalPhoneNumber,
  email,
  observationsDifficultJobs,
  observationsParticularSituations,
  bonusVacation,
  departureDate,
  departureReason,
}: IAgentFormValues) => {
  return instanceAxios
    .post("/agents/create-agent", {
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-agents`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL ARCHIVED
export const onGetAllArchivedAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-archived`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL ANONYMISED
export const onGetAllAnonymisedAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-anonymised`)
    .then((response) => (response.data ? response.data.data : null));
};

// RESTORE AGENT FROM ARCHIVED AGENTS
export const onRestoreAgentApi = async (id: number) => {
  return instanceAxios
    .put(`/agents/restore-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/agents/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE DIVISION AGENT
export const onUpdateAgentDivisionApi = async ({
  id,
  divisionId,
}: {
  id: number;
  divisionId: number;
}) => {
  return instanceAxios
    .put("/agents/update-division-agent/" + id, {
      divisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// CHECK IF MATRICULE EXISTS
export const onCheckRegistrationNumberOfAgentApi = async (
  registrationNumber: number
) => {
  return instanceAxios
    .get(`/agents/check-registration-number-agent/${registrationNumber}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteAgentApi = async (id: number) => {
  return instanceAxios
    .delete(`/agents/delete-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// ANONYM ONE
export const onAnonymAgentApi = async (id: number) => {
  return instanceAxios
    .put(`/agents/anonym-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// delete selected tags
export const onDeleteAllFromIdListApi = async (agentIds: Array<number>) => {
  return instanceAxios
    .delete("/agents/delete-all-from-id-list/", { params: { ids: agentIds } })
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateAgentApi = async ({
  id,
  firstName,
  lastName,
  sex,
  birthDate,
  registrationNumber,
  maritalStatus,
  professionalPhoneNumber,
  email,
  observationsDifficultJobs,
  observationsParticularSituations,
  bonusVacation,
  departureDate,
  departureReason,
}: IAgentFormValues) => {
  return instanceAxios
    .put("/agents/update-agent/" + id, {
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// ADD / EDIT AGENT DIVISION
export const onAddEditAgentDivisionApi = async ({
  agentId,
  divisionId,
}: IAgentAddEditDivisionFormValues) => {
  return instanceAxios
    .put("/agents/add-edit-agent-division/" + agentId, {
      divisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
