import React, { useCallback, useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../common/contexts/notificationContext";
import { INotificationInfos } from "../../interfaces/notification";
import { COLOR_BLUE_MEDIUM_DARK, COLOR_TEXT, COLOR_TEXT_MUTED } from "../../constants/cts_colors";
import { NOTIFICATION_TYPES } from "../../constants/cts_notifications";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

const Notification = ({loadNotifications}: {loadNotifications: Function}) => {
  const { id } = useParams()
  const { onGetNotification, onToggleNotificationIsActive } = useNotifications()
  const [notification, _setNotification] = useState<INotificationInfos | null>(null)
  const [toggleIsActive, _setToggleIsActive] = useState<boolean>(false);
  const [confirmModalText, _setConfirmModalText] = useState<string | null>(null);

  // GET THE NOTIFICATION
  const loadNotification = useCallback(() => {
    if (id) {
      onGetNotification(parseInt(id))
      .then((notification) => {
        if (notification) {
          if (
            notification.type === NOTIFICATION_TYPES.simple &&
            notification.isActive
          ) {
            toggleNotificationIsActive(notification)
          }
          _setNotification(notification)
        }
      })
      .catch((error) => console.error(error))
    }
  }, [id])

  useEffect(() => {
    if (id) {
      loadNotification()
    }
  }, [id])

  // TOGGLE NOTIFICATION IS ACTIVE
  const toggleNotificationIsActive = (notification: INotificationInfos) => {
    onToggleNotificationIsActive(notification.id)
      .then(() => {
        loadNotification()
        loadNotifications()
      })
      .catch((error) => console.error(error))
  }

  return (
    <BodyContainer title="Notification">
      {
        notification &&
        <MainContainer>
            <InfoIsActive>
              <span className="info"><span className={`point ${notification.isActive ? 'active' : ''}`} />
                {
                  notification.isActive ? 'Active' : 'Inactive'
                }
              </span>
            </InfoIsActive>
            <NotificationTextStyles>{notification.text}</NotificationTextStyles>
            {
              notification.type === NOTIFICATION_TYPES.block &&
              <ButtonToggleIsActive
                onClick={() => _setConfirmModalText(`
                  Voulez-vous ${notification.isActive ? 'désactiver' : 'activer'} cette notification ?
                `)}
              >
                {
                  notification.isActive ? 'Désactiver' : 'Activer'
                }
              </ButtonToggleIsActive>
            }
        </MainContainer>
      }

      {/* confirm modal (notification is active) */}
      {confirmModalText && notification && (
        <Modal onClose={() => _setConfirmModalText(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModalText}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => {
                  toggleNotificationIsActive(notification)
                  _setConfirmModalText(null)
                }}
              />
              <Button text="Non" onClick={() => _setConfirmModalText(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </BodyContainer>
  )
}

export default Notification;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const MainContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 12px 24px;
`

const InfoIsActive = styled.div`
  color: ${COLOR_TEXT};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .info {
    display: flex;
    align-items: center;
    gap: 10px;

    .point {
      display: block;
      width: 10px;
      height: 10px;
      background-color: ${COLOR_TEXT_MUTED};
      border-radius: 50%;

      &.active {
        background-color: ${COLOR_BLUE_MEDIUM_DARK};
      }
    }
  }
`

const NotificationTextStyles = styled.p`
  color: ${COLOR_TEXT};
`

const ButtonToggleIsActive = styled.button`
  display: block;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${COLOR_BLUE_MEDIUM_DARK};
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s opacity ease;

  &:hover {
    opacity: 0.7;
  }
`

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;