// EMPTY INPUT
export const checkEmptyInput = (inputValue: string | number | Date | null | undefined) => {
  const value = inputValue
  let str = null

  if(value === null || value === undefined) {
    return false
  }else {
    str = value.toString()
  }

  if (str.trim().length <= 0) {
    return false
  }

  return true
}

// STRING EQUALITY
export const checkStringEquality = (str1: string, str2: string) => {
  if (str1 !== str2)
    return false
  return true
}

// VALIDATE AGE
export const isValidAge = (age: number | string) => {

  // verify to be a positive number
  // if is string
  if(typeof age === "string" && parseInt(age) >= 0) {
    return true
  }
  // if is integer
  if(typeof age === "number" && age >= 0) {
    return true
  }

  return false
}
