import React, { ChangeEvent, FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../common/contexts/userContext";
import {
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_ERROR_MESSAGE,
  COLOR_LINK,
  COLOR_LINK_HOVER,
  COLOR_PLACEHOLDER,
  COLOR_TEXT,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";
import AuthPagesBase from "../../components/authPages/Base";
import { AiOutlineUser } from "react-icons/ai";

const LostPassword = () => {
  const { onLostPassword } = useUser();
  const [email, _SetEmail] = useState<string>("");
  const [submitted, _setSubmitted] = useState<boolean>(false);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    _SetEmail(e.target.value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onLostPassword(email)
      .then(() => {
        _setSubmitted(true);
      })
      .catch((error: any) => {
        if (error.message) {
          _setErrorMessage(error.message);
        } else if (error) {
          _setErrorMessage(error);
        }
      });
  };

  return (
    <AuthPagesBase title="Mot de passe oublié">
      <MainContainer>
        {!submitted ? (
          <Form id="lostPassword" onSubmit={onSubmit}>
            {errorMessage && (
              <ErrorMessageStyles>{errorMessage}</ErrorMessageStyles>
            )}
            <div className="inputs-container">
              {/* email */}
              <div className="input-line">
                <AiOutlineUser className="icon" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Votre e-mail"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {/* login link */}
            <Link className="login-link" to={getPageUrl(PATH.login)}>
              Retour à la page de connexion
            </Link>
            {/* submit btn */}
            <button className="submit-btn" type="submit" form="lostPassword">
              Envoyer le lien
            </button>
          </Form>
        ) : (
          <EmailSent>
            Un lien pour réinitialiser votre mot de passe a été envoyé par
            e-mail à {email}
          </EmailSent>
        )}
      </MainContainer>
    </AuthPagesBase>
  );
};

export default LostPassword;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ErrorMessageStyles = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${COLOR_ERROR_MESSAGE};

  // ========= MEDIA QUERIES - ErrorMessageStyles ============
  @media (max-width: 800px) {
    font-size: 2.5vw;
  }
`;

const MainContainer = styled.div`
  width: 500px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  // ========= MEDIA QUERIES - MainContainer ============
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .input-line {
      position: relative;
      display: flex;

      .icon {
        flex-basis: 0px;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        width: 15px;
      }

      input {
        flex-basis: 100%;
        color: ${COLOR_TEXT};
        font-size: 1.1rem;
        padding: 5px;
        padding-left: 22px;
        text-align: center;

        // ========= MEDIA QUERIES - Form / .inputs-container / .input-line / input ============
        @media (max-width: 800px) {
          font-size: 3vw;
        }

        &::placeholder {
          color: ${COLOR_PLACEHOLDER};
        }
      }
    }
  }

  .login-link {
    display: block;
    width: max-content;
    margin-top: 10px;
    color: ${COLOR_LINK};
    text-decoration: none;

    // ========= MEDIA QUERIES - Form / .login-link ============
    @media (max-width: 800px) {
      font-size: 2.5vw;
    }

    &:hover {
      color: ${COLOR_LINK_HOVER};
    }
  }

  .submit-btn {
    display: block;
    margin-left: auto;
    margin-top: 10px;
    background-color: ${COLOR_BTN};
    width: 50%;
    font-size: 1.1rem;
    padding: 10px 5px;
    border: none;
    border-radius: 5px;
    color: ${COLOR_WHITE};
    cursor: pointer;

    // ========= MEDIA QUERIES - Form / .submit-btn ============
    @media (max-width: 800px) {
      font-size: 3vw;
    }

    &:hover {
      background-color: ${COLOR_BTN_HOVER};
    }
  }
`;

const EmailSent = styled.div`
  color: ${COLOR_TEXT};
`;
